import React from "react";
import { MUploadButtonWrapper } from "./styled-components";

export default function MUploadButton({ onChange, children }) {
  return (
    <MUploadButtonWrapper>
      {children}
      <input type="file" onChange={onChange} />
    </MUploadButtonWrapper>
  );
}
