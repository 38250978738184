import React, { useEffect, useState } from "react";
import { AuthState } from "../../../context/AuthProvider";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMyClaims, removeClaimLocation, updateEvents } from "../../../utils/api";
import MBanner from "../../../design_system/MBanner";
import notify from "../../../utils/notify";
import { dateFormat, isEmpty } from "../../../utils/util";
import MTableCmp from "../../../design_system/MTableCmp";
import { EditIcon, TrashIcon } from "../../../utils/icons";
import { EventModal } from "./EventModal";
import { MDeleteModal } from "../../../design_system/MDeleteModal";

export const MyLocations = () => {
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [curSelectedLocation, setCurSelectedLocation] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [sortFormData, setSortFormData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [formData, setFormData] = useState({});
  const [updateItemIndex, setUpdateItemIndex] = useState(null);
  const tableInfo = [
    {
      key: "name",
      sortable: true,
      render: (cell, item) => <Link to={`/location/${item.id}`}>{cell}</Link>
    },
    { key: "address", sortable: true },
    {
      key: "status", sortable: true, render: (cell) =>
        <div className="text-center">
          {cell === true ? (
            <Button size="sm" variant="outline-info">
              Approved
            </Button>
          ) : cell === false ? (
            <Button size="sm" variant="outline-danger">
              Refused
            </Button>
          ) :
            <Button size="sm" variant="light">
              Pending
            </Button>
          }
        </div>
    },
    { key: "description" },
    {
      key: "date",
      label: "Request Date",
      sortable: true,
      render: (cell) => dateFormat(cell)
    },
    {
      key: "action",
      label: "Actions",
      render: (cell, item) =>
        <div className="cursor-pointer ml-2" onClick={() => { setIsShowDeleteModal(true); setDeleteItem(item) }} >
          <TrashIcon />
        </div>
    }
  ]

  const eventsTableInfo = [
    {
      key: "title",
      sortable: true,
    },
    {
      key: "start_date",
      sortable: true
    },
    {
      key: "action",
      label: "Actions",
      render: (cell, item, index) => <div className="d-flex justify-content-between">
        <div className="cursor-pointer ml-2" onClick={() => handleRemoveEvent(index)} >
          <TrashIcon />
        </div>
        <div className="cursor-pointer" style={{ color: "#1da2f2" }} onClick={() => handleUpdateEvent(index)}>
          <EditIcon />
        </div>
      </div>
    },
    {
      key: "start_time",
      sortable: true
    },
    {
      key: "end_time",
      sortable: true
    },
    {
      key: "type",
      sortable: true,
    },
    {
      key: "end_date",
      sortable: true
    },
    {
      key: "repeat",
      sortable: true,
    }
  ]

  function loadData() {
    const abortController = new AbortController();
    getMyClaims(abortController.signal)
      .then((data) => {
        setTableData(data.data);
        // setTotalSize(data.total);
      })
      .catch("null");
  }

  useEffect(() => {
    loadData();

    // eslint-disable-next-line
  }, [sortFormData]);

  const handleDelete = (item) => {
    const abortController = new AbortController();
    removeClaimLocation(deleteItem, abortController.signal)
      .then((data) => {
        const updatedData = tableData.filter(data => data.id !== deleteItem.id);
        setTableData(updatedData);
      })
      .catch("null");
  }

  const addEventModalOpen = () => {
    if (isEmpty(curSelectedLocation) || isEmpty(tableData)) {
      notify("Please select a location", "warn");
      return;
    }
    setUpdateItemIndex(null);
    setFormData({ type: "Once" })
    setIsShowModal(true);
  }

  const handleNewEventSave = () => {
    if (isEmpty(curSelectedLocation) || isEmpty(tableData)) {
      notify("Please select a location", "warn");
      return;
    }
    if (isEmpty(formData.title) || isEmpty(formData.start_date)) {
      notify("Please input title and start date", "warn");
      return;
    }
    setIsShowModal(false);
    let updatedData = [...tableData];
    let updatedEvents = updatedData[curSelectedLocation].events || [];
    if (isEmpty(updateItemIndex))
      updatedEvents.push(JSON.stringify(formData));
    else
      updatedEvents.splice(updateItemIndex, 1, JSON.stringify(formData));
    updatedData[curSelectedLocation].events = updatedEvents;
    setTableData(updatedData);
  }

  const handleSaveEventChange = () => {
    if (isEmpty(curSelectedLocation) || isEmpty(tableData)) {
      notify("Please select a location", "warn");
      return;
    }
    const abortController = new AbortController();
    updateEvents(tableData[curSelectedLocation], abortController.signal)
      .then((data) => {
        notify("Successfully Updated!", "success");
      })
      .catch("null");
  }

  const handleRemoveEvent = (index) => {
    const updatedTableData = [...tableData];

    if (updatedTableData[curSelectedLocation].events) {
      updatedTableData[curSelectedLocation].events.splice(index, 1);
    }

    setTableData(updatedTableData);
  }

  const handleUpdateEvent = (index) => {
    if (tableData[curSelectedLocation].events) {
      let curEvent = tableData[curSelectedLocation].events[index];
      setFormData(JSON.parse(curEvent));
      setIsShowModal(true);
      setUpdateItemIndex(index);
    }
  }

  const curSelectedEvents = isEmpty(tableData[curSelectedLocation]) || !tableData[curSelectedLocation]?.status ?
    [] :
    tableData[curSelectedLocation]?.events?.map(item => JSON.parse(item));

  return (
    <>
      <MBanner title="Manage Location" signed={isAuthenticated}>
        <Link className="link" to="/">Home</Link>
      </MBanner>
      <div className="featured-title">
        <h1>Manage <span>Location</span></h1>

        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempor non augue nec pulvinar.</h6>
      </div>

      <Row xs={1} sm={2} className="w-100 m-0 px-4 justify-content-center ">
        <Col xs={12} className="mb-3 p-1">
          <MTableCmp
            tableInfo={tableInfo}
            tableData={tableData}
            setSortFormData={setSortFormData}
            sortFormData={sortFormData}
            selectActiveRow={setCurSelectedLocation}
          />
        </Col>
        <Col xs={12} className="pl-md-5 mt-1">
          <div className="mCard p-0">
            <MTableCmp
              tableInfo={eventsTableInfo}
              tableData={curSelectedEvents}
              setSortFormData={setSortFormData}
              sortFormData={sortFormData}
            />
            <div className="d-flex gap-2 px-4 mb-4">
              <Button
                size="lg"
                className="m-0 my-3 w-50"
                onClick={addEventModalOpen}
                disabled={!tableData[curSelectedLocation]?.status}
              >
                Add Event
              </Button>
              <Button
                size="lg"
                className="m-0 my-3 w-50"
                variant="outline-light"
                onClick={handleSaveEventChange}
                disabled={!tableData[curSelectedLocation]?.status}
              >
                Save
              </Button>
            </div>
            <EventModal
              isShowModal={isShowModal}
              setShowModal={setIsShowModal}
              formData={formData}
              setFormData={setFormData}
              handleSave={handleNewEventSave}
            />
          </div>
        </Col>
      </Row>
      <MDeleteModal
        tableData={{}}
        deleteId={{}}
        setIsOpen={setIsShowDeleteModal}
        isOpen={isShowDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};
