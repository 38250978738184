import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { getLocationSuggest } from "../utils/api";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "../utils/util";

export default function MLocationSuggest({ value, onChange }) {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const fetch = React.useMemo(
    () =>
      debounce(async (request, callback) => {
        const results = await getLocationSuggest(request);
        callback(results);
      }, 700),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!changed || !open || inputValue === "") {
      return undefined;
    }

    fetch({ key: inputValue }, (results) => {
      if (active) {
        setOptions(results);
        setChanged(false);
      }
    });

    return () => {
      active = false;
    };

    // eslint-disable-next-line
  }, [changed, inputValue, fetch]);

  return (
    <Autocomplete
      id="location-suggest"
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setChanged(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for a Specific Business"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {open && isEmpty(options) ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
