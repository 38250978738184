import { useGoogleMap } from "@react-google-maps/api";
import { useEffect, useRef } from "react";

const MMapControl = (props) => {
  const map = useGoogleMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
    // eslint-disable-next-line
  }, [map, ref]);

  return <div ref={ref}>{props.children}</div>;
};

export default MMapControl;
