import React from "react";
import { Button, Modal } from "react-bootstrap";
import { SearchModalWrapper } from "../../../design_system/styled-components";
import { MDropdown } from "../../../design_system/MDropdown";
import { MCreatableDropdown } from "./MCreatableDropdown";
import { events } from "../../Location/DrowndownList";
import { DateCalendar, DatePicker, DateTimePicker, TimePicker, DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import { EventRepeatTypes, EventTypes } from "../../../utils/constants";
import { isEmpty } from "../../../utils/util";
import moment from "moment";
import { Badge } from "@mui/material";

export const EventModal = ({
  isShowModal,
  setShowModal,
  formData,
  setFormData,
  handleSave
}) => {
  const handleClose = () => {
    setShowModal(false);
  };

  const getSelectedDays = () => {
    if (isEmpty(formData)) return [];
    if (isEmpty(formData.start_date)) return [];
    const start_date = new Date(formData.start_date).getDate();
    let days = [start_date];

    return days;
  }

  const start_date = new Date(formData.start_date);
  const end_date = new Date(formData.end_date);
  const start_time = start_date.getTime();
  const end_time = end_date.getTime();
  const ServeDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const cur_date = new Date(moment(day).format('YYYY-MM-DD'));

    const cur_time = cur_date.getTime();

    let isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
    if (cur_time < start_time) {
      isSelected = false;
    } else if (isEmpty(formData.type) || formData.type === "Once" || isEmpty(formData.repeat)) {
      if (cur_time === start_time) {
        isSelected = true;
      } else {
        isSelected = false;
      }
    } else if (formData.type === "Period") {
      if (cur_time > end_time || (isEmpty(formData.end_date) && cur_time !== start_time)) {
        isSelected = false;
      } else if (formData.repeat === "Monthly") {
      } else if (formData.repeat === "Daily") {
        isSelected = true;
      } else if (formData.repeat === "Weekly" && cur_date.getDay() === start_date.getDay()) {
        isSelected = true;
      } else if (formData.repeat === "Yearly" && cur_date.getMonth() === start_date.getMonth() && cur_date.getDate() === start_date.getDate()) {
        isSelected = true;
      } else {
        isSelected = false;
      }
    } else if (formData.type === "Permanent") {
      if (formData.repeat === "Monthly") {
      } else if (formData.repeat === "Daily") {
        isSelected = true;
      } else if (formData.repeat === "Weekly" && cur_date.getDay() === start_date.getDay()) {
        isSelected = true;
      } else if (formData.repeat === "Yearly" && cur_date.getMonth() === start_date.getMonth() && cur_date.getDate() === start_date.getDate()) {
        isSelected = true;
      } else {
        isSelected = false;
      }
    }

    return (
      <Badge
        key={props.day.toString()}
        sx={{
          ".MuiBadge-badge": {
            top: "0.3rem !important",
            right: "0.3rem !important",
          }
        }}
        color="primary"
        variant="dot"
        invisible={!isSelected}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge >
    );
  }

  return (
    <Modal
      style={{ paddingLeft: 0 }}
      show={isShowModal}
      onHide={() => handleClose()}
      backdrop={true}
      className="modal fade"
      role="dialog"
    >
      <SearchModalWrapper>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title text-center" id="exampleModalLabel">
                Add New Event
              </h5>
            </div>
            <div className="modal-body px-4">
              <div className="mt-5 my-4">
                <MCreatableDropdown
                  listItems={events}
                  formData={formData}
                  setFormData={setFormData}
                  attribute="title"
                  placeholder={`Choose Event`}
                  sx={{ backgroundColor: "white", borderRadius: "0.375rem" }}
                />
              </div>
              <div className="d-flex gap-2 ">
                <DateTimePicker
                  label="Date And Start Time*"
                  value={isEmpty(formData.start_date) ? null : moment(formData.start_date + "T" + formData.start_time)}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      start_date: moment(value).format("YYYY-MM-DD"),
                      start_time: moment(value).format("HH:mm")
                    })}
                />
                <TimePicker
                  label="End Time"
                  value={isEmpty(formData.end_time) ? null : moment(formData.end_time, "HH:mm")}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      end_time: moment(value).format("HH:mm")
                    })}
                />
                <div className="w-25">
                  <MDropdown
                    className="dropdown"
                    formData={formData}
                    setFormData={setFormData}
                    listItems={EventTypes}
                    attribute="type"
                    label="Type"
                    placeholder="Choose Event Type"
                  />
                </div>
              </div>
              <div className="d-flex gap-2 mt-4">
                {!isEmpty(formData.type) && formData.type === "Period" &&
                  <DatePicker
                    label="Period End Date"
                    value={isEmpty(formData.end_date) ? null : moment(formData.end_date)}
                    onChange={(value) =>
                      setFormData({
                        ...formData,
                        end_date: moment(value).format("YYYY-MM-DD")
                      })}
                  />
                }
                <div className="w-50">
                  {!isEmpty(formData.type) && formData.type !== "Once" &&
                    <MDropdown
                      className="dropdown"
                      formData={formData}
                      setFormData={setFormData}
                      listItems={EventRepeatTypes}
                      attribute="repeat"
                      label="Repeat"
                      placeholder="Choose Event Repeat"
                    />}
                </div>
              </div>
              <DateCalendar className="mt-4"
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: ServeDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays: getSelectedDays(),
                  },
                }}
              />
            </div>
            <div className="modal-footer py-3 px-4 mx-1">
              <Button size="lg" data-dismiss="modal" variant="outline-dark" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="lg" data-dismiss="modal" variant="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </SearchModalWrapper>
    </Modal>
  );
};
