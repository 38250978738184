import React, { useState, useEffect } from "react";
import { MTable } from "../../design_system/MTable";
import {
  listAnalysisData,
  deleteAnalysis,
  deleteAllNotFoundAnalysis,
  analyzeData,
} from "../../utils/api";
import { isEmpty } from "../../utils/util";
import { MInput } from "../../design_system/MInput";
import MPagination from "../../design_system/MPagination";
import MBanner from "../../design_system/MBanner";
import { Link, useNavigate } from "react-router-dom";
import { AuthState } from "../../context/AuthProvider";
import { Button, Form } from "react-bootstrap";
import { criteriaLabels } from "../Location/DrowndownList";
import notify from "../../utils/notify";

export const DataAnalysis = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const columnNames = isEmpty(tableData)
    ? []
    : [
        "name",
        "address",
        "yelp_name",
        "yelp_address",
        "analysis_actions",
        "barType",
        "specialtyType",
        "events",
        "games",
        "barMusic",
        "parking",
        "dogFriendly",
        "time2visit",
        "amenities",
        "ambiance",
        "close2Others",
        "sports",
        "typesOfSports",
        "dancing",
        "mixology",
        "drinkCost",
        "beerCost",
        "drinkSpecialties",
        "happyHour",
        "offersFood",
        "foodType",
        "foodCost",
        "reservable",
        "meal",
        "restaurantType",
      ];
  const [searchFormData, setSearchFormData] = useState({});
  const [sortFormData, setSortFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const radioOptions = [
    { value: "all", label: "All" },
    { value: "not_found", label: "Not Found" },
    { value: "conflict", label: "Check Conflict" },
  ];
  const [radioChecked, setRadioChecked] = useState("all");

  function loadData() {
    const abortController = new AbortController();
    listAnalysisData(
      {
        sort: sortFormData,
        search: searchFormData.search,
        page: currentPage,
        checked: radioChecked,
      },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.data);
        setTotalSize(data.total);
      })
      .catch("null");
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [sortFormData, currentPage, radioChecked]);

  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      loadData();
      event.target.blur();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (formData) => {
    const abortController = new AbortController();

    deleteAnalysis(formData, abortController.signal)
      .then((data) => {
        const temp = tableData.filter((item) => item.id !== formData.id);
        setTableData(temp);
        setTotalSize(temp.length);
      })
      .catch("null");
  };

  const handleDeleteAllNotFound = () => {
    const abortController = new AbortController();

    deleteAllNotFoundAnalysis(abortController.signal)
      .then((data) => {
        navigate(0);
      })
      .catch("null");
  };

  const handleAnalyze = (formData) => {
    const abortController = new AbortController();
    analyzeData(formData, abortController.signal)
      .then((data) => {
        const temp = tableData.map((item) =>
          item.id === formData.id
            ? { ...item, ...data.data, is_analyzed: true }
            : item
        );
        setTableData(temp);
        notify("Analysis success!", "success");
      })
      .catch("null");
  };

  return (
    <>
      <MBanner title="Data Analysis" signed={isAuthenticated}>
        <Link className="link" to="/metrics">
          Admin Metrics
        </Link>
      </MBanner>
      <div className="m-4">
        <div className="mx-2 d-flex justify-content-between align-items-center">
          <h4 className="m-0">Data Analysis</h4>
          <div className="d-flex justify-content-end align-items-center">
            {radioOptions.map((option, index) => (
              <Form.Check
                className="mx-2"
                type="radio"
                name={option.value}
                id={option.value}
                key={index}
                label={option.label}
                value={radioChecked === option.value}
                onChange={(e) => setRadioChecked(option.value)}
                checked={radioChecked === option.value}
              />
            ))}
            <div className="mr-2">
              <MInput
                attribute="search"
                formData={searchFormData}
                setFormData={setSearchFormData}
                editData=""
                isEdit={true}
                onKeyDown={handlePressEnter}
              />
            </div>
            <Button variant="danger" onClick={handleDeleteAllNotFound}>
              Delete All Not Found
            </Button>
          </div>
        </div>
        <MTable
          columnNames={columnNames}
          columnLabels={{ ...criteriaLabels, analysis_actions: "Actions" }}
          tableData={tableData}
          setSortFormData={setSortFormData}
          sortFormData={sortFormData}
          handleDelete={handleDelete}
          handleFetch={handleAnalyze}
        />
        <MPagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          total={totalSize}
          pageSize={50}
        />
      </div>
    </>
  );
};
