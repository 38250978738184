import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../utils/api";
import { ModalWrapper } from "./styled-components";
import { Button } from "react-bootstrap";

export const MDeleteUserModal = ({ tableData, deleteId, setIsOpen }) => {
  const navigate = useNavigate();
  const [formData] = useState(tableData[deleteId]);
  function deleteHandler(event) {
    event.preventDefault();
    const abortController = new AbortController();

    deleteUser(formData?.id, abortController.signal)
      .then((data) => {
        console.log(data);
        navigate(0);
      })
      .catch("null");
  }
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <ModalWrapper>
      <div
        className="modal fade"
        id="deleteUserModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center flex-column border-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M42.0019 7.99997H35.802C34.845 3.34716 30.7521 0.006 26.0019 0H22.0019C17.2516 0.006 13.1587 3.34716 12.2019 7.99997H6.00192C4.89736 7.99997 4.00195 8.89538 4.00195 9.99994C4.00195 11.1045 4.89736 12 6.00192 12H8.00189V38C8.00855 43.5201 12.4818 47.9934 18.0019 48H30.0019C35.522 47.9934 39.9953 43.5201 40.002 38V12H42.0019C43.1065 12 44.0019 11.1046 44.0019 10C44.0019 8.89547 43.1065 7.99997 42.0019 7.99997ZM22.002 34C22.002 35.1046 21.1065 36 20.002 36C18.8973 36 18.0019 35.1046 18.0019 34V22C18.0019 20.8955 18.8973 20.0001 20.0019 20.0001C21.1065 20.0001 22.0019 20.8955 22.0019 22V34H22.002ZM30.0019 34C30.0019 35.1046 29.1065 36 28.002 36C26.8974 36 26.002 35.1046 26.002 34V22C26.002 20.8955 26.8974 20.0001 28.002 20.0001C29.1065 20.0001 30.0019 20.8955 30.0019 22V34ZM16.344 7.99997C17.1946 5.60456 19.46 4.00303 22.002 3.99994H26.002C28.5439 4.00303 30.8093 5.60456 31.66 7.99997H16.344Z"
                  fill="#DC4635"
                />
              </svg>
            </div>
            <div className="modal-body text-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User
              </h5>
              Are you sure delete this user?
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between w-100 gap-2">
                <Button
                  size="lg"
                  variant="outline-primary"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="lg"
                  variant="danger"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={deleteHandler}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
