import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { isEmpty } from "../utils/util";
import {
  getImageURL,
  setFavoriteLocation,
  setViewedLocation,
} from "../utils/api";
import { MStarRating } from "../design_system/MStarRating";
import { IconButton, Tooltip } from "@mui/material";
import {
  FavoriteBorder,
  Favorite,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { AuthState } from "../context/AuthProvider";
import notify from "../utils/notify";
import { MCardWrapper } from "./styled-components";

const MCardView = ({
  info,
  withDesc = false,
  withRate = false,
  isSelected = false,
  isShowReviewModal,
}) => {
  const navigate = useNavigate();
  const { auth, setAuth } = AuthState();
  const { favoriteLocations } = auth || {};

  const handleToggleFavorite = async (e, id) => {
    e.preventDefault();
    if (isEmpty(auth) || !auth.isAuthenticated) {
      return navigate("/signIn");
    }
    const { data } = await setFavoriteLocation({ id });
    const isAdded = favoriteLocations?.includes(id);
    setAuth({ ...auth, favoriteLocations: data });
    notify(`${isAdded ? "Remove" : "Add"} Favorite Success`, "success");
  };

  const handleSetViewedLocation = (id) => {
    if (!isEmpty(auth) && auth.isAuthenticated) setViewedLocation({ id });
  };

  return (
    <MCardWrapper withRate>
      <Card
        className="h-100"
        style={{ backgroundColor: isSelected ? "#e6f0ff" : "white" }}
      >
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={!isShowReviewModal && `/location/${info.id}`}
          onClick={() => handleSetViewedLocation(info.id)}
        >
          {!isEmpty(info.photo) ? (
            <Card.Img variant="top" src={getImageURL(info.photo)} />
          ) : (
            <Card.Img
              className="px-5 pt-3"
              variant="top"
              src="/zappt-mag.png"
            />
          )}
          <Card.ImgOverlay as="div" className="bottom-auto right-auto ">
            <IconButton onClick={(e) => handleToggleFavorite(e, info.id)}>
              {!isEmpty(favoriteLocations) &&
              favoriteLocations?.includes(info.id) ? (
                <Tooltip title="Remove Favorite">
                  <Favorite color="error" />
                </Tooltip>
              ) : (
                <Tooltip title="Add Favorite">
                  <FavoriteBorder />
                </Tooltip>
              )}
            </IconButton>
          </Card.ImgOverlay>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Tooltip title={info.name}>
                  <Card.Title>{info.name}</Card.Title>
                </Tooltip>
                <Tooltip title={info.address}>
                  <p>{info.address}</p>
                </Tooltip>
              </div>
              {withRate ? (
                <MStarRating rating={info.rating || 0} />
              ) : (
                <KeyboardDoubleArrowRight />
              )}
            </div>
            {withDesc && (
              <>
                <p className="title">Description</p>
                <Card.Text>{info.description}</Card.Text>
              </>
            )}
          </Card.Body>
        </Link>
      </Card>
    </MCardWrapper>
  );
};

export default MCardView;
