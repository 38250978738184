import React, { useState, useEffect } from "react";
import RoutesPicker from "./RoutesPicker";
import { ToastContainer, toast } from "react-toastify";
import MHeader from "../design_system/MHeader";
import MFooter from "../design_system/MFooter";
import ReactGA from "react-ga";
import MSidebar from "../design_system/MSidebar";
import { AuthState } from "../context/AuthProvider";
import {
  AddLocationIcon,
  FeaturedLocationIcon,
  HomeIcon,
  MetricsIcon,
  ProfileIcon,
} from "../utils/icons";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import StarOutlineSharpIcon from "@mui/icons-material/StarOutlineSharp";
import UploadIcon from "@mui/icons-material/Upload";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { UserRole } from "../utils/constants";
import "react-toastify/dist/ReactToastify.css";

// import { SidebarState } from "../context/AuthProvider";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
  // debug: true,
});

/**
 * Defines the main layout of the application.
 *
 * You will not need to make changes to this file.
 *
 * @returns {JSX.Element}
 */

export const Layout = () => {
  const { auth, setAuth } = AuthState();
  const { isAuthenticated, role } = auth || {};
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (auth?.isNew && isOpen) {
      toast.info(<Msg />, {
        onClose: () => {
          setIsOpen(false);
        },
      });
    }
  }, [auth?.isNew]);

  const Msg = ({ closeToast, toastProps }) => (
    <div>
      Please download this{" "}
      <a
        style={{
          color: "white",
          textDecoration: "underline",
        }}
        href=""
      >
        app
      </a>{" "}
      for a better experience!
    </div>
  );

  const navList =
    parseInt(role) === UserRole.ADMIN
      ? [
          {
            text: "Home",
            url: "/",
            icon: <HomeIcon />,
          },
          {
            text: "Admin Metrics",
            url: "/metrics",
            icon: <MetricsIcon />,
          },
          {
            text: "Location List",
            url: "/location",
            icon: <FeaturedLocationIcon />,
          },
          {
            text: "Add Location",
            url: "/add-location",
            icon: <AddLocationIcon />,
          },
          {
            text: "Upload License",
            url: "/upload-license",
            icon: <UploadIcon />,
          },
          {
            text: "Data Analysis",
            url: "/data-analysis",
            icon: <TroubleshootIcon />,
          },
          {
            text: "Location Owners",
            url: "/location-owners",
            icon: <PersonPinCircleIcon />,
          },
          {
            text: "Manage Location",
            url: "/my-location",
            icon: <MyLocationIcon />,
          },
          {
            text: "Profile",
            url: `/auth/${auth?.id}`,
            icon: <ProfileIcon />,
          },
        ]
      : parseInt(role) === UserRole.OWNER
      ? [
          {
            text: "Home",
            url: "/",
            icon: <HomeIcon />,
          },
          {
            text: "Manage Location",
            url: "/my-location",
            icon: <MyLocationIcon />,
          },
          {
            text: "Favorite Location",
            url: "/favorite",
            icon: <FeaturedLocationIcon />,
          },
          {
            text: "Recommendation",
            url: "/recommendation",
            icon: <StarOutlineSharpIcon />,
          },
          {
            text: "Help",
            url: "/help",
            icon: <HelpOutlineSharpIcon />,
          },
          {
            text: "Profile",
            url: `/auth/${auth?.id}`,
            icon: <ProfileIcon />,
          },
        ]
      : isAuthenticated
      ? [
          {
            text: "Home",
            url: "/",
            icon: <HomeIcon />,
          },
          {
            text: "Favorite Location",
            url: "/favorite",
            icon: <FeaturedLocationIcon />,
          },
          {
            text: "Recommendation",
            url: "/recommendation",
            icon: <StarOutlineSharpIcon />,
          },
          {
            text: "Help",
            url: "/help",
            icon: <HelpOutlineSharpIcon />,
          },
          {
            text: "Profile",
            url: `/auth/${auth?.id}`,
            icon: <ProfileIcon />,
          },
        ]
      : [
          {
            text: "Home",
            url: "/",
            icon: <HomeIcon />,
          },
          {
            text: "Favorite Location",
            url: "/favorite",
            icon: <FeaturedLocationIcon />,
          },
          {
            text: "Recommendation",
            url: "/recommendation",
            icon: <StarOutlineSharpIcon />,
          },
          {
            text: "Help",
            url: "/help",
            icon: <HelpOutlineSharpIcon />,
          },
        ];

  return (
    <div>
      <MHeader navList={navList} />
      <div className="d-block d-sm-flex">
        {isAuthenticated && <MSidebar navList={navList} />}
        <ToastContainer
          style={{ width: "50%" }}
          position="bottom-left"
          autoClose={false}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="w-100 main">
          <RoutesPicker />
        </div>
      </div>
      <MFooter />
    </div>
  );
};
