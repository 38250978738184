import { useState } from "react";
import { uploadFileToServer } from "../utils/api";
import { Form, Spinner } from "react-bootstrap";

export function MImageUpload({ formData, setFormData, attribute }) {
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (e) => {
    try {
      setLoading(true);
      setFormData({ ...formData, [attribute]: "" });
      const form = new FormData();
      form.append("avatar", e.target.files[0]);
      const res = await uploadFileToServer(form);
      setFormData({ ...formData, [attribute]: res?.secure_url });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-100 position-relative d-flex">
        <img
          src={formData[attribute]}
          className="w-50 m-auto min-vh-10"
          alt="user avatar"
        />
        {loading && (
          <Spinner
            className="position-absolute start-50 top-50"
            animation="border"
            role="status"
            size="sm"
          />
        )}
      </div>
      <Form.Group controlId="formFile" className="my-3">
        <Form.Control
          disabled={loading}
          type="file"
          onChange={handleImageUpload}
        />
      </Form.Group>
    </>
  );
}
