import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthState, DataState, SidebarState } from "../context/AuthProvider";
import { MSearchModal } from "./MSearchModal";
import { HeaderWrapper } from "./styled-components";
import { listSearch } from "../utils/api";
import { googleLogout } from "@react-oauth/google";
import gaEvents from "../utils/gaEvents";
import MHeaderMobile from "./MHeaderMobile";
import MHeaderTablet from "./MHeaderTablet";
import MInTakeModal from "./MInTakeModal";
import Avatar from "@mui/material/Avatar";
import { MReviewModal } from "./MReviewModal";
import { getRatingDataByUserID, nearDrinks } from "../utils/api";
import { MSearchDrinksModal } from "./MSearchDrinksModal";
import { isEmpty } from "../utils/util";
import notify from "../utils/notify";

export default function MHeader({ navList }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSidebarOpen, setSidebarOpen } = SidebarState();
  const {
    setTableData,
    setMostFavoriteLocations,
    formData,
    setFormData,
    excFormData,
    setExcFormData,
    mapFormData,
    setMapFormData,
  } = DataState();
  const { auth, setAuth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [loadingState, setLoadingState] = useState(true);
  const [exclusion, setExclusion] = useState(false);
  const [isShowSearchModal, setShowSearchModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [suggestionRating, setSuggestionRating] = useState([]);
  const [isShowDrinkModal, setIsShowDrinkModal] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (isAuthenticated) {
      getRatingDataByUserID(abortController.signal).then((response) => {
        setSuggestionRating(response.suggestionRating);
        setFilterData(response.locations);
        setLoadingState(false);
      });
    }
  }, [isAuthenticated]);

  const handleShow = () => setSidebarOpen(!isSidebarOpen);
  const handleExclusionButton = () => {
    setExclusion(!exclusion);
  };

  const handleClearSearch = () => {
    setFormData({});
    setExcFormData({});
    setMapFormData({});
  };

  const handleSearch = () => {
    setShowSearchModal(false);
    setTableData([]);
    setMostFavoriteLocations([]);
    const abortController = new AbortController();
    listSearch(
      {
        criteria: formData,
        excCriteria: excFormData,
        map: mapFormData,
      },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.relevantData);
        setMostFavoriteLocations(data.mostFavoriteLocations);
        if (isEmpty(data.relevantData))
          notify("No locations in your search", "warn");
        navigate("/search");
        gaEvents.eventLocationSearch();
      })
      .catch("null");
  };

  const handleSignOut = () => {
    googleLogout();
    setAuth({});
    navigate("/");
  };

  const handleFindNearDrinks = () => {
    setFormData({});
    setExcFormData({});
    setTableData([]);
    setMostFavoriteLocations([]);
    const abortController = new AbortController();
    nearDrinks(mapFormData, abortController.signal)
      .then((data) => {
        setIsShowDrinkModal(false);
        setTableData(data?.relevantData);
        setMostFavoriteLocations(data?.mostFavoriteLocations);
        if (isEmpty(data.relevantData)) {
          notify("No locations in your search", "warn");
        }
        navigate("/map-location");
        gaEvents.eventLocationSearch();
      })
      .catch("null");
  };

  return (
    <>
      <HeaderWrapper>
        <header
          className={`${
            isAuthenticated ? "mx-5" : "container"
          } d-none d-sm-flex justify-content-between py-3`}
        >
          <div className="d-flex">
            <div className="d-xl-none d-sm-flex">
              <img
                className="logo"
                alt="Zappt"
                onClick={handleShow}
                src={require("../images/zappt-logo.png")}
              />
              <div className="d-xl-none">
                <MHeaderTablet
                  navList={navList}
                  handleSearch={handleSearch}
                  handleSignOut={handleSignOut}
                  setShowSearchModal={setShowSearchModal}
                />
              </div>
            </div>
            {!isAuthenticated && (
              <>
                <img
                  className="logo d-none d-xl-block"
                  alt="Zappt"
                  onClick={handleShow}
                  src={require("../images/zappt-logo.png")}
                />
                <nav className="d-none d-xl-flex align-items-center nav">
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Home
                  </Link>
                  <Link
                    to="/favorite"
                    className={
                      location.pathname === "/favorite" ? "active" : ""
                    }
                  >
                    Featured Location
                  </Link>
                  <Link
                    to="/help"
                    className={location.pathname === "/help" ? "active" : ""}
                  >
                    Help
                  </Link>
                  <Link
                    to="/recommendation"
                    className={
                      location.pathname === "/recommendation" ? "active" : ""
                    }
                  >
                    Recommendation
                  </Link>
                </nav>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              data-toggle="modal"
              data-target="#updateModal"
              size="lg"
              onClick={() => setIsShowDrinkModal(true)}
            >
              Drinks Near Me
            </Button>
            <Button
              data-toggle="modal"
              data-target="#updateModal"
              size="lg"
              onClick={() => setShowSearchModal(true)}
            >
              Search
            </Button>
            {!isAuthenticated ? (
              <>
                <Link to="/signIn">
                  <Button size="lg">Sign In</Button>
                </Link>
                <Link to="/signUp">
                  <Button size="lg" variant="outline-dark">
                    Sign Up
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Avatar
                  className="mr-2 ml-4"
                  alt="user avatar"
                  src={auth.avatar}
                />
                <p className="m-0">{`${auth?.firstName ? auth.firstName : ""} ${
                  auth?.lastName ? auth.lastName : ""
                }`}</p>
              </>
            )}
          </div>
        </header>
        {/* mobile header */}
        <header className="container d-flex d-sm-none justify-content-between align-items-center py-3 px-4">
          <img
            alt="zappt"
            onClick={handleShow}
            src={require("../images/zappt-logo.png")}
          />
          <MHeaderMobile
            navList={navList}
            handleSearch={handleSearch}
            handleSignOut={handleSignOut}
            setShowSearchModal={setShowSearchModal}
            setIsShowDrinkModal={setIsShowDrinkModal}
          />
        </header>
      </HeaderWrapper>
      <MSearchDrinksModal
        isOpen={isShowDrinkModal}
        setIsOpen={setIsShowDrinkModal}
        handleSubmit={handleFindNearDrinks}
      />
      {isShowSearchModal ? (
        <MSearchModal
          // modalId="updateModal"
          isShowSearchModal={isShowSearchModal}
          setShowSearchModal={setShowSearchModal}
          formData={formData}
          setFormData={setFormData}
          handleExclusionButton={handleExclusionButton}
          excFormData={excFormData}
          setExcFormData={setExcFormData}
          mapFormData={mapFormData}
          setMapFormData={setMapFormData}
          exclusion={exclusion}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
      ) : null}
      <MReviewModal
        filterData={filterData}
        suggestionRating={suggestionRating}
        setSuggestionRating={setSuggestionRating}
        setFilterData={setFilterData}
        handleSignOut={handleSignOut}
        loading={loadingState}
      />
      <MInTakeModal />
    </>
  );
}
