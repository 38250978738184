import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MCardView from "../design_system/MCardView";
import { Row, Col, Button } from "react-bootstrap";
import { AuthState, DataState } from "../context/AuthProvider";
import { isEmpty } from "../utils/util";
import { getFeaturedLocations, nearDrinks } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { MSearchDrinksModal } from "../design_system/MSearchDrinksModal";
import gaEvents from "../utils/gaEvents";
import notify from "../utils/notify";

const DashboardWrapper = styled.div`
  .hero {
    color: white;
    text-align: center;
    font-size: 4.3rem;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36vw;
    background: url("/images/hero.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: ${(props) => (props.signed ? "2rem" : "0")};
    border-radius: ${(props) => (props.signed ? "1.5rem" : "0")};

    span {
      max-width: 60rem;
    }

    .drink-btn {
      font-size: 2rem;
      font-weight: bold;
    }

    @media only screen and (max-width: 1220px) {
      font-size: 3.4rem;

      span {
        max-width: 50rem;
      }

      .drink-btn {
        font-size: 1.7rem;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 480px) {
      margin: 0;
      border-radius: 0;
      font-size: 1.5rem;
      padding: 30% 2%;

      span {
        max-width: 20rem;
      }

      .drink-btn {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
`;

export const Dashboard = () => {
  const navigate = useNavigate();
  const {
    featuredLocations,
    setFeaturedLocations,
    setTableData,
    setMostFavoriteLocations,
    setFormData,
    setExcFormData,
    setMapFormData,
    mapFormData
  } = DataState([]);
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [isShowDrinkModal, setIsShowDrinkModal] = useState(false);

  useEffect(() => {
    if (isEmpty(featuredLocations))
      getFeaturedLocations()
        .then((data) =>
          setFeaturedLocations(data.slice(0, Math.min(data.length, 6)))
        )
        .catch(null);

    // eslint-disable-next-line
  }, []);

  const handleFindNearDrinks = () => {
    setFormData({});
    setExcFormData({});
    setTableData([]);
    setMostFavoriteLocations([]);
    const abortController = new AbortController();
    nearDrinks(mapFormData, abortController.signal)
      .then((data) => {
        setIsShowDrinkModal(false);
        setTableData(data?.relevantData);
        setMostFavoriteLocations(data?.mostFavoriteLocations);
        if (isEmpty(data.relevantData))
          notify("No locations in your search", "warn")
        navigate("/map-location");
        gaEvents.eventLocationSearch();
      })
      .catch("null");
  };

  return (
    <DashboardWrapper signed={isAuthenticated}>
      <div className="hero">
        <div>
          <p>Spend Less Time Looking and More Time Doing</p>
          <Button
            className="m-auto drink-btn"
            size="lg"
            onClick={() => setIsShowDrinkModal(true)}
          >
            Drinks Near Me
          </Button>
        </div>
      </div>
      <div className="featured-title">
        <h1>
          Featured <span>Location</span>
        </h1>
        <h6>Weekly featured locations</h6>
        <Row xs={1} sm={2} xl={3}>
          {featuredLocations?.map((info) => (
            <Col className="my-2" key={info.id}>
              <MCardView info={info} />
            </Col>
          ))}
        </Row>
      </div>
      <MSearchDrinksModal
        isOpen={isShowDrinkModal}
        setIsOpen={setIsShowDrinkModal}
        handleSubmit={handleFindNearDrinks}
      />
    </DashboardWrapper>
  );
};
