import React, { useState } from "react";
import { MDropdown } from "./MDropdown";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import {
  barType,
  specialtyType,
  events,
  games,
  barMusic,
  parking,
  dogFriendly,
  time2visit,
  amenities,
  ambiance,
  close2Others,
  sports,
  typesOfSports,
  dancing,
  levelOfMixology,
  costOfDrinks,
  costOfCans,
  drinkSpecialties,
  happyHour,
  offersFood,
  foodType,
} from "../layout/Location/DrowndownList";

import { SearchFieldsWrapper } from "./styled-components";
// import { isEmpty } from "../utils/util";

export const SearchInput = ({ formData, setFormData, tableData, isEdit }) => {
  const [isShowWhere, setShowWhere] = useState(false);
  const [isShowDrink, setShowDrink] = useState(false);
  const [isShowVibes, setShowVibes] = useState(false);
  const [isShowLocationType, setShowLocationType] = useState(false);
  const [isShowFood, setShowFood] = useState(false);
  // const [isShowTypesOfSports, setShowTypesOfSports] = useState(false);

  return (
    <>
      <SearchFieldsWrapper>
        <Button
          className="mr-2"
          variant="outline-light"
          onClick={() => setShowWhere(!isShowWhere)}
        >
          Where
          {isShowWhere ? <RemoveIcon /> : <AddIcon />}
        </Button>
        {isShowWhere && (
          <div>
            <MDropdown
              className="dropdown"
              formData={formData}
              setFormData={setFormData}
              listItems={foodType}
              attribute="foodType"
              label="Food Type"
              editData={tableData?.foodType || []}
              isEdit={isEdit}
              isMulti={true}
              shouldAlphabetize={true}
            />
          </div>
        )}
        <Button
          className="mr-2"
          variant="outline-light"
          onClick={() => setShowDrink(!isShowDrink)}
        >
          DRINKS
          {isShowDrink ? <RemoveIcon /> : <AddIcon />}
        </Button>
        {isShowDrink && (
          <>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={levelOfMixology}
                attribute="mixology"
                label="Mixology"
                editData={tableData?.mixology || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={costOfDrinks}
                attribute="drinkCost"
                label="Drink Cost"
                editData={tableData?.drinkCost || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={costOfCans}
                attribute="beerCost"
                label="Beer Cost"
                editData={tableData?.beerCost || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={drinkSpecialties}
                attribute="drinkSpecialties"
                label="Drink Specialties"
                editData={tableData?.drinkSpecialties || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div className="d-flex align-items-center">
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={happyHour}
                attribute="happyHour"
                label="Happy Hour"
                editData={tableData?.happyHour || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
          </>
        )}
        <Button
          className="mr-2"
          variant="outline-light"
          onClick={() => setShowVibes(!isShowVibes)}
        >
          VIBES
          {isShowVibes ? <RemoveIcon /> : <AddIcon />}
        </Button>
        {isShowVibes && (
          <>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={ambiance}
                attribute="ambiance"
                label="Ambiance"
                editData={tableData?.ambiance || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={games}
                attribute="games"
                label="Games"
                editData={tableData?.games || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={sports}
                attribute="sports"
                label="Sports Watching"
                editData={tableData?.sports || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div className="d-flex align-items-center">
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={typesOfSports}
                attribute="typesOfSports"
                label="Types of Sports"
                editData={tableData?.typesOfSports || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={events}
                attribute="events"
                label="Events"
                editData={tableData?.events || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={dogFriendly}
                attribute="dogFriendly"
                label="Pet Friendly"
                editData={tableData?.dogFriendly || []}
                isEdit={isEdit}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={amenities}
                attribute="amenities"
                label="Amenities"
                editData={tableData?.amenities || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={parking}
                attribute="parking"
                label="Parking"
                editData={tableData?.parking || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={time2visit}
                attribute="time2visit"
                label="Best Time to Visit"
                editData={tableData?.time2visit || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={dancing}
                attribute="dancing"
                label="Dancing"
                editData={tableData?.dancing || []}
                isEdit={isEdit}
                shouldAlphabetize={true}
              />
            </div>
            <div className="d-flex align-items-center">
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={barMusic}
                attribute="barMusic"
                label="Bar Music"
                editData={tableData?.barMusic || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
          </>
        )}
        <Button
          className="mr-2"
          variant="outline-light"
          onClick={() => setShowLocationType(!isShowLocationType)}
        >
          LOCATION TYPE
          {isShowLocationType ? <RemoveIcon /> : <AddIcon />}
        </Button>
        {isShowLocationType && (
          <>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={barType}
                attribute="barType"
                label="Bar Type"
                editData={tableData?.barType || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={specialtyType}
                attribute="specialtyType"
                label="Specialty Type"
                editData={tableData?.specialtyType || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={true}
              />
            </div>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={close2Others}
                attribute="close2Others"
                label="Close to Other bars"
                editData={tableData?.close2Others || []}
                isEdit={isEdit}
                isMulti={true}
                shouldAlphabetize={false}
              />
            </div>
          </>
        )}
        <Button
          variant="outline-light"
          onClick={() => setShowFood(!isShowFood)}
        >
          Food
          {isShowFood ? <RemoveIcon /> : <AddIcon />}
        </Button>
        {isShowFood && (
          <>
            <div>
              <MDropdown
                className="dropdown"
                formData={formData}
                setFormData={setFormData}
                listItems={offersFood}
                attribute="offersFood"
                label="Offers Food"
                editData={tableData?.offersFood || []}
                isEdit={isEdit}
                shouldAlphabetize={true}
              />
            </div>
          </>
        )}
      </SearchFieldsWrapper>
    </>
  );
};
