import ReactGA from "react-ga";

const locationSearch = () =>
  ReactGA.event({
    action: "location search",
    category: "Location",
  });

const gaEvents = {
  eventLocationSearch: locationSearch,
};

export default gaEvents;
