import React from "react";
import { MInput } from "../../design_system/MInput";
import { DropdownWrapper, Wrapper } from "./styled-components";
import { SearchInput } from "../../design_system/SearchInput";
import Form from "react-bootstrap/Form";
import Rating from "@mui/material/Rating";

export const AdminInput = ({ formData, setFormData, tableData, isEdit }) => {
  return (
    <>
      <Wrapper>
        <div>
          <p>Name</p>
          <MInput
            attribute="name"
            editData={tableData?.name || null}
            formData={formData}
            setFormData={setFormData}
            isEdit={isEdit}
          />
        </div>
        <div>
          <p>Address</p>
          <MInput
            attribute="address"
            formData={formData}
            editData={tableData?.address || null}
            setFormData={setFormData}
            isEdit={isEdit}
          />
        </div>
      </Wrapper>
      <DropdownWrapper>
        <SearchInput
          formData={formData}
          setFormData={setFormData}
          tableData={tableData}
          isEdit={isEdit}
        />
      </DropdownWrapper>
      <div className="m-2">
        <p className="m-0">Description</p>
        <Form.Control
          as="textarea"
          value={formData?.description || ""}
          onChange={(e) =>
            setFormData({
              ...formData,
              description: e.target.value,
            })
          }
        />
      </div>
      <div className="m-2">
        <p className="m-0">Confidence Level</p>
        <Rating
          name="half-rating-read"
          value={parseInt(formData.confidenceLevel)}
          onChange={(e) =>
            setFormData({ ...formData, confidenceLevel: e.target.value })
          }
        />
      </div>
    </>
  );
};
