import React, { useEffect, useState } from "react";
import { DataState } from "../context/AuthProvider";
import {
  GoogleMap as GoogleMapReact,
  useJsApiLoader,
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../utils/api";
import { Button } from "react-bootstrap";
import MMarker from "./MMarker";
import { isEmpty } from "../utils/util";
import MButtonGroup from "./MButtonGroup";

export const MMap = ({ centerLocation, isDrinkNearModel, distance }) => {
  useEffect(() => {
    if (distance) {
      setMapFormData({ ...mapFormData, distance: distance });
    }
  }, [distance]);
  const { mapFormData, setMapFormData } = DataState();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });
  const [center, setCenter] = useState({
    lat: 39.7536237361807,
    lng: -104.97601230939361,
  });

  const onLoad = React.useCallback(
    function callback(map) {
      map.setCenter(center);
      map.setZoom(14);

      // eslint-disable-next-line
    },
    [center]
  );

  const setNewPosition = (marker) => {
    const currentMarkers = mapFormData?.markers || [];
    let newData = {};
    newData.markers = currentMarkers.filter(
      (mk) =>
        mk.geometry.location.lat !== marker.geometry.location.lat ||
        mk.geometry.location.lng !== marker.geometry.location.lng
    );
    if (distance) {
      newData.distance = distance;
    } else {
      newData.distance = 500;
    }
    newData.markers = [...newData.markers, marker];
    setMapFormData(newData);
  };

  useEffect(() => {
    if (!isEmpty(centerLocation)) {
      setCenter(centerLocation.geometry.location);
      setNewPosition(centerLocation);
    }

    // eslint-disable-next-line
  }, [centerLocation]);

  const handleMapClick = (event) => {
    const newMarker = {
      geometry: {
        location: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      },
    };
    setNewPosition(newMarker);
  };

  const handleRemoveMarker = (info) => {
    const currentMarkers = mapFormData?.markers || [];
    let newMarkers = currentMarkers.filter(
      (marker) =>
        marker.geometry.location.lat !== info.geometry.location.lat ||
        marker.geometry.location.lng !== info.geometry.location.lng
    );
    setMapFormData({ ...mapFormData, markers: newMarkers });
  };

  const handleRemoveAll = () => {
    setMapFormData({});
  };

  const handlePickCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const newMarker = {
        geometry: {
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        },
      };
      setCenter(newMarker.geometry.location);
      setNewPosition(newMarker);
    });
  };

  const renderOprationGroup = () => {
    return (
      <div className="d-flex justify-content-between align-items-start mb-1 flex-wrap gap-2 px-4">
        <div className="d-flex">
          <Button
            className="mr-2"
            variant="outline-light"
            onClick={handlePickCurrentLocation}
            size="sm"
          >
            Current Position
          </Button>
          <Button
            className="mr-2"
            variant={
              isEmpty(mapFormData.distance)
                ? "outline-light"
                : "outline-primary"
            }
            onClick={handleRemoveAll}
            size="sm"
          >
            Remove All
          </Button>
        </div>
        <MButtonGroup
          listItems={[500, 1500, 2500, 3500, 5000]}
          formData={mapFormData}
          setFormData={setMapFormData}
          attribute={"distance"}
          isEdit={true}
          isMultiSelect={false}
          disabled={false}
          activeVariant="outline-light"
          size="sm"
        />
      </div>
    );
  };

  return (
    <div>
      {!isDrinkNearModel && renderOprationGroup()}
      {isLoaded ? (
        <GoogleMapReact
          mapContainerStyle={{ height: "64vh", width: "100%" }}
          onLoad={onLoad}
          onClick={handleMapClick}
          center={center}
        >
          {mapFormData?.markers?.map((info, index) => (
            <MMarker
              info={info}
              key={index}
              setCurOpened={(data) => handleRemoveMarker(data)}
              icon="arrow"
              hideInfoWindow={true}
              distance={mapFormData.distance}
              withDistance
            />
          ))}
        </GoogleMapReact>
      ) : (
        <></>
      )}
    </div>
  );
};
