import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getUserById,
  updateUserProfile,
  uploadFileToServer,
} from "../../utils/api";
import { Col, Row, Button, Form, Spinner } from "react-bootstrap";
import MCardView from "../../design_system/MCardView";
import { AuthState } from "../../context/AuthProvider";
import { dateFormat, isEmpty } from "../../utils/util";
import MBanner from "../../design_system/MBanner";
import { ButtonGroupWrapper } from "../../design_system/styled-components";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MButtonGroup from "../../design_system/MButtonGroup";
import { MDropdown } from "../../design_system/MDropdown";
import MDatePicker from "../../design_system/MDatePicker";

const EditAvatarBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#1DA1F2",
    color: "#fff",
    border: "3px solid #FFF",
    borderRadius: "0.3125rem",
    width: "1.75rem",
    height: "1.75rem",
    margin: "0.75rem",
    padding: "0.75rem",
    "MuiSvgIcon-root": {
      width: "0.9rem",
      height: "0.9rem",
    },
    input: {
      position: "absolute",
      width: "2rem",
      height: "2rem",
      opacity: 0,
      cursor: "pointer",
    },
  },
}));

const UserPage = () => {
  const { id } = useParams();
  const { auth: userData, setAuth } = AuthState();
  const [isLoading, setIsLoading] = useState(false);
  const { favorite_locations, isAuthenticated } = userData || {};
  const [formData, setFormData] = useState(userData);
  const favoriteLocationData = favorite_locations?.slice(
    0,
    Math.min(favorite_locations.length, 2)
  );

  const [isUpdateQuestion, setIsUpdateQuestion] = useState(false);
  const questions = [
    {
      title: "What type of Bars Do You Enjoy?",
      attribute: "barType",
      multiSelect: true,
      options: [
        "College kids(party)",
        "Mature/upscale(classy)",
        "Sports bar",
        "Dive",
        "Clubs",
        "Lounge",
      ],
      type: "buttonGroup",
    },
    {
      title: "What Experiences Are You Looking For?",
      attribute: "experienceType",
      multiSelect: true,
      options: ["Events", "Restaurants", "Bars"],
      type: "buttonGroup",
    },
    {
      title: "What Do You Like to Drink?",
      attribute: "drinkType",
      multiSelect: true,
      options: ["Mixed drinks", "Beer", "Seltzer", "Wine", "Mixology drinks"],
      type: "buttonGroup",
    },
    {
      title: "What is your Typical Weekend Night Spend?",
      subQuestion: [
        {
          title: "On a typical weekend night out how much do you spend?",
          attribute: "currentSpend",
          options: ["$10-25", "$26-50", "$50-100", "$100-200", "$200+"],
          multiSelect: false,
          type: "buttonGroup",
        },
        {
          title: "Are you happy about this?",
          attribute: "statusSpend",
          options: ["Yes", "No"],
          multiSelect: false,
          type: "buttonGroup",
        },
        {
          title:
            "On a typical weekend night out how much would you like to spend?",
          attribute: "idealSpend",
          options: ["$10-25", "$26-50", "$50-100", "$100-200", "$200+"],
          multiSelect: false,
          type: "buttonGroup",
        },
      ],
      type: "group",
    },
    {
      title: "How Old are You?",
      subQuestion: [
        {
          title: "How old are you?",
          attribute: "birthday",
          label: "",
          options: [],
          type: "datepicker",
        },
        {
          title: "What age do you feel?",
          attribute: "idealAge",
          label: "",
          options: Array.from({ length: 100 }, (_, index) => ({
            text: index,
            value: index,
          })),
          type: "dropdown",
        },
      ],
      type: "group",
    },
    {
      title: "What Gender do you Identify as?",
      attribute: "gender",
      multiSelect: false,
      options: ["Male", "Female", "None-binary", "Prefer not to say"],
      type: "buttonGroup",
    },
    {
      title: "What Ethnicity are you?",
      attribute: "identify",
      multiSelect: false,
      options: [
        "American Indian or Alaskan Native",
        "Asian / Pacific Islander",
        "Black or African American",
        "Hispanic",
        "White / Caucasian",
        "Other",
        "Prefer not to say",
      ],
      type: "buttonGroup",
    },
    {
      title: "What brings you to Zappt?",
      attribute: "reason",
      multiSelect: true,
      options: [
        "New date ideas",
        "Explore new spots",
        "Looking for something in particular",
        "Just here for fun",
        "Prefer not to say",
      ],
      type: "buttonGroup",
    },
    {
      title: "Ideal night out?",
      attribute: "ideal",
      multiSelect: true,
      options: [
        "Craft cocktails",
        "Good beer",
        "Swanky vibe",
        "High energy",
        "Cozy spot",
        "Snacks on hand",
        "Lots around to walk to",
        "Meeting new people",
        "None of these",
      ],
      type: "buttonGroup",
    },
  ];

  useEffect(() => {
    getUserById(id).then((userDetail) => {
      setAuth({
        ...userData,
        ...userDetail,
        firstName: userDetail.first_name,
        lastName: userDetail.last_name,
      });
      setFormData({
        ...userDetail,
        firstName: userDetail.first_name,
        lastName: userDetail.last_name,
      });
    });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setFormData({
      ...formData,
      age: new Date().getFullYear() - new Date(formData.birthday).getFullYear(),
    });
    // eslint-disable-next-line
  }, [formData.birthday]);

  const handleChangeWithName = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isUpdateQuestion) setIsUpdateQuestion(false);
    updateUserProfile({ formData })
      .then((data) => {
        setAuth({ ...userData, ...formData, ...data, intake: userData.intake });
        setIsLoading(false);
      })
      .catch("null");
  };

  const renderQuestionCmp = (question) => {
    if (question.type === "buttonGroup") {
      return (
        <MButtonGroup
          listItems={question.options}
          formData={formData}
          setFormData={setFormData}
          attribute={question.attribute}
          isEdit={isUpdateQuestion}
          isMultiSelect={question.multiSelect}
          disabled={!isUpdateQuestion}
          prefix="letter"
          activeVariant="primary"
          normalVariant="outline-primary"
          size="sm"
        />
      );
    } else if (question.type === "datepicker") {
      if (isUpdateQuestion)
        return (
          <>
            <MDatePicker
              className="dropdown"
              formData={formData}
              setFormData={setFormData}
              listItems={question.options}
              attribute={question.attribute}
              label={question.label}
              editData={formData[question.attribute] || []}
              isEdit={isUpdateQuestion}
            />
            {isEmpty(formData[question.attribute]) ? null : (
              <Button
                size="sm"
                variant="outline-secondary"
                disabled
                className="ml-2 black opacity-75"
              >
                {`${formData.age} year old`}
              </Button>
            )}
          </>
        );
      else if (!isEmpty(formData[question.attribute]))
        return (
          <Button size="sm" variant="primary" disabled>
            - {dateFormat(formData[question.attribute])} / {formData.age} year
            old
          </Button>
        );
      else return null;
    } else if (question.type === "dropdown") {
      if (isUpdateQuestion)
        return (
          <MDropdown
            className="dropdown"
            formData={formData}
            setFormData={setFormData}
            listItems={question.options}
            attribute={question.attribute}
            label={question.label}
            editData={formData[question.attribute] || []}
            isEdit={isUpdateQuestion}
          />
        );
      else if (!isEmpty(formData[question.attribute]))
        return (
          <Button variant="primary" disabled>
            - {formData[question.attribute]} year old
          </Button>
        );
      else return null;
    } else if (question.type === "group") {
      return question.subQuestion.map((subQuestion, index) => (
        <div key={"subQuestion" + index} className="mb-2">
          <p className="mb-1 sub-title">
            {`${index + 1}. ${subQuestion.title}`}
          </p>
          {renderQuestionCmp(subQuestion)}
        </div>
      ));
    }
    return null;
  };

  const handleChangeUserAvatar = async (e) => {
    try {
      if (e.target.files.length === 0 || isLoading) return;
      setIsLoading(true);
      setFormData({ ...formData, avatar: "" });
      const form = new FormData();
      form.append("avatar", e.target.files[0]);
      const res = await uploadFileToServer(form);
      setFormData({ ...formData, avatar: res?.secure_url });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleCancelUpdateProfile = () => {
    setFormData(userData);
    setIsUpdateQuestion(false);
  };

  return (
    <>
      <MBanner title="User Profile" signed={isAuthenticated}>
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <Row xs={1} sm={2} className="w-100 m-0 p-4 justify-content-center ">
        <Col xs={11} sm={9} xl={5} className="mb-3 p-1">
          <div className="mCard">
            <div className="d-flex mb-4">
              <EditAvatarBadge
                className="m-auto cursor-pointer"
                overlap="rectangular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  isLoading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    <>
                      <EditOutlinedIcon />
                      <input type="file" onChange={handleChangeUserAvatar} />
                    </>
                  )
                }
              >
                <Avatar
                  alt="user avatar"
                  variant="rounded"
                  sx={{
                    width: "8.75rem",
                    height: "8.75rem",
                    borderRadius: "0.875rem",
                  }}
                  src={formData.avatar}
                />
              </EditAvatarBadge>
            </div>
            <Form className="w-100" onSubmit={handleUpdateProfile}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label className="m-0">First Name</Form.Label>
                <TextField
                  type="text"
                  name="firstName"
                  tabIndex="1"
                  className="d-block w-100"
                  placeholder="First Name"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": { width: "100%", padding: 0 },
                    input: { padding: "0.6rem" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <EditOutlinedIcon sx={{ width: "1.15rem" }} />
                      </InputAdornment>
                    ),
                  }}
                  value={formData?.firstName || ""}
                  onChange={handleChangeWithName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label className="m-0">Last Name</Form.Label>
                <TextField
                  type="text"
                  name="lastName"
                  tabIndex="2"
                  className="d-block w-100"
                  placeholder="Last Name"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": { width: "100%", padding: 0 },
                    input: { padding: "0.6rem" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <EditOutlinedIcon sx={{ width: "1.15rem" }} />
                      </InputAdornment>
                    ),
                  }}
                  value={formData?.lastName || ""}
                  onChange={handleChangeWithName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="m-0">Last Name</Form.Label>
                <TextField
                  type="email"
                  name="email"
                  tabIndex="3"
                  className="d-block w-100"
                  placeholder="Last Name"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": { width: "100%", padding: 0 },
                    input: {
                      padding: "0.6rem",
                      // "-webkit-text-fill-color": "black !important",
                    },
                  }}
                  value={userData?.email || ""}
                  // disabled
                />
              </Form.Group>

              <Button
                type="submit"
                size="lg"
                tabIndex="3"
                className="m-0 my-3 w-100"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            </Form>
          </div>
          {!isEmpty(favoriteLocationData) &&
            !isEmpty(favoriteLocationData[0]) && (
              <div className="mCard mt-4">
                <h4>Favorite Location</h4>
                <Row xs={1} md={2}>
                  {favoriteLocationData?.map((info) => (
                    <Col className="my-2" key={info?.id}>
                      <MCardView info={info} />
                    </Col>
                  ))}
                </Row>
                <div className="d-flex">
                  <Link to="/favorite" className="m-auto my-3">
                    <Button size="lg">See All</Button>
                  </Link>
                </div>
              </div>
            )}
          <div className="mCard mt-4">
            <h4>For Owners</h4>
            <div className="d-flex">
              <Link to="/claim-location" className="m-auto my-3">
                <Button size="lg">Claim Location</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={11} sm={11} xl={7} className="pl-md-5">
          <div className="mCard">
            {!isUpdateQuestion && (
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4>User Questions</h4>
                <div>
                  {/* <Button size="md">
                <AddOutlinedIcon sx={{ height: "1.2rem" }} />
                Add
              </Button> */}
                  <Button
                    size="md"
                    variant="outline-dark"
                    className="ml-2"
                    onClick={() => setIsUpdateQuestion(true)}
                    disabled={isLoading}
                  >
                    <EditOutlinedIcon sx={{ height: "1.1rem" }} />
                    Edit
                  </Button>
                </div>
              </div>
            )}
            <ButtonGroupWrapper>
              {questions.map((question, index) => (
                <div key={index}>
                  <p className="mb-2">{`${index + 1}. ${question.title}`}</p>
                  {renderQuestionCmp(question)}
                </div>
              ))}
            </ButtonGroupWrapper>
            <div className="d-flex justify-content-end">
              {isUpdateQuestion && (
                <>
                  <Button
                    size="lg"
                    variant="outline-dark"
                    onClick={handleCancelUpdateProfile}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="lg"
                    className="ml-2"
                    onClick={handleUpdateProfile}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserPage;
