import React from "react";
import { Marker, InfoWindow, Circle } from "@react-google-maps/api";
import MCardView from "./MCardView";
import styled from "styled-components";

export const InfoWindowWrapper = styled.div`
  .card {
    box-shadow: none;
  }

  @media only screen and (max-width: 480px) {
    // width: auto;
  }
`;

export default function MMarker({
  info,
  icon,
  curOpened,
  setCurOpened,
  hideInfoWindow = false,
  distance,
  withDistance = false,
}) {
  return (
    <>
      <Marker
        info={info}
        key={info?.id}
        position={info?.geometry?.location}
        onClick={() => setCurOpened(info)}
        icon={
          icon === "star"
            ? "/images/icons/star.svg"
            : icon === "circle"
              ? {
                path: window.google.maps.SymbolPath.CIRCLE,
                strokeColor: "#444",
                scale: 7,
              }
              : icon === "arrow"
                ? {
                  path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                  strokeColor: "#e44",
                  scale: 3,
                } : ""
        }
      >
        {!hideInfoWindow && curOpened?.id === info?.id && (
          <InfoWindow onCloseClick={() => setCurOpened(null)}>
            <InfoWindowWrapper>
              <MCardView info={info} />
            </InfoWindowWrapper>
          </InfoWindow>
        )}
      </Marker>
      {withDistance && parseInt(distance) > 0 && (
        <Circle
          center={info?.geometry?.location}
          radius={distance / 3.281}
          options={{
            fillColor: "#ff333399",
            strokeColor: "#0000",
          }}
        />
      )}
    </>
  );
}
