import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { isEmpty } from "../utils/util";
import { setAPIHeader } from "../utils/api";

const AuthContext = createContext();
const SidebarContext = createContext();
const DataContext = createContext();

const AuthProvider = ({ children }) => {
  // If "auth" !== null and token is not expired, save the auth details inside "auth" state else set it to null
  const [auth, setAuth] = useState(
    !isEmpty(localStorage.getItem("auth")) &&
      new Date() < new Date(JSON.parse(localStorage.getItem("auth")).expires_at)
      ? JSON.parse(localStorage.getItem("auth"))
      : null
  );
  setAPIHeader(auth?.token);
  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  // for user search
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({});
  const [excFormData, setExcFormData] = useState({});
  const [mapFormData, setMapFormData] = useState({});
  const [mostFavoriteLocations, setMostFavoriteLocations] = useState([]);
  const [featuredLocations, setFeaturedLocations] = useState([]);

  // for admin edit data
  const [editLocation, setEditLocation] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <SidebarContext.Provider value={{ isSidebarOpen, setSidebarOpen }}>
        <DataContext.Provider
          value={{
            tableData,
            setTableData,
            mostFavoriteLocations,
            setMostFavoriteLocations,
            featuredLocations,
            setFeaturedLocations,
            formData,
            setFormData,
            excFormData,
            setExcFormData,
            mapFormData,
            setMapFormData,
            editLocation,
            setEditLocation,
          }}
        >
          {children}
        </DataContext.Provider>
      </SidebarContext.Provider>
    </AuthContext.Provider>
  );
};

export const AuthState = () => {
  return useContext(AuthContext);
};

export const SidebarState = () => {
  return useContext(SidebarContext);
};

export const DataState = () => {
  return useContext(DataContext);
};

export default AuthProvider;
