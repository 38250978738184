import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SidebarWrapper } from "./styled-components";
import { AuthState, SidebarState } from "../context/AuthProvider";
import { SignOutIcon } from "../utils/icons";
import { googleLogout } from "@react-oauth/google";

const drawerWidth = "15rem";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "& .MuiListItemIcon-root": {
    marginRight: "12px",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `5rem`,
  [theme.breakpoints.up("sm")]: {
    width: `7rem`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  borderColor: "white !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  marginTop: "auto",
  borderTop: "1px solid #4FBCFF",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ".MuiDrawer-paper": {
    border: "none",
    backgroundColor: "var(--primary-color) !important",
    "& .MuiListItemIcon-root, li": {
      color: "white !important",
    },
    "& .MuiListItem-root": {
      padding: "0.25rem 0.5rem",
    },
    "& .MuiListItemButton-root:hover, .active": {
      borderRadius: "0.375rem",
      background: "#0D8AD7",
    },
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MSidebar({ navList }) {
  const { isSidebarOpen, setSidebarOpen } = SidebarState();
  const { setAuth } = AuthState();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    googleLogout();
    setAuth({});
    navigate("/");
  };

  return (
    <SidebarWrapper open={isSidebarOpen}>
      <div className="toggle-button">
        <IconButton onClick={() => setSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? (
            <KeyboardDoubleArrowLeft />
          ) : (
            <KeyboardDoubleArrowRight />
          )}
        </IconButton>
      </div>
      <Drawer
        variant="permanent"
        open={isSidebarOpen}
        className="d-none d-xl-block"
      >
        <DrawerHeader>
          <img
            className="logo"
            alt="zappt"
            src={require("../images/zappt-white.png")}
          />
        </DrawerHeader>
        <Divider sx={{ borderColor: "#4FBCFF", opacity: 1 }} />
        <List sx={{ width: isSidebarOpen ? "15rem" : "7rem" }}>
          {navList.map((item) => (
            <Link key={item.text} to={item.url} className="no-decoration">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  className={location.pathname === item.url ? "active" : ""}
                  sx={{
                    minHeight: 48,
                    justifyContent: isSidebarOpen ? "initial" : "center",
                    px: isSidebarOpen ? 3 : 5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isSidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ display: isSidebarOpen ? "block" : "none" }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <DrawerFooter>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleSignOut}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isSidebarOpen ? "initial" : "center",
                px: isSidebarOpen ? 3 : 5,
                my: 1.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isSidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Log Out"
                sx={{ display: isSidebarOpen ? "block" : "none" }}
              />
            </ListItemButton>
          </ListItem>
        </DrawerFooter>
      </Drawer>
    </SidebarWrapper>
  );
}
