import React, { useEffect, useState } from "react";
import { AuthState } from "../../../context/AuthProvider";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllClaims, setHandleLocationClaim } from "../../../utils/api";
import MBanner from "../../../design_system/MBanner";
import notify from "../../../utils/notify";
import { dateFormat } from "../../../utils/util";
import { UserRole } from "../../../utils/constants";
import MPagination from "../../../design_system/MPagination";
import { MInput } from "../../../design_system/MInput";
import MTableCmp from "../../../design_system/MTableCmp";
import { Tooltip } from "@mui/material";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";

export const LocationOwners = () => {
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [tableData, setTableData] = useState([]);
  const [searchFormData, setSearchFormData] = useState({});
  const [sortFormData, setSortFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(1);
  const tableInfo = [
    {
      key: "name",
      sortable: true,
      render: (cell, item) => <Link to={`/location/${item.id}`}>{cell}</Link>
    },
    { key: "address", sortable: true },
    { key: "persons_name", sortable: true },
    {
      key: "claim_actions",
      label: "Actions",
      render: (cell, item) =>
        <div className="d-flex align-items-center justify-content-end">
          <div className="cursor-pointer">
            <Tooltip title="Approve">
              <span onClick={() => handleApprove(cell, item)}><CheckOutlined sx={{ color: "#1da2f2" }} /></span>
            </Tooltip>
          </div>

          <div className="cursor-pointer ml-2">
            <Tooltip title="Refuse">
              <span onClick={() => handleRefuse(cell, item)}><CloseOutlined sx={{ color: "#DC4635" }} /></span>
            </Tooltip>
          </div>
        </div>
    },
    {
      key: "status", sortable: true, render: (cell) =>
        <div className="text-center">
          {cell === true ? (
            <Button size="sm" variant="outline-info">
              Approved
            </Button>
          ) : cell === false ? (
            <Button size="sm" variant="outline-danger">
              Refused
            </Button>
          ) :
            <Button size="sm" variant="light">
              Pending
            </Button>
          }
        </div>
    },
    { key: "description" },
    { key: "email", sortable: true, },
    {
      key: "role",
      label: "User Role",
      sortable: true,
      render: (cell, item) => (parseInt(cell) === UserRole.ADMIN) ? "Admin" : parseInt(cell) === UserRole.OWNER ? "Owner" : "User"
    },
    {
      key: "join_date",
      sortable: true,
      render: (cell) => dateFormat(cell)
    },
    {
      key: "date",
      label: "Request Date",
      sortable: true,
      render: (cell) => dateFormat(cell)
    }
  ]
  const radioOptions = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "refused", label: "Refused" },
  ];
  const [radioChecked, setRadioChecked] = useState("all");

  function loadData() {
    const abortController = new AbortController();
    getAllClaims(
      {
        sort: sortFormData,
        search: searchFormData.search,
        page: currentPage,
        checked: radioChecked,
      },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.data);
        setTotalSize(data.total);
      })
      .catch("null");
  }

  useEffect(() => {
    loadData();

    // eslint-disable-next-line
  }, [sortFormData, currentPage, radioChecked]);

  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      loadData();
      event.target.blur();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApprove = (text, item) => {
    setHandleLocationClaim({ item, status: true })
      .then(() => {
        const updatedData = tableData.map(location =>
          location.id === item.id && location.persons_id === item.persons_id ? { ...location, status: true } : location
        );
        setTableData(updatedData);
        notify("Successfully Approved!", "success");
      })
      .catch("null");
  }

  const handleRefuse = (text, item) => {
    setHandleLocationClaim({ item, status: false })
      .then(() => {
        const updatedData = tableData.map(location =>
          location.id === item.id && location.persons_id === item.persons_id ? { ...location, status: false } : location
        );
        setTableData(updatedData);
        notify("Successfully Refused!", "success");
      })
      .catch("null");
  }

  return (
    <>
      <MBanner title="Location Claims" signed={isAuthenticated}>
        <Link className="link" to="/metrics">
          Admin Metrics
        </Link>
      </MBanner>
      <div className="m-4">
        <div className="mx-2 d-flex justify-content-between align-items-center">
          <h4 className="m-0">Data Analysis</h4>
          <div className="d-flex justify-content-end align-items-center">
            {radioOptions.map((option, index) => (
              <Form.Check
                className="mx-2"
                type="radio"
                name={option.value}
                id={option.value}
                key={index}
                label={option.label}
                value={radioChecked === option.value}
                onChange={(e) => setRadioChecked(option.value)}
                checked={radioChecked === option.value}
              />
            ))}
            <div className="mr-2">
              <MInput
                attribute="search"
                formData={searchFormData}
                setFormData={setSearchFormData}
                editData=""
                isEdit={true}
                onKeyDown={handlePressEnter}
              />
            </div>
            <Button variant="danger" onClick={() => { }}>Delete All Refused</Button>
          </div>
        </div>
        <MTableCmp
          tableInfo={tableInfo}
          tableData={tableData}
          setSortFormData={setSortFormData}
          sortFormData={sortFormData}
        // handleDelete={handleDelete}
        // handleFetch={handleAnalyze}
        />
        <MPagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          total={totalSize}
          pageSize={50}
        />
      </div>
    </>
  );
};
