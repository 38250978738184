import * as React from 'react';
import Slider from '@mui/material/Slider';

export default function MSlider({ value, onChange, minValue, maxValue, stepValue }) {
  return (
    <Slider
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      valueLabelDisplay="auto"
      step={stepValue}
      marks
      min={minValue}
      max={maxValue}
    />
  );
}