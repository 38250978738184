import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const MInput = ({
  attribute,
  formData,
  setFormData,
  editData,
  isEdit,
  className,
  formClassName,
  ...props
}) => {
  const [localStateInput, setLocalStateInput] = useState(editData);
  const setFormDataValue = (value) => {
    if (isEdit) {
      setFormData({
        ...formData,
        [attribute]: value,
      });
    } else {
      const myUUID = uuidv4();
      setFormData({
        ...formData,
        id: myUUID,
        [attribute]: value,
      });
    }
  };

  const onChangeHandler = (event) => {
    setLocalStateInput(event.target.value);
    if (props.type === "file") {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const data = e.target.result;
          setFormDataValue(data);
        };
      }
      return;
    }
    setFormDataValue(event.target.value);
  };

  return (
    <TextField
      type="search"
      placeholder="Search"
      className={`form-control ${className}`}
      variant="outlined"
      sx={{
        "& .MuiInputBase-root": {
          width: "100%",
          padding: 0,
          borderRadius: "0.5rem",
        },
        input: { padding: "0.6rem" },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon sx={{ width: "1.15rem" }} />
          </InputAdornment>
        ),
      }}
      value={localStateInput || ""}
      onChange={onChangeHandler}
      {...props}
    />
  );
};
