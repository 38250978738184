import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SearchModalWrapper } from "./styled-components";
import MSlider from "./MSlider";
import { MMap } from "./MMap";
import { DataState } from "../context/AuthProvider";

export const MSearchDrinksModal = ({ isOpen, setIsOpen, handleSubmit }) => {
  const { mapFormData, setMapFormData } = DataState();
  const [showMap, setShowMap] = useState(false);
  const [center, setCenter] = useState({
    lat: 39.7536237361807,
    lng: -104.97601230939361,
  });

  useEffect(() => {
    if (isOpen) {
      navigator.geolocation.getCurrentPosition((position) => {
        const curPosition = {
          geometry: {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
        };
        setCenter(curPosition);
        setMapFormData({
          distance: mapFormData.distance || 300,
          markers: [curPosition],
        });
      });
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handClickDifferent = () => {
    setShowMap(true);
  };

  return (
    <Modal show={isOpen} onHide={() => handleClose()} backdrop={true}>
      <SearchModalWrapper>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title text-center" id="exampleModalLabel">
                Select Range
              </h5>
            </div>
            <div className="my-4 mx-5">
              <p>Distance: {mapFormData.distance} feet</p>
              <MSlider
                value={mapFormData.distance || 0}
                onChange={(e) =>
                  setMapFormData({ ...mapFormData, distance: e })
                }
                minValue={100}
                maxValue={5000}
                stepValue={100}
              />
            </div>
            <div className="my-4 mx-5">
              <span
                style={{
                  color: "#1da1f2",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handClickDifferent}
              >
                Use a different location?
              </span>
            </div>
            {showMap && (
              <MMap
                centerLocation={center}
                isDrinkNearModel={true}
                distance={mapFormData.distance}
              />
            )}
            <div className="modal-footer py-3 px-4 mx-1">
              <Button
                size="lg"
                data-dismiss="modal"
                variant="outline-dark"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                data-dismiss="modal"
                variant="primary"
                onClick={() => handleSubmit()}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </SearchModalWrapper>
    </Modal>
  );
};
