import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SearchModalWrapper } from "./styled-components";
import { MSearchGroup } from "./MSearchGroup";
import { MMap } from "./MMap";
import MLocationSuggest from "./MLocationSuggest";

export const MSearchModal = ({
  isShowSearchModal,
  setShowSearchModal,
  modalId,
  formData,
  setFormData,
  handleExclusionButton,
  excFormData,
  setExcFormData,
  exclusion,
  handleSearch,
  handleClearSearch
}) => {
  const handleClose = () => {
    setShowSearchModal(false);
  };
  const [curState, setCurState] = useState("map");
  const [criteriaState, setCriteriaState] = useState("inclusion");
  const [curSuggestion, setCurSuggestion] = useState(null);

  const handleSuggestionChange = (newSuggestion) => {
    setCurSuggestion(newSuggestion);
  }

  return (
    <Modal
      style={{ paddingLeft: 0 }}
      show={isShowSearchModal}
      onHide={() => handleClose()}
      backdrop={true}
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <SearchModalWrapper>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title text-center" id="exampleModalLabel">
                Apply Filter
              </h5>
            </div>
            <div className="modal-body">
              <div className="filter-group">
                <div className="d-flex align-items-center justify-content-between px-4 pt-4 pb-3 gap-2">
                  <Button
                    size="lg"
                    variant="outline-secondary"
                    className={`${curState === "map" ? "active" : ""} w-25 mx-0`}
                    onClick={() => setCurState("map")}
                  >
                    Location
                  </Button>
                  <div className="w-25" />
                  <div className="w-25" />
                  <span className="w-25 text-end btn-outline-primary">
                    Clear
                  </span>
                </div>
                <div className="d-flex justify-content-between px-4 flex-wrap flex-md-nowrap gap-2">
                  <Button
                    className={`${curState === "type" ? "active" : ""} w-25 mx-0`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCurState("type")}
                  >
                    Type
                  </Button>
                  <Button
                    className={`${curState === "vibe" ? "active" : ""} w-25 mx-0`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCurState("vibe")}
                  >
                    Vibe
                  </Button>
                  <Button
                    className={`${curState === "drinks" ? "active" : ""} w-25 mx-0`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCurState("drinks")}
                  >
                    Drinks
                  </Button>
                  <Button
                    className={`${curState === "food" ? "active" : ""} w-25 mx-0`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCurState("food")}
                  >
                    Food
                  </Button>
                </div>
                <div className="d-flex justify-content-between px-4 mt-4 gap-2">
                  <Button
                    className={`${criteriaState === "inclusion" ? "active" : ""} criteria-btn`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCriteriaState("inclusion")}
                  >
                    Inclusion Criteria
                  </Button>
                  <div className="w-25 d-none d-lg-block" />
                  <div className="w-25 d-none d-lg-block" />
                  <Button
                    className={`${criteriaState === "exclusion" ? "active" : ""} criteria-btn`}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => setCriteriaState("exclusion")}
                  >
                    Exclusion Criteria
                  </Button>
                </div>
              </div>
              {curState === "map" ?
                <>
                  <div className="p-4">
                    <MLocationSuggest value={curSuggestion} onChange={handleSuggestionChange} />
                  </div>
                  <MMap centerLocation={curSuggestion} />
                </>
                :
                <MSearchGroup
                  curState={curState}
                  formData={criteriaState === "inclusion" ? formData : excFormData}
                  setFormData={criteriaState === "inclusion" ? setFormData : setExcFormData}
                />
              }
            </div>
            <div className="modal-footer py-3 px-4 mx-1">
              <Button size="lg" data-dismiss="modal" variant="outline-dark" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="lg" data-dismiss="modal" variant="primary" onClick={handleSearch}>
                Search
              </Button>
            </div>
          </div>
        </div>
      </SearchModalWrapper>
    </Modal>
  );
};
