import React, { useState } from "react";
import { setSuggestionRating } from "../../utils/api";
import { Button, Card, Col, Row } from "react-bootstrap";
import { MStarRating } from "../../design_system/MStarRating";
import notify from "../../utils/notify";
import { numberFormat } from "../../utils/util";
import { MCardWrapper } from "../../design_system/styled-components";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PersonalDetail({ ratings, setRatings }) {
  const ratingItems = [
    { text: "Vibe", value: "vibe" },
    { text: "Amenties", value: "amenties" },
    { text: "Cost", value: "cost" },
    { text: "Quality", value: "quality" },
  ];
  const [updatedRates, setUpdatedRates] = useState([]);
  const [rateUpdated, setRateUpdated] = useState(false);

  const handleChangeStarRating = (e, key) => {
    setRatings({
      ...ratings,
      [key]: e.target.value,
    });
    if (!updatedRates.includes(key)) setUpdatedRates([...updatedRates, key]);
    if (!rateUpdated) setRateUpdated(true);
  };

  const handleSubmitRating = () => {
    const abortController = new AbortController();
    let updatedRatings = {};
    updatedRates.forEach((key) => (updatedRatings[key] = ratings[key]));
    setSuggestionRating(
      { ratings: updatedRatings },
      abortController.signal
    ).then(() => {
      notify(`Saved Successfully`, "success");
    });
  };

  return (
    <>
      <Row xs={1} lg={2} className="w-100 m-0 p-4 justify-content-center ">
        <Col xs={12} lg={12} xl={12} className="mb-3 p-0">
          <MCardWrapper withRate>
            <Card className="h-100 mt-5">
              <Card.Body>
                <p className="title">How'd we do with this recommendation?</p>
                <Accordion
                  sx={{
                    boxShadow: "none!important",
                    border: "none !important",
                    marginBottom: "1rem",
                    marginTop: "0 !important",
                    padding: "0 !important",
                    ".MuiAccordionSummary-root": {
                      width: "100% !important",
                      padding: "0 !important",
                      ".MuiAccordionSummary-content": {
                        margin: "0!important",
                      },
                    },
                    ".MuiCollapse-root": {
                      width: "100% !important",
                    },
                    ":before": { backgroundColor: "transparent !important" },
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="ratings"
                  >
                    <div className="d-flex align-items-center mb-2">
                      {ratings?.overall >= 0 && (
                        <label className="mr-2">
                          {numberFormat(ratings?.overall)}
                        </label>
                      )}

                      <MStarRating
                        size="lg"
                        readOnly={false}
                        value={parseFloat(ratings?.overall) || 0}
                        onChange={(e) => handleChangeStarRating(e, "overall")}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 }}>
                    {ratingItems.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between flex-wrap gap-2 mb-2"
                      >
                        <label>{item.text}</label>
                        <div className="d-flex align-items-center justify-content-end">
                          {ratings && ratings[item.value] >= 0 && (
                            <label className="mr-2">
                              {numberFormat(ratings && ratings[item.value])}
                            </label>
                          )}

                          <MStarRating
                            size="lg"
                            readOnly={false}
                            value={parseFloat(ratings && ratings[item.value])}
                            onChange={(e) =>
                              handleChangeStarRating(e, item.value)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <div className="d-flex justify-content-end">
                  {rateUpdated && (
                    <Button
                      className="ml-2 px-2"
                      size="md"
                      onClick={handleSubmitRating}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </MCardWrapper>
        </Col>
      </Row>
    </>
  );
}
