import React, { useEffect, useRef, useState } from "react";
import { AuthState, DataState } from "../../context/AuthProvider";
import { Button, Col, Row } from "react-bootstrap";
import MCardView from "../../design_system/MCardView";
import { Link } from "react-router-dom";
import MBanner from "../../design_system/MBanner";
import MButtonGroup from "../../design_system/MButtonGroup";
import { CloseIcon, FeaturedLocationIcon } from "../../utils/icons";
import { criteriaLists } from "../Location/DrowndownList";
import gaEvents from "../../utils/gaEvents";
import { listSearch } from "../../utils/api";
import useDebounce from "../../utils/hooks";
import { isEmpty } from "../../utils/util";
import notify from "../../utils/notify";

export const Search = () => {
  const {
    setTableData,
    tableData,
    // mostFavoriteLocations,
    setMostFavoriteLocations,
    formData,
    setFormData,
    excFormData,
    setExcFormData,
    mapFormData,
    setMapFormData,
  } = DataState();
  const isMounted = useRef(false);
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [isSeeMore, setIsSeeMore] = useState(false);

  const handleClearAndSearch = () => {
    setFormData({});
    setExcFormData({});
    setMapFormData({});
  };

  const handleSearch = () => {
    setTableData([]);
    setMostFavoriteLocations([]);
    const abortController = new AbortController();
    listSearch(
      { criteria: formData, excCriteria: excFormData, map: mapFormData },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.relevantData);
        setMostFavoriteLocations(data.mostFavoriteLocations);
        if (isEmpty(data.relevantData))
          notify("No locations in your search", "warn")
        gaEvents.eventLocationSearch();
      })
      .catch("null");
  };
  const debouncedHandleSearch = useDebounce(handleSearch, 300);

  useEffect(() => {
    if (isMounted.current) {
      debouncedHandleSearch();
    }

    // eslint-disable-next-line
  }, [formData, excFormData]);

  return (
    <>
      <MBanner title="Search Location" signed={isAuthenticated}>
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <div className="featured-title">
        <h1>
          <span>Search</span> Results
        </h1>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-start  justify-content-start">
            <Button
              className="w-6"
              size="sm"
              variant="link"
              onClick={handleClearAndSearch}
            >
              Clear All
            </Button>
            <div
              className="d-flex flex-wrap gap-2 justify-content-start align-items-center"
              style={{ width: "calc(100% - 6rem)" }}
            >
              {Object.keys(formData).map((criteria, index) => (
                <MButtonGroup
                  key={index}
                  size="sm"
                  listItems={criteriaLists[criteria]}
                  formData={formData}
                  setFormData={setFormData}
                  attribute={criteria}
                  isMultiSelect={true}
                  activeVariant="outline-light"
                  endIcon={<CloseIcon />}
                  withAttribute
                />
              ))}
              {Object.keys(excFormData).map((excCriteria, index) => (
                <MButtonGroup
                  key={index}
                  size="sm"
                  listItems={criteriaLists[excCriteria]}
                  formData={excFormData}
                  setFormData={setExcFormData}
                  attribute={excCriteria}
                  isMultiSelect={true}
                  activeVariant="outline-danger"
                  endIcon={<CloseIcon />}
                  withAttribute
                />
              ))}
            </div>
          </div>
          <Link to="/map-location">
            <Button
              className="w-8"
              variant="primary"
            // onClick={handleClearAndSearch}
            >
              <FeaturedLocationIcon />
              <span className="ml-2">See Map</span>
            </Button>
          </Link>
        </div>
        <Row xs={1} sm={2} xl={3}>
          {tableData.slice(0, Math.min(6, tableData.length))?.map((info) => (
            <Col className="my-2" key={info.id}>
              <MCardView info={info} withDesc withRate />
            </Col>
          ))}
        </Row>
        {tableData.length > 5 && (
          <>
            <Button
              size="lg"
              className="m-auto my-3"
              onClick={() => setIsSeeMore(true)}
            >
              See More
            </Button>
            <Row xs={1} md={3}>
              {isSeeMore &&
                tableData.slice(6, tableData.length)?.map((info) => (
                  <Col className="my-2" key={info.id}>
                    <MCardView info={info} withDesc withRate />
                  </Col>
                ))}
            </Row>
          </>
        )}
      </div>
    </>
  );
};
