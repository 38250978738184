import React from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { BoysNight } from "./BoysNight";
import { Dates } from "./Dates";
import { Events } from "./Events";
import { Location } from "./Location/Location";
import { GirlsNight } from "./GirlsNight";
import { Search } from "./Search";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Detail from "./Location/Detail";
import UserPage from "./User";
import { AuthState } from "../context/AuthProvider";
import ForgetPassword from "./SignIn/ForgetPassword";
import ResetPassword from "./SignIn/ResetPassword";
import { GoogleMap } from "./GoogleMap";
import { Recommendation } from "./Recommendation";
import { Help } from "./Help";
import { AddLocation } from "./Location/AddLocation";
import { Metrics } from "./Metrics";
import { FavoriteLocation } from "./FavoriteLocation";
import { UploadLicense } from "./UploadLicense";
import { DataAnalysis } from "./DataAnalysis";
import { ClaimLocation } from "./Owner/ClaimLocation";
import { LocationOwners } from "./Admin/LocationOwners";
import { UserRole } from "../utils/constants";
import { MyLocations } from "./Owner/MyLocations";

/**
 * Defines all the routes for the application.
 *
 * You will need to make changes to this file.
 *
 * @returns {JSX.Element}
 */

const PrivateRoute = ({ path, element }) => {
  const { auth } = AuthState();
  return auth !== null && new Date() < new Date(auth.expires_at) ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace state={{ from: path }} />
  );
  // return <Outlet />
};

const AdminPrivateRoute = ({ path, element }) => {
  const { auth } = AuthState();
  return auth !== null &&
    new Date() < new Date(auth.expires_at) &&
    parseInt(auth.role) === UserRole.ADMIN ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace state={{ from: path }} />
  );
};

const OwnerPrivateRoute = ({ path, element }) => {
  const { auth } = AuthState();
  return auth !== null && new Date() < new Date(auth.expires_at) &&
    (parseInt(auth.role) === UserRole.ADMIN || parseInt(auth.role) === UserRole.OWNER) ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace state={{ from: path }} />
  );
};

const RoutesPicker = () => {
  const routes = useRoutes([
    { path: "/", element: <Dashboard /> },
    { path: "/signIn", element: <SignIn /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    { path: "/reset-password/:resetToken", element: <ResetPassword /> },
    { path: "/signUp", element: <SignUp /> },
    { path: "/search", element: <Search /> },
    { path: "map-location", element: <GoogleMap /> },
    { path: "help", element: <Help /> },
    {
      path: "",
      element: <PrivateRoute />,
      children: [
        { path: "location/:id", element: <Detail /> },
        { path: "auth/:id", element: <UserPage /> },
        { path: "favorite", element: <FavoriteLocation /> },
        { path: "recommendation", element: <Recommendation /> },
        { path: "claim-location", element: <ClaimLocation /> },

        { path: "boysnight", element: <BoysNight /> },
        { path: "dates", element: <Dates /> },
        { path: "events", element: <Events /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "girlsnight", element: <GirlsNight /> },
      ],
    },
    {
      path: "",
      element: <AdminPrivateRoute />,
      children: [
        { path: "location", element: <Location /> },
        { path: "add-location", element: <AddLocation /> },
        { path: "add-location/:id", element: <AddLocation /> },
        { path: "metrics", element: <Metrics /> },
        { path: "upload-license", element: <UploadLicense /> },
        { path: "data-analysis", element: <DataAnalysis /> },
        { path: "location-owners", element: <LocationOwners /> },
      ],
    },
    {
      path: "",
      element: <OwnerPrivateRoute />,
      children: [
        { path: "claim-location", element: <ClaimLocation /> },
        { path: "my-location", element: <MyLocations /> },
      ],
    },
  ]);

  return routes;
};

export default RoutesPicker;
