import React, { useCallback, useEffect, useState } from "react";
import { AuthState } from "../../context/AuthProvider";
import { Col, Row } from "react-bootstrap";
import MCardView from "../../design_system/MCardView";
import { Link } from "react-router-dom";
import { isEmpty } from "../../utils/util";
import { getRecommendations } from "../../utils/api";
import MBanner from "../../design_system/MBanner";

export const FavoriteLocation = () => {
  const { auth } = AuthState();
  const { isAuthenticated, favoriteLocations } = auth || {};
  const [suggestions, setSuggestions] = useState({});

  const fetchData = useCallback(async () => {
    const data = await getRecommendations();
    setSuggestions(data);
  }, []);

  useEffect(() => {
    if (!isEmpty(auth) && !isAuthenticated)
      fetchData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MBanner title="Favorite Location" signed={isAuthenticated}>
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <div className="featured-title">
        <h1>
          Favorite <span>Location</span>
        </h1>
        <h6>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempor
          non augue nec pulvinar.
        </h6>
        {!isEmpty(suggestions?.favoriteLocations) && (
          <Row xs={1} sm={2} xl={3}>
            {suggestions?.favoriteLocations?.map((info) => {
              if (
                !isEmpty(favoriteLocations) &&
                favoriteLocations?.includes(info.id)
              )
                return (
                  <Col className="my-2" key={info.id}>
                    <MCardView info={info} />
                  </Col>
                );
              return null;
            })}
          </Row>
        )}
      </div>
      {/* {isEmpty(suggestions) && <Dashboard />}
      {!isEmpty(suggestions?.viewedLocations) && (
        <h1>Recent Viewed Locations</h1>
      )}
      <Row xs={1} md={5}>
        {suggestions?.viewedLocations?.map((info) => (
          <Col className="my-2" key={info.id}>
            <MCardView info={info} />
          </Col>
        ))}
      </Row>

      {!isEmpty(suggestions?.recentSearches) && <h1>Recent Searches</h1>}
      <Row xs={1} md={5}>
        {suggestions?.recentSearches?.map((suggestion, idx) => (
          <Col className="my-2" key={"suggestion-" + idx}>
            <Card className="p-2 h-100">
              {Object.keys(suggestion.criteria).map((key) => (
                <div key={key}>
                  <p className="fs-5   m-0">{criteriaLabels[key]} : </p>
                  <p className="mb-1">
                    {Array.isArray(suggestion.criteria[key])
                      ? suggestion?.criteria[key]?.join(", ")
                      : suggestion?.criteria[key]}
                  </p>
                </div>
              ))}
              <p className="mt-auto">
                Last Search: {dateTimeFormat(suggestion.time)}
              </p>
              <Button
                className="w-100"
                onClick={() => handleSearch(suggestion)}
              >
                Search
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      {!isEmpty(suggestions?.favoriteLocations) && <h1>Favorite Locations</h1>}
      <Row xs={1} md={5}>
        {suggestions?.favoriteLocations?.map((info) => {
          if (
            !isEmpty(favoriteLocations) &&
            favoriteLocations?.includes(info.id)
          )
            return (
              <Col className="my-2" key={info.id}>
                <MCardView info={info} />
              </Col>
            );
          return null;
        })}
      </Row> */}
    </>
  );
};
