import React, { useState, useEffect } from "react";
import { MTable } from "../../design_system/MTable";
import { deleteLocation, listLocationSearch } from "../../utils/api";
import { isEmpty } from "../../utils/util";
import { MInput } from "../../design_system/MInput";
import { MModal } from "../../design_system/MModal";
import MPagination from "../../design_system/MPagination";
import MBanner from "../../design_system/MBanner";
import { Link } from "react-router-dom";
import { AuthState } from "../../context/AuthProvider";
import { Button, Form } from "react-bootstrap";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export const Location = () => {
  const [tableData, setTableData] = useState([]);
  const columnNames = isEmpty(tableData) ? [] : [
    "name",
    "address",
    "zip",
    "city",
    "actions",
    "confidenceLevel",
    "description",
    "barType",
    "specialtyType",
    "events",
    "games",
    "barMusic",
    "parking",
    "dogFriendly",
    "time2visit",
    "amenities",
    "ambiance",
    "close2Others",
    "sports",
    "typesOfSports",
    "dancing",
    "mixology",
    "drinkCost",
    "beerCost",
    "drinkSpecialties",
    "happyHour",
    "offersFood",
    "foodType",
    "foodCost",
    "reservable",
    "meal",
    "restaurantType",
  ];
  const [searchFormData, setSearchFormData] = useState({});
  const [sortFormData, setSortFormData] = useState([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(1);

  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const radioOptions = [
    { value: "all", label: "All" },
    { value: "fetch", label: "Not Fetched" },
    { value: "conflict", label: "Check Conflict" },
    { value: "photo", label: "No Photo" },
  ];
  const [radioChecked, setRadioChecked] = useState("all");

  function loadFood() {
    const abortController = new AbortController();
    listLocationSearch(
      {
        sort: sortFormData,
        search: searchFormData.search,
        page: currentPage,
        checked: radioChecked,
      },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.data);
        setTotalSize(data.total);
      })
      .catch("null");
  }

  useEffect(() => {
    loadFood();
    // eslint-disable-next-line
  }, [sortFormData, currentPage, radioChecked]);

  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      loadFood();
      event.target.blur();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (formData) => {
    const abortController = new AbortController();

    deleteLocation(formData, abortController.signal)
      .then(data => {
        const temp = tableData.filter((item) => item.id !== formData.id);
        setTableData(temp);
        setTotalSize(temp.length);
      })
      .catch("null");
  };

  return (
    <>
      <MBanner title="Location List" signed={isAuthenticated}>
        <Link className="link" to="/metrics">
          Admin Metrics
        </Link>
      </MBanner>
      <div className="m-4">
        <div className="mx-2 d-flex justify-content-between align-items-center">
          <h4 className="m-0">Location List</h4>
          <div className="d-flex justify-content-end align-items-center">
            {radioOptions.map((option, index) => (
              <Form.Check
                className="mx-2"
                type="radio"
                name={option.value}
                id={option.value}
                key={index}
                label={option.label}
                value={radioChecked === option.value}
                onChange={(e) => setRadioChecked(option.value)}
                checked={radioChecked === option.value}
              />
            ))}
            <div>
              <MInput
                attribute="search"
                formData={searchFormData}
                setFormData={setSearchFormData}
                editData=""
                isEdit={true}
                onKeyDown={handlePressEnter}
              />
            </div>
            <Link className="link" to="/add-location">
              <Button className="pl-2 ml-2">
                <AddOutlinedIcon />
                Add Location
              </Button>
            </Link>
          </div>
        </div>
        <MTable
          columnNames={columnNames}
          tableData={tableData}
          setSortFormData={setSortFormData}
          sortFormData={sortFormData}
          handleDelete={handleDelete}
        />
        <MPagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          total={totalSize}
          pageSize={50}
        />
      </div>
      {isAddOpen ? (
        <MModal modalId="createModal" setIsOpen={setIsAddOpen} />
      ) : null}
    </>
  );
};
