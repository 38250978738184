import React from "react";
import { isEmpty } from "../utils/util";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MDropdown = ({
  attribute,
  formData,
  setFormData,
  listItems,
  editData,
  isEdit,
  isMulti = false,
  label,
  shouldAlphabetize,
  ...inputProps
}) => {
  let value = isEmpty(formData[attribute])
    ? isMulti
      ? []
      : null
    : isMulti
    ? listItems.filter((item) => formData[attribute]?.includes(item.value))
    : listItems.find((item) => formData[attribute] === item.value);

  const setValue = (newValue) => {
    setFormData({
      ...formData,
      [attribute]: isMulti
        ? newValue.map((value) => value.value)
        : newValue?.value,
    });
  };

  return (
    <Autocomplete
      multiple={isMulti}
      options={listItems}
      disableCloseOnSelect={isMulti}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.text}
      onChange={(event, newValue) => setValue(newValue)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {isMulti && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option.text}
        </li>
      )}
      renderInput={(params) => <TextField {...params} {...inputProps} />}
    />
  );
};
