import React from "react";
import Rating from "@mui/material/Rating";

export const MStarRating = ({ rating, size = "md", readOnly = true, ...props }) => {
  return (
    <Rating
      name="half-rating-read"
      defaultValue={parseFloat(rating)}
      precision={0.5}
      readOnly={readOnly}
      sx={{
        gap: "0.19rem",
        "& .MuiSvgIcon-root": {
          width: `${size === "lg" ? "1.5rem" : "1.125rem"} !important`,
          padding: "0 !important",
          background: "transparent !important",
        },
        "& > span": {
          display: "flex",
          alignItems: "center",
        },
      }}
      {...props}
    />
  );
};
