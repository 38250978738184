import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { isEmpty, toUpperCamelCase } from "../utils/util";
import { TableWrapper } from "./styled-components";
import { Tooltip } from "@mui/material";

export default function MTableCmp({
  tableInfo,
  tableData,
  setSortFormData = () => { },
  sortFormData = [],
  selectActiveRow = (idx) => { }
}) {
  const [rowIsActive, setRowIsActive] = useState(null);

  const columnsHeader = tableInfo?.map((col, index) => {
    const idx = sortFormData?.findIndex((item) => !isEmpty(item[col?.key]));
    return (
      <th
        key={index}
        className="cursor-pointer"
        onClick={() => {
          if (!col.sortable) return;
          let temp = [...sortFormData];
          if (idx < 0) {
            temp.unshift({ [col.key]: true });
          } else {
            if (temp[idx][col.key] === true) {
              temp[idx][col.key] = false;
            } else {
              temp.splice(idx, 1);
            }
          }
          setSortFormData(temp);
        }}
      >
        <span className="d-flex justify-content-between">
          {col.label || toUpperCamelCase(col.key)}
          {idx < 0 ? (
            ""
          ) : sortFormData[idx][col.key] ? (
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M17 11l-5-5-5 5" />
              <path d="M17 18l-5-5-5 5" />
            </svg>
          ) : (
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M7 13l5 5 5-5" />
              <path d="M7 6l5 5 5-5" />
            </svg>
          )}
        </span>
      </th>
    );
  });

  const toggleActive = (index) => {
    setRowIsActive(index);
    selectActiveRow(index);
  };
  const rows = tableData?.map((rows, idx) => {
    return (
      <tr
        className={rowIsActive === idx ? "table-primary" : ""}
        onClick={() => toggleActive(idx)}
        key={"row" + idx}
      >
        {tableInfo.map((col, index) => (
          <td key={"col" + index}>
            {
              isEmpty(col.render) ?
                typeof rows[col.key] === "string" && rows[col.key].length > 24 ?
                  <Tooltip title={rows[col.key]}><span>{rows[col.key]}</span></Tooltip> :
                  rows[col.key] :
                col.render(rows[col.key], rows, idx)
            }
          </td>
        ))}
      </tr>
    );
  });

  return (
    <>
      <div className="mt-4 position-relative">
        <TableWrapper>
          <div>
            <Table className="table table-hover table-striped">
              <thead>
                <tr>{columnsHeader}</tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};
