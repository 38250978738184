import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { ReviewModalWrapper } from "./styled-components";
import MCardView from "./MCardView";
import styled from "styled-components";
import { AuthState } from "../context/AuthProvider";
import ReviewDetail from "../layout/Location/ReviewDetail";
import PersonalDetail from "../layout/Location/PersonalReview";

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const types = ["Overall Rating", "Suggestion Rating"];

export const MReviewModal = ({
  modalId,
  filterData,
  suggestionRating,
  loading,
  setSuggestionRating,
  setFilterData,
}) => {
  const handleClose = () => {
    setAuth((prev) => {
      return { ...prev, intake: false };
    });
  };

  const [active, setActive] = useState(types[0]);
  const { auth, setAuth } = AuthState();

  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [data, setData] = useState([]);
  const [locationIndex, setIndex] = useState(-1);
  const [textState, setTextState] = useState(true);

  useEffect(() => {
    if (selected.length !== 0) {
      setTextState(false);
    } else {
      setTextState(true);
    }
  }, [selected.length]);
  const handleReviewModal = () => {
    setIndex(0);
    setModalState(true);
  };

  const handleMinusIndex = () => {
    if (locationIndex === 0) {
      setIndex(0);
    } else {
      let curIndex = locationIndex;
      setIndex(curIndex - 1);
    }
  };

  const handlePlusIndex = () => {
    if (locationIndex === selected.length - 1) {
      setIndex(selected.length - 1);
    } else {
      let curIndex = locationIndex;
      setIndex(curIndex + 1);
    }
  };

  useEffect(() => {
    setData(
      filterData &&
        filterData.filter((item) => item.id === selected[locationIndex])
    );

    // eslint-disable-next-line
  }, [locationIndex]);

  const handleCardClick = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const renderRatingModel = () => {
    return (
      <div style={{ paddingTop: "30px" }}>
        <ButtonGroup>
          {types.map((type) => (
            <Tab
              key={type}
              active={active === type}
              onClick={() => setActive(type)}
            >
              {type}
            </Tab>
          ))}
        </ButtonGroup>
        <div style={{ overflowY: "auto", overflowX: "clip", height: "60vh" }}>
          {active === "Overall Rating" ? (
            <>
              {data[0] && (
                <ReviewDetail setFilterData={setFilterData} data={data[0]} />
              )}
            </>
          ) : (
            <PersonalDetail
              setRatings={setSuggestionRating}
              ratings={suggestionRating}
            />
          )}
        </div>
      </div>
    );
  };

  const renderOnlySuggestionModel = () => {
    return (
      <div
        style={{
          paddingTop: "30px",
        }}
      >
        <ButtonGroup>
          <Tab active>Suggestion Rating</Tab>
        </ButtonGroup>
        <div
          style={{
            overflowY: "auto",
            overflowX: "clip",
            height: "60vh",
          }}
        >
          <PersonalDetail
            setRatings={setSuggestionRating}
            ratings={suggestionRating}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      style={{ paddingLeft: 20 }}
      show={auth?.intake && !auth.isNew && filterData}
      onHide={() => handleClose()}
      backdrop={true}
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      size="lg"
    >
      <ReviewModalWrapper>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {!modalState ? (
                <div>
                  <div
                    className="modal-header justify-content-center"
                    style={{ display: "block" }}
                  >
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Which locations did you visit?
                    </h5>
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "clip",
                      height: "60vh",
                    }}
                  >
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        <Row xs={1} sm={2} xl={2}>
                          {filterData?.length > 0 &&
                            filterData.map((info) => {
                              return (
                                <Col
                                  className="my-2 px-4"
                                  key={info?.id}
                                  onClick={() => handleCardClick(info.id)}
                                >
                                  <MCardView
                                    info={info}
                                    withDesc
                                    withRate
                                    isShowReviewModal
                                    isSelected={selected.includes(info.id)}
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              ) : textState ? (
                renderOnlySuggestionModel()
              ) : (
                renderRatingModel()
              )}
            </div>
            <div className="modal-footer py-3 px-4 mx-1">
              {!modalState ? (
                <>
                  <Button
                    size="lg"
                    data-dismiss="modal"
                    variant="primary"
                    onClick={handleReviewModal}
                  >
                    {textState
                      ? "I didn't visit any of these locations"
                      : "Review Locations"}
                  </Button>
                  <Button
                    size="lg"
                    data-dismiss="modal"
                    variant="outline-dark"
                    onClick={handleClose}
                  >
                    Not now
                  </Button>
                </>
              ) : (
                <div className="d-flex align-items-center">
                  {active === "Overall Rating" && selected.length > 1 ? (
                    <Row className="align-items-center d-flex flex-nowrap">
                      <Col className="text-center" style={{ fontSize: "20px" }}>
                        {locationIndex + 1}/{selected.length}
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          size="lg"
                          data-dismiss="modal"
                          variant="outline-dark"
                          onClick={handleMinusIndex}
                        >
                          Prev
                        </Button>
                        <Button
                          size="lg"
                          data-dismiss="modal"
                          variant="primary"
                          onClick={handlePlusIndex}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Button
                    size="lg"
                    data-dismiss="modal"
                    variant="outline-dark"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ReviewModalWrapper>
    </Modal>
  );
};
