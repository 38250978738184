import React from "react";
import { isEmpty } from "../utils/util";
import { Button } from "react-bootstrap";

export default function MButtonGroup({
  listItems,
  formData,
  setFormData,
  attribute,
  prefix,
  isEdit,
  isMultiSelect,
  activeVariant = "primary",
  normalVariant = "outline-primary",
  endIcon = null,
  withAttribute = false,
  ...props
}) {
  const currentData = isMultiSelect
    ? formData[attribute]
    : isEmpty(formData[attribute])
    ? []
    : [formData[attribute]];

  const getPrefix = (index) => {
    if (isEmpty(prefix)) {
      return;
    } else if (prefix === "letter") {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return index > 25 ? "-" : alphabet[index] + ".";
    } else return prefix;
  };

  const handleChange = (item, included) => {
    let newValue;
    if (isMultiSelect) {
      if (included) {
        newValue = formData[attribute].filter((data) => data !== item);
      } else {
        newValue = isEmpty(formData[attribute])
          ? [item]
          : [...formData[attribute], item];
      }
    } else {
      if (included) {
        newValue = null;
      } else {
        newValue = item;
      }
    }

    setFormData({ ...formData, [attribute]: newValue });
  };

  let buttons = [];
  if (!isEmpty(listItems)) {
    buttons = listItems.reduce((accumulator, item, index) => {
      const included =
        currentData?.includes(item) || currentData?.includes(item.value);

      if (isEdit || included) {
        accumulator.push(
          <Button
            key={index}
            variant={included ? activeVariant : normalVariant}
            onClick={() => handleChange(item?.value || item, included)}
            {...props}
          >
            {withAttribute ? attribute + ": " : ""}
            {getPrefix(index)} {item?.text || item}
            <span className="ml-2">{endIcon}</span>
          </Button>
        );
      }
      return accumulator;
    }, []);
  }

  return (
    <>
      {!isEmpty(buttons) && (
        <div className="d-flex flex-wrap gap-2">{buttons}</div>
      )}
    </>
  );
}
