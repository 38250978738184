import React from "react";
import { isEmpty } from "../../../utils/util";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

const filter = createFilterOptions();


export const MCreatableDropdown = ({
  attribute,
  formData,
  setFormData,
  listItems,
  ...inputProps
}) => {

  const setValue = (newValue) => {
    setFormData({ ...formData, [attribute]: newValue?.value });
  }

  return (
    <Autocomplete
      options={listItems}
      value={isEmpty(formData[attribute]) ? null : { value: formData[attribute], text: formData[attribute] }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.text}
      freeSolo
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({ value: newValue, text: newValue });
        } else if (newValue && newValue.inputValue) {
          setValue({ value: newValue.inputValue, text: newValue });
        } else {
          setValue(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} {...inputProps} />
      )}
      renderOption={(props, option) => <li {...props}>{option.text}</li>}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.text);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            text: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
    />
  );
};
