import React, { useState } from "react";
import { AuthState } from "../../context/AuthProvider";
import { Link } from "react-router-dom";
import MBanner from "../../design_system/MBanner";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export const Help = () => {
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [expanded, setExpanded] = useState([]);

  const faqs = [
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum, lorem sed cursus feugiat, leo dolor feugiat magna, sit amet cursus ex tellus vel massa. Nunc at metus vitae odio laoreet posuere. Quisque iaculis neque eu vestibulum pretium",
    },
  ];

  const handleChange = (index) => {
    let newExpanded = expanded;
    if (expanded?.includes(index)) {
      newExpanded = expanded.filter((item) => item !== index);
    } else {
      newExpanded = [...expanded, index];
    }
    setExpanded(newExpanded);
  };

  return (
    <>
      <MBanner title="Help" signed={isAuthenticated}>
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <div className="featured-title">
        <h1>
          Frequently Asked <span>Questions</span>
        </h1>
        <h6>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempor
          non augue nec pulvinar.
        </h6>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            onChange={() => handleChange(index)}
            sx={{
              boxShadow: "2px 2px 14px 0px rgba(0, 0, 0, 0.10)",
              borderRadius: "0.5rem !important",
              marginBottom: "1rem",
              padding: "0.5rem !important",
              ":before": { backgroundColor: "transparent !important" },
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded.includes(index) ? (
                  <RemoveCircleOutlineIcon sx={{ color: "#1da2f2" }} />
                ) : (
                  <AddCircleOutlineIcon sx={{ color: "#1da2f2" }} />
                )
              }
            >
              <Typography
                sx={{
                  color: "#1C1C1F",
                  fontSize: "1.125rem",
                  fontWeight: "500",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              <Typography
                sx={{
                  color: "#5B5B63",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                }}
              >
                {faq.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};
