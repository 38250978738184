import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Button } from "react-bootstrap";
import { SignOutIcon } from "../utils/icons";
import { AuthState } from "../context/AuthProvider";
import { IconButton } from "@mui/material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

export default function MHeaderMobile({ handleSignOut, navList }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsMenuOpen(open);
  };

  const ItemButtonStyle = {
    px: 0,
    mx: isAuthenticated ? "1rem" : "1.5rem",
    my: "0.25rem",
    maxWidth: "fit-content",
    transition: "0.1s",
    a: {
      color: "white !important",
      border: "none !important",
    },
    li: {
      color: "white !important",
      border: "none !important",
    },
    svg: {
      color: "white !important",
    },
    ":hover": {
      backgroundColor: "#0978BD",
      borderRadius: "0.25rem",
      padding: "0.5rem 0.5rem",
      marginRight: "2rem",
    },
  };

  return (
    <React.Fragment>
      <IconButton
        sx={{
          backgroundColor: "#1da1f2 !important",
          margin: "0.5rem",
          width: "2rem",
          height: "2rem",
        }}
        onClick={toggleDrawer(true)}
      >
        <KeyboardDoubleArrowRight sx={{ color: "white" }} />
      </IconButton>
      <Drawer
        className="mobile-drawer"
        anchor={"left"}
        open={isMenuOpen}
        onClose={toggleDrawer(false)}
        sx={{
          zIndex: 10000,
          ".MuiPaper-root": {
            width: "15rem",
            borderRadius: "0 1.25rem 1.25rem 0",
          },
        }}
      >
        <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {isAuthenticated && (
              <div className="d-flex align-items-center justify-content-center">
                <img
                  className="logo"
                  alt="Zappt"
                  src={require("../images/zappt-logo.png")}
                />
              </div>
            )}
            {!isAuthenticated && (
              <>
                <ListItem className="py-0">
                  <Link to="/signIn">
                    <Button size="lg" className="w-6">
                      Sign In
                    </Button>
                  </Link>
                </ListItem>
                <ListItem className="py-0">
                  <Link to="/signUp">
                    <Button size="lg" variant="outline-dark" className="w-6">
                      Sign Up
                    </Button>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
          <Divider
            className="mt-2"
            sx={{ borderColor: "#1DA1F2", opacity: 1 }}
          />
          <List
            className="nav py-4"
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#1DA1F2",
            }}
          >
            {navList.map((item) => (
              <Link key={item.url} to={item.url} className="mx-1 my-0">
                <ListItemButton sx={ItemButtonStyle}>
                  {isAuthenticated && (
                    <ListItemIcon
                      sx={{ minWidth: 0, mr: "0.5rem" }}
                      className="p-0"
                    >
                      {item.icon}
                    </ListItemIcon>
                  )}
                  <ListItem className="p-0">{item.text}</ListItem>
                </ListItemButton>
              </Link>
            ))}
            {isAuthenticated && (
              <ListItemButton sx={ItemButtonStyle}>
                <ListItemIcon
                  sx={{ minWidth: 0, ml: "0.5rem", mr: "0.25rem" }}
                  className="p-0"
                >
                  <SignOutIcon />
                </ListItemIcon>
                <ListItem className="p-0 mx-1" onClick={handleSignOut}>
                  Log out
                </ListItem>
              </ListItemButton>
            )}
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
