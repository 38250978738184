import React, { useState } from "react";
import { AuthState } from "../../../context/AuthProvider";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setClaimLocation } from "../../../utils/api";
import MBanner from "../../../design_system/MBanner";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MLocationSuggest from "../../../design_system/MLocationSuggest";
import notify from "../../../utils/notify";
import { isEmpty } from "../../../utils/util";
import { MTable } from "../../../design_system/MTable";
import { criteriaLabels } from "../../Location/DrowndownList";

export const ClaimLocation = () => {
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};
  const [formData, setFormData] = useState({});
  const [curSuggestion, setCurSuggestion] = useState(null);
  const columnNames = isEmpty(formData.claimList) ? [] : [
    "name",
    "address",
    "status",
    "claim_actions",
  ];

  const addToClaimList = (newSuggestion) => {
    setCurSuggestion(newSuggestion);

    if (isEmpty(newSuggestion)) return;
    if (isEmpty(formData.claimList)) {
      setFormData({ ...formData, claimList: [newSuggestion] });
    } else if (!formData.claimList.some(item => item?.id === newSuggestion?.id)) {
      setFormData({ ...formData, claimList: [...formData.claimList, newSuggestion] });
    } else {
      notify("Already added to the claim list!", "warning");
    }
  };

  const handleSubmitClaimLocation = () => {
    const abortController = new AbortController();
    setClaimLocation(formData, abortController.signal)
      .then((data) => {
        if (!isEmpty(data?.message)) {
          notify(data?.message, "success");
        }
      })
      .catch("null");
  }

  const handleRemoveLocationFromList = (location) => {
    let newLocationList = formData.claimList.filter(item => item.id !== location.id);
    setFormData({ ...formData, claimList: newLocationList });
  }

  return (
    <>
      <MBanner title="Claim Location" signed={isAuthenticated}>
        <Link className="link" to="/">Home</Link>
      </MBanner>
      <div className="featured-title">
        <h1>Claim <span>Location</span></h1>

        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempor non augue nec pulvinar.</h6>

        <Row xs={1} sm={2} className="w-100 m-0 p-4 justify-content-center ">
          <Col xs={11} sm={9} xl={4} className="mb-3 p-1">
            <div className="mCard">
              <h4>Contact Information</h4>
              <TextField
                type="text"
                name="phone"
                tabIndex="1"
                className="d-block w-100"
                placeholder="Phone Number"
                variant="outlined"
                sx={{
                  "& .MuiInputBase-root": { width: "100%", padding: 0 },
                  input: { padding: "0.6rem" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EditOutlinedIcon sx={{ width: "1.15rem" }} />
                    </InputAdornment>
                  ),
                }}
                value={formData?.phone || ""}
                onChange={e => setFormData({ ...formData, phone: e.target.value })}
              />
              <TextField
                type="text"
                multiline
                rows={4}
                name="description"
                tabIndex="1"
                className="d-block w-100 mt-4"
                placeholder="Description..."
                variant="outlined"
                sx={{
                  "& .MuiInputBase-root": { width: "100%" },
                }}
                value={formData?.description || ""}
                onChange={e => setFormData({ ...formData, description: e.target.value })}
              />
            </div>
          </Col>
          <Col xs={11} sm={11} xl={8} className="pl-md-5">
            <div className="mCard">
              <MLocationSuggest value={curSuggestion} onChange={addToClaimList} />
              <MTable
                columnNames={columnNames}
                columnLabels={{ ...criteriaLabels, claim_actions: "Actions" }}
                tableData={formData?.claimList || []}
                handleDelete={handleRemoveLocationFromList}
              />
              <Button
                size="lg"
                className="m-0 my-3 w-100"
                onClick={handleSubmitClaimLocation}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
