export const isEmpty = (value) =>
  value === undefined ||
  value === "undefined" ||
  value === null ||
  value === "null" ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const dateFormat = (value) => {
  const date = new Date(value);
  const formattedDate =
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "-" +
    date.getFullYear();
  return formattedDate;
};

export const dateTimeFormat = (value) => {
  const date = new Date(value);
  const formattedDateTime =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2);
  return formattedDateTime;
};

export const toCamelCase = (inputString) => {
  return inputString.replace(/[-_]+([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

export const toUpperCamelCase = (inputString) => {
  return inputString
    .replace(/^([a-z])/, (match, letter) => letter.toUpperCase())
    .replace(/[-_]+([a-z])/g, (match, letter) => ' ' + letter.toUpperCase());
};

export const dateFormat1 = (value) => {
  if (isEmpty(value)) return "";
  let date = value.split(" ")[0];
  if (date.length < 10) {
    date = new Date(date);
    date =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear();
  }
  return date;
};

export const numberFormat = (value) => {
  return isEmpty(value) || isNaN(value) ? "" : parseFloat(value).toFixed(1).replace(/\.0$/, '').toString();
};
