import React, { useState, useEffect } from "react";
import { ModalWrapper } from "./styled-components";
import { Button, Modal } from "react-bootstrap";
import SyncIcon from "@mui/icons-material/Sync";

export const MAnalysisConfirmModal = ({
  tableData,
  rowId,
  setIsOpen,
  isOpen = false,
  handleAnalyze
}) => {
  const [formData, setFormData] = useState(tableData[rowId]);

  useEffect(
    () => {
      setFormData(tableData[rowId]);
    },
    [rowId, tableData]
  );

  const analyzeHandler = () => {
    handleAnalyze(formData);
    setIsOpen(false);
  }

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      className="modal fade"
      id="deleteModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <ModalWrapper>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center flex-column border-0">
              <SyncIcon
                sx={{ color: "#1da2f2", fontSize: "4rem", margin: "auto" }}
              />
            </div>
            <div className="modal-body text-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Analyze Entry
              </h5>
              Are you sure analyze this location again?
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between w-100 gap-2">
                <Button
                  size="lg"
                  variant="outline-primary"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="lg"
                  variant="danger"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={analyzeHandler}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
};
