import React from "react";
import { FooterWrapper } from "./styled-components";

export default function MFooter() {
  const curYear = new Date().getFullYear();
  return (
    <FooterWrapper as="footer">
      © {curYear} Zappt. All rights reserved.
    </FooterWrapper>
  );
}
