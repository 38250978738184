import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { updateUserProfile } from "../utils/api";
import { AuthState } from "../context/AuthProvider";
import { MDropdown } from "./MDropdown";
import { isEmpty } from "../utils/util";
import { MImageUpload } from "./MImageUpload";
import TextField from "@mui/material/TextField";
import notify from "../utils/notify";

export default function InTakeModal({ show }) {
  const [curStep, setCurStep] = useState(0);
  const [formData, setFormData] = useState({});
  const { auth, setAuth } = AuthState();
  const curYear = new Date().getFullYear();

  useEffect(() => {
    if (auth?.isNew) {
      notify(
        "To help improve accuracy of searches you can update this info at any time on your profile page.",
        "success"
      );
    }
    setCurStep(0);
    setFormData(auth);
  }, [show, auth]);

  useEffect(() => {
    setFormData({
      ...formData,
      age: (curYear - formData.birthdayYear).toString(),
    });
    // eslint-disable-next-line
  }, [formData.birthdayYear]);

  const steps = [
    {
      title: "What type of Bars Do You Enjoy?",
      attribute: "barType",
      options: [
        "College kids(party)",
        "Mature/upscale(classy)",
        "Sports bar",
        "Dive",
        "Clubs",
        "Lounge",
      ],
      type: "checkbox",
    },
    {
      title: "What Experiences Are You Looking For?",
      attribute: "experienceType",
      options: ["Events", "Restaurants", "Bars"],
      type: "checkbox",
    },
    {
      title: "How old are you?",
      attribute: "age",
      label: "Age",
      type: "number",
    },
    {
      title: "What Gender do you Identify as?",
      attribute: "gender",
      options: ["Male", "Female", "None-binary", "Prefer not to say"],
      type: "radio",
    },
    // {
    //   title: "What Ethnicity are you?",
    //   attribute: "identify",
    //   options: [
    //     "American Indian or Alaskan Native",
    //     "Asian / Pacific Islander",
    //     "Black or African American",
    //     "Hispanic",
    //     "White / Caucasian",
    //     "Other",
    //     "Prefer not to say",
    //   ],
    //   type: "radio",
    // },

    {
      title: "What brings you to Zappt?",
      attribute: "reason",
      options: [
        "New date ideas",
        "Explore new spots",
        "Looking for something in particular",
        "Just here for fun",
        "Prefer not to say",
      ],
      type: "checkbox",
    },
    // {
    //   title: "Ideal night out?",
    //   attribute: "ideal",
    //   options: [
    //     "Craft cocktails",
    //     "Good beer",
    //     "Swanky vibe",
    //     "High energy",
    //     "Cozy spot",
    //     "Snacks on hand",
    //     "Lots around to walk to",
    //     "Meeting new people",
    //     "None of these",
    //   ],
    //   type: "checkbox",
    // },

    // {
    //   title: "What Do You Like to Drink?",
    //   attribute: "drinkType",
    //   options: ["Mixed drinks", "Beer", "Seltzer", "Wine", "Mixology drinks"],
    //   type: "checkbox",
    // },
    // {
    //   title: "What is your Typical Weekend Night Spend?",
    //   subQuestion: [
    //     {
    //       title: "On a typical weekend night out how much do you spend?",
    //       attribute: "currentSpend",
    //       options: ["$10-25", "$26-50", "$50-100", "$100-200", "$200+"],
    //       type: "radio",
    //     },
    //     {
    //       title: "Are you happy about this?",
    //       attribute: "statusSpend",
    //       options: ["Yes", "No"],
    //       type: "radio",
    //     },
    //     {
    //       title:
    //         "On a typical weekend night out how much would you like to spend?",
    //       attribute: "idealSpend",
    //       options: ["$10-25", "$26-50", "$50-100", "$100-200", "$200+"],
    //       type: "radio",
    //     },
    //   ],
    //   type: "group",
    // },
    // {
    //   title: "Profile Picture",
    //   attribute: "avatar",
    //   type: "image",
    // },
  ];

  const handleClose = () => {
    const abortController = new AbortController();
    updateUserProfile({ formData: { isNew: false } }, abortController.signal)
      .then(() => {
        setAuth({ ...auth, isNew: false });
      })
      .catch("null");
    notify(
      "To get better recommendations, you can fill this out at anytime on your profile page.",
      "warn"
    );
  };

  const RadioGroup = ({ title, attribute, options, formData, setFormData }) => (
    <>
      <p>{title} </p>
      {options.map((option, index) => (
        <Form.Check
          type="radio"
          name={attribute}
          id={option + "_" + attribute + "_" + index}
          key={option + "_" + attribute + "_" + index}
          label={option}
          value={option}
          onChange={(e) =>
            setFormData({ ...formData, [attribute]: e.target.value })
          }
          checked={option === formData[attribute]}
        />
      ))}
    </>
  );

  const CheckBoxGroup = ({
    title,
    attribute,
    options,
    formData,
    setFormData,
  }) => (
    <>
      <p>{title} </p>
      {options.map((option, index) => {
        const isIncluded = formData[attribute]?.includes(option);
        return (
          <Form.Check
            type="checkbox"
            name={attribute}
            id={option + "_" + attribute + "_" + index}
            key={option + "_" + attribute + "_" + index}
            label={option}
            value={option}
            onChange={(e) =>
              setFormData((prevData) => {
                const updatedData = prevData[attribute]
                  ? [...prevData[attribute]]
                  : [];
                if (!isIncluded) {
                  updatedData.push(e.target.value);
                } else {
                  const idx = updatedData.indexOf(e.target.value);
                  updatedData.splice(idx, 1);
                }
                return { ...prevData, [attribute]: updatedData };
              })
            }
            checked={isIncluded}
          />
        );
      })}
    </>
  );

  const handleSubmitChange = () => {
    const abortController = new AbortController();
    updateUserProfile(
      { formData: { ...formData, isNew: false } },
      abortController.signal
    )
      .then((data) => {
        setAuth({ ...auth, ...formData, ...data, isNew: false });
      })
      .catch("null");
  };

  const renderQuestionCmp = (question) => {
    if (question.type === "radio") {
      return (
        <RadioGroup
          attribute={question.attribute}
          options={question.options}
          formData={formData}
          setFormData={setFormData}
          title={question.title}
        />
      );
    } else if (question.type === "checkbox") {
      return (
        <CheckBoxGroup
          attribute={question.attribute}
          formData={formData}
          setFormData={setFormData}
          options={question.options}
          title={question.title}
        />
      );
    } else if (question.type === "number") {
      return (
        <>
          {!isEmpty(question.title) && (
            <h5 className="mt-2">- {question.title}</h5>
          )}
          <TextField
            type="number"
            min={1}
            max={300}
            className="d-block w-100"
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { width: "100%" } }}
            value={formData[question.attribute] || ""}
            onChange={(e) => setFormData({ ...formData, [question.attribute]: Math.max(1, Math.min(e.target.value, 300)) })}
          />
        </>
      );
    } else if (question.type === "dropdown") {
      return (
        <MDropdown
          className="dropdown"
          formData={formData}
          setFormData={setFormData}
          listItems={question.options}
          attribute={question.attribute}
          label={question.label}
          editData={formData[question.attribute] || []}
        // isEdit={isEdit}
        />
      );
    } else if (question.type === "image") {
      return (
        <MImageUpload
          formData={formData}
          setFormData={setFormData}
          attribute={question.attribute}
        />
      );
    } else if (question.type === "group") {
      return question.subQuestion.map((subQuestion, index) => (
        <div
          key={"subQuestion" + index}
          className={isEmpty(subQuestion.title) ? "d-inline-block mr-2" : ""}
        >
          {!isEmpty(subQuestion.title) && (
            <h5 className="mt-2">- {subQuestion.title}</h5>
          )}
          {renderQuestionCmp(subQuestion)}
        </div>
      ));
    }
    return null;
  };

  return (
    <Modal show={auth?.isNew} onHide={handleClose} backdrop="static" centered>
      <div className="modal-content">
        <div className="modal-body">
          <div
            className="modal-header justify-content-center"
            style={{ display: "block", width: "50vw" }}
          >
            <h5 className="modal-title text-center py-4" id="exampleModalLabel">
              Questionare
            </h5>
            <div
              style={{
                height: "30vh",
                overflow: "auto",
              }}
            >
              {renderQuestionCmp(steps[curStep])}
            </div>
          </div>
          <div className="modal-footer py-2 px-4 mx-1">
            <Button
              size="lg"
              variant="secondary"
              onClick={() => setCurStep(curStep - 1)}
              disabled={curStep === 0}
            >
              Prev
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => setCurStep(curStep + 1)}
              disabled={curStep === steps.length - 1}
            >
              Next
            </Button>
            {curStep === steps.length - 1 ? (
              <Button size="lg" variant="primary" onClick={handleSubmitChange}>
                Done
              </Button>
            ) : (
              <Button size="lg" variant="primary" onClick={handleClose}>
                Not Now
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
