import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";

import { AuthState } from "../../context/AuthProvider";
import notify from "../../utils/notify";
import { googleSign, signUp } from "../../utils/api";
import GoogleLoginCmp from "../SignIn/GoogleLoginCmp";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MBanner from "../../design_system/MBanner";
import { FormWrapper } from "../../design_system/styled-components";
import { isEmpty } from "../../utils/util";

const SignUp = () => {
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setAuth } = AuthState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCredentials = (e) => {
    if (isEmpty(credentials.id)) {
      const myUUID = uuidv4();
      setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
        id: myUUID,
      });
    } else {
      setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
      });
    }
  };

  const registerHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (
      !credentials.firstName ||
      !credentials.lastName ||
      !credentials.email ||
      !credentials.password ||
      !credentials.confirmPassword
    ) {
      setIsLoading(false);
      return notify("Please Fill all the Feilds", "warn");
    }

    // If password and confirm password doesn't match
    if (credentials.password !== credentials.confirmPassword) {
      setIsLoading(false);
      return notify("Passwords Do Not Match", "warn");
    }

    // If password is less than 8 characters
    if (credentials.password.length < 8) {
      setIsLoading(false);
      return notify("Password must be at least 8 characters", "warn");
    }

    try {
      // Register user
      const data = await signUp(credentials);
      handleSignUpSuccess(data);
    } catch (error) {
      setIsLoading(false);
      return notify("Internal server error", "error");
    }
  };

  const handleGoogleSignUp = async (credentials) => {
    try {
      const data = await googleSign(credentials);
      handleSignUpSuccess(data);
    } catch (error) {
      setIsLoading(false);
      return notify("Internal server error", "error");
    }
  };

  const handleSignUpSuccess = (data) => {
    if (data.success) {
      setAuth(data);
      setIsLoading(false);
      navigate(-1); // Go to page user was previously on
      return notify("Your account has been successfully created", "success");
    } else {
      setIsLoading(false);
      return notify(data.error, "error");
    }
  };

  return (
    <>
      <MBanner title="Sign Up">
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <FormWrapper>
        <Form className="w-sm-50" onSubmit={registerHandler}>
          <h3 className="m-0">Sign Up</h3>
          <p className="mb-4">
            Please enter your credentials to access your account.
          </p>

          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <TextField
              type="text"
              name="firstName"
              tabIndex="1"
              className="d-block w-100"
              placeholder="Enter first name"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { width: "100%" } }}
              value={credentials.firstName || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <TextField
              type="text"
              name="lastName"
              tabIndex="2"
              className="d-block w-100"
              placeholder="Enter last name"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { width: "100%" } }}
              value={credentials.lastName || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <TextField
              type="email"
              name="email"
              tabIndex="3"
              className="d-block w-100"
              placeholder="Enter email"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
              }}
              value={credentials.email || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              tabIndex="3"
              className="d-block w-100"
              placeholder="Enter password"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={credentials.password || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <TextField
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              tabIndex="4"
              className="d-block w-100"
              placeholder="Confirm password"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={credentials.confirmPassword || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Button
            tabIndex="6"
            size="lg"
            type="submit"
            className="m-0 mb-3 w-100"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Create Account"
            )}
          </Button>

          <Divider
            className="mb-3"
            sx={{ fontSize: "0.875rem", color: "#97979E" }}
          >
            Or
          </Divider>

          <GoogleLoginCmp handleGoogleLogin={handleGoogleSignUp} />

          <Form.Group className="mt-5 text-center" controlId="signIn">
            <span className="other">
              Already have an account?&nbsp;
              <Link to="/signIn" tabIndex="7" className="text-decoration-none">
                Sign In
              </Link>
            </span>
          </Form.Group>
        </Form>
      </FormWrapper>
    </>
  );
};

export default SignUp;
