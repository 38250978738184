import React, { useState, useEffect } from "react";
import { isEmpty } from "../../utils/util";
import MBanner from "../../design_system/MBanner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthState, DataState } from "../../context/AuthProvider";
import { Button, Col, Form, Row } from "react-bootstrap";
import { createLocation, updateLocation } from "../../utils/api";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { MAdminButtonGroup } from "../../design_system/MAdminButtonGroup";
import notify from "../../utils/notify";

export const AddLocation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const { editLocation } = DataState();
  const isAdd = isEmpty(id) || id !== editLocation.id;

  useEffect(() => {
    const uuid = uuidv4();
    const tempFormData = isAdd ? { id: uuid } : editLocation;
    setFormData(tempFormData);

    // eslint-disable-next-line
  }, [id]);

  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const handleChangeByName = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const abortController = new AbortController();

    if (isAdd) {
      createLocation(formData, abortController.signal)
        .then((data) => {
          notify("Successfully added", "success");
        })
        .catch("null");
    } else {
      updateLocation(formData, abortController.signal)
        .then((data) => {
          notify("Successfully Updated", "success");
          // navigate(0);
        })
        .catch("null");
    }
  };

  return (
    <>
      <MBanner
        title={`${isAdd ? "Add" : "Edit"} Location`}
        signed={isAuthenticated}
      >
        <Link className="link" to="/metrics">
          Admin Metrics
        </Link>
      </MBanner>
      <div className="">
        <Form className="w-sm-50 m-4 mCard p-0" onSubmit={submitHandler}>
          <Row className="p-4 pb-0">
            <Col sm={6} lg={4}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Location Name</Form.Label>
                <TextField
                  type="text"
                  name="name"
                  tabIndex="1"
                  className="d-block w-100"
                  placeholder="Enter Location Name"
                  variant="outlined"
                  sx={{ "& .MuiInputBase-root": { width: "100%" } }}
                  value={formData.name || ""}
                  onChange={(e) => handleChangeByName(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} lg={4}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Location Address</Form.Label>
                <TextField
                  type="text"
                  name="address"
                  tabIndex="2"
                  className="d-block w-100"
                  placeholder="Enter Location Address"
                  variant="outlined"
                  sx={{ "& .MuiInputBase-root": { width: "100%" } }}
                  value={formData.address || ""}
                  onChange={(e) => handleChangeByName(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Confidence Level</Form.Label>
                <div
                  className="d-flex align-items-center m-auto"
                  style={{ height: "3.4375em" }}
                >
                  <Rating
                    name="half-rating-read"
                    value={parseInt(formData.confidenceLevel)}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        confidenceLevel: e.target.value,
                      })
                    }
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3 p-4 pt-0" controlId="firstName">
            <Form.Label>Description</Form.Label>
            <TextField
              type="text"
              name="description"
              tabIndex="3"
              multiline
              rows={4}
              className="d-block w-100"
              placeholder="Type description..."
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { width: "100%" } }}
              value={formData.description || ""}
              onChange={(e) => handleChangeByName(e)}
            />
          </Form.Group>
          <div className="p-1">
            <MAdminButtonGroup formData={formData} setFormData={setFormData} />
          </div>
          <div className="mb-1 bg-gray p-2 d-flex justify-content-end">
            <Button
              size="lg"
              variant="outline-secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button size="lg" variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
