import React, { useEffect, useState } from "react";
import { DataState } from "../../context/AuthProvider";
import {
  GoogleMap as GoogleMapReact,
  useJsApiLoader,
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../utils/api";
import MMarker from "../../design_system/MMarker";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import MMapControl from "./MMapControl";
import styled from "styled-components";
import { isEmpty } from "../../utils/util";

export const GoogleMap = () => {
  const { tableData, mostFavoriteLocations, mapFormData } = DataState();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });
  const [mapRef, setMapRef] = useState(null);

  const [curOpened, setCurOpened] = React.useState(null);
  const [isShowAll, setIsShowAll] = React.useState(true);
  const [isShowFavorite, setIsShowFavorite] = React.useState(true);

  const onLoad = React.useCallback(function callback(map) {
    setMapRef(map);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mapRef || !tableData || tableData.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    const radius = mapFormData.distance || 300;

    mapFormData.markers.forEach(marker => {
      const position = new window.google.maps.LatLng(
        marker.geometry.location.lat,
        marker.geometry.location.lng
      );

      const circle = new window.google.maps.Circle({
        center: position,
        radius: radius * 0.3048,
        visible: false
      });

      bounds.union(circle.getBounds());
    });

    mapRef.fitBounds(bounds);

    if (mapFormData.markers.length === 1) {
      mapRef.setZoom(13);
    }

    // eslint-disable-next-line
  }, [mapRef, tableData]);

  const dataLength = isShowAll ? tableData.length : 5;
  const hideFavoriteInfoWindow =
    tableData.findIndex((item) => item.id === curOpened?.id) >= 0;

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Link to="/search">
          <Button size="lg" className="">
            List
          </Button>
        </Link>
      </div>
      {isLoaded ? (
        <GoogleMapReact
          mapContainerStyle={{ height: "100vh", width: "100%" }}
          onLoad={onLoad}
        >
          <MMapControl position="TOP_LEFT">
            <ControlButtonWrapper onClick={() => setIsShowAll(!isShowAll)}>
              {isShowAll ? "Best Matches" : "Show All"}
            </ControlButtonWrapper>
          </MMapControl>
          <MMapControl position="TOP_LEFT">
            <ControlButton1Wrapper
              onClick={() => setIsShowFavorite(!isShowFavorite)}
            >
              {isShowFavorite ? "Hide Favorites" : "Show Favorites"}
            </ControlButton1Wrapper>
          </MMapControl>
          {tableData.slice(0, dataLength).map((info, index) => (
            <MMarker
              info={info}
              key={info?.id}
              icon={index > 4 ? "circle" : ""}
              curOpened={curOpened}
              setCurOpened={setCurOpened}
            />
          ))}
          {isShowFavorite &&
            mostFavoriteLocations.map((info) => (
              <MMarker
                info={info}
                key={info?.id}
                icon="star"
                hideInfoWindow={hideFavoriteInfoWindow}
                curOpened={curOpened}
                setCurOpened={setCurOpened}
              />
            ))}
          {mapFormData?.markers?.map((info, index) => (
            <MMarker
              info={info}
              key={index}
              icon="arrow"
              hideInfoWindow={true}
              distance={mapFormData.distance}
              withDistance
            />
          ))}
        </GoogleMapReact>
      ) : (
        <></>
      )}
    </div>
  );
};

const ControlButtonWrapper = styled.div`
  background: none padding-box rgb(255, 255, 255);
  margin: 10px;
  padding: 10px 17px;
  cursor: pointer;
  text-align: center;
  height: 40px;
  color: rgb(86, 86, 86);
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 40px;

  &:hover {
    background: none padding-box rgb(235, 235, 235);
    color: rgb(0, 0, 0);
  }

  @media only screen and (max-width: 480px) {
    top: 50px;
    position: relative;
    right: 10px;
    min-height: 60px;
  }
`;

const ControlButton1Wrapper = styled.div`
  background: none padding-box rgb(255, 255, 255);
  margin: 10px;
  padding: 10px 17px;
  cursor: pointer;
  text-align: center;
  height: 40px;
  color: rgb(86, 86, 86);
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 40px;

  &:hover {
    background: none padding-box rgb(235, 235, 235);
    color: rgb(0, 0, 0);
  }

  @media only screen and (max-width: 480px) {
    top: 120px;
    position: relative;
    right: 135px;
    min-height: 60px;
  }
`;
