import React, { useState, useEffect } from "react";
import { MTable } from "../../design_system/MTable";
import { getListMetrics } from "../../utils/api";
import { isEmpty } from "../../utils/util";
import { MInput } from "../../design_system/MInput";
import MPagination from "../../design_system/MPagination";
import MBanner from "../../design_system/MBanner";
import { AuthState } from "../../context/AuthProvider";
import { Avatar } from "@mui/material";
import {
  ActiveUserIcon,
  BlueMarkerIcon,
  DeletedUserIcon,
  OrangeUserIcon,
} from "../../utils/icons";
import { MetricWrapper } from "../../design_system/styled-components";

export const Metrics = () => {
  const [tableData, setTableData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [searchFormData, setSearchFormData] = useState({});
  const [sortFormData, setSortFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(1);
  // eslint-disable-next-line
  const [metrics, setMetrics] = useState({});

  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  function loadUser() {
    const abortController = new AbortController();
    getListMetrics(
      { sort: sortFormData, search: searchFormData.search, page: currentPage },
      abortController.signal
    )
      .then((data) => {
        setTableData(data.data);
        setTotalSize(data.total);
        setMetrics(data.metrics);
      })
      .catch("null");
  }

  useEffect(() => {
    loadUser();

    // eslint-disable-next-line
  }, [sortFormData, currentPage]);

  useEffect(() => {
    if (!isEmpty(tableData)) {
      setColumnNames(["user", "joinDate", "status", "email", "adminActions"]);
    }
  }, [tableData]);

  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      loadUser();
      event.target.blur();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <MBanner title="Admin Metrics" signed={isAuthenticated}></MBanner>
      <div className="m-4">
        <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between gap-lg-3 gap-1">
          <MetricWrapper>
            <Avatar
              variant="rounded"
              sx={{ backgroundColor: "#F6F6F6", width: "4rem", height: "4rem" }}
            >
              <BlueMarkerIcon />
            </Avatar>
            <div>
              <p className="label">Total Location</p>
              <p className="value">240</p>
            </div>
          </MetricWrapper>
          <MetricWrapper>
            <Avatar
              variant="rounded"
              sx={{ backgroundColor: "#F6F6F6", width: "4rem", height: "4rem" }}
            >
              <OrangeUserIcon />
            </Avatar>
            <div>
              <p className="label">Total User</p>
              <p className="value">3400</p>
            </div>
          </MetricWrapper>
          <MetricWrapper>
            <Avatar
              variant="rounded"
              sx={{ backgroundColor: "#F6F6F6", width: "4rem", height: "4rem" }}
            >
              <ActiveUserIcon />
            </Avatar>
            <div>
              <p className="label">Active User</p>
              <p className="value">240</p>
            </div>
          </MetricWrapper>
          <MetricWrapper>
            <Avatar
              variant="rounded"
              sx={{ backgroundColor: "#F6F6F6", width: "4rem", height: "4rem" }}
            >
              <DeletedUserIcon />
            </Avatar>
            <div>
              <p className="label">Delete User</p>
              <p className="value">240</p>
            </div>
          </MetricWrapper>
        </div>

        <div className="mx-2 d-flex justify-content-between align-items-center">
          <h4 className="m-0">User List</h4>
          <div className="d-flex justify-content-end align-items-center">
            <div>
              <MInput
                id="searchKey"
                attribute="search"
                placeholder="Search "
                formData={searchFormData}
                setFormData={setSearchFormData}
                editData=""
                isEdit={true}
                onKeyDown={handlePressEnter}
              />
            </div>
          </div>
        </div>
        <MTable
          columnNames={columnNames}
          tableData={tableData}
          setSortFormData={setSortFormData}
          sortFormData={sortFormData}
        />
        <MPagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          total={totalSize}
          pageSize={50}
        />
      </div>
    </>
  );
};
