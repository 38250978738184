import React, { useState } from "react";

import { forgetPassword } from "../../utils/api";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import notify from "../../utils/notify";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MBanner from "../../design_system/MBanner";
import { FormWrapper } from "../../design_system/styled-components";

const ForgetPassword = () => {
  const [credentials, setCredentials] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (!credentials.email) {
      setIsLoading(false);
      return notify("Please Fill all the Feilds", "warn");
    }

    try {
      await forgetPassword(credentials);
      setIsLoading(false);
    } catch (error) {
      return notify("Internal server error", "error");
    }
  };

  return (
    <>
      <MBanner title="Forget Password">
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <FormWrapper>
        <Form className="w-sm-50" onSubmit={handleSubmit}>
          <h3 className="m-0">Forget Password</h3>
          <p className="mb-4">
            Please enter your email to change your password.
          </p>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <TextField
              type="email"
              name="email"
              tabIndex="1"
              className="d-block w-100"
              placeholder="Enter email"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
              }}
              value={credentials.email || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Button
            type="submit"
            size="lg"
            tabIndex="3"
            className="m-0 mb-3 w-100"
            disabled={isLoading}
          >
            Send
          </Button>
        </Form>
      </FormWrapper>
    </>
  );
};

export default ForgetPassword;
