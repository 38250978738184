import React from "react";
import styled from "styled-components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { BreadcrumbIcon } from "../utils/icons";
import { isEmpty } from "../utils/util";

const MBannerWrapper = styled.div`
  background: linear-gradient(180deg, #1da1f2 0%, #0f8ad6 100%);
  height: 13rem;
  overflow: hidden;
  position: relative;
  color: #fff;
  margin: ${({ signed }) => (signed ? "2rem" : "0")};
  border-radius: ${({ signed }) => (signed ? "1.5rem" : 0)};

  img {
    max-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  img:first-child {
    left: 0;
  }

  img:last-child {
    right: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: 2.25rem;
    font-weight: 700;
  }

  nav {
    z-index: 1;
  }

  .link {
    color: #fff;
    text-decoration: none;
    opacity: 0.74;
  }

  @media only screen and (max-width: 480px) {
    height: 11rem;
  }
`;

export default function MBanner({ title, signed = false, children }) {
  return (
    <MBannerWrapper signed={signed}>
      <img src="/banner-left.png" alt="Zappt" />
      <h6>{title}</h6>
      <Breadcrumbs
        separator={<BreadcrumbIcon />}
        sx={{ position: "absolute", bottom: "1rem" }}
      >
        {children}
        {!isEmpty(children) && <Typography color="white">{title}</Typography>}
      </Breadcrumbs>
      <img src="/banner-right.png" alt="Zappt" />
    </MBannerWrapper>
  );
}
