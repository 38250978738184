import React from "react";

import {
  barType,
  specialtyType,
  events,
  games,
  barMusic,
  parking,
  dogFriendly,
  time2visit,
  amenities,
  ambiance,
  close2Others,
  sports,
  typesOfSports,
  dancing,
  levelOfMixology,
  costOfDrinks,
  costOfCans,
  drinkSpecialties,
  happyHour,
  offersFood,
  foodType,
  foodCost,
  reservation,
  restaurantType,
  meal,
} from "../layout/Location/DrowndownList";

import { MDropdown } from "./MDropdown";
import { MSearchGroupWrapper } from "./styled-components";

export const MSearchGroup = ({ curState, formData, setFormData, tableData, isEdit }) => {
  const searchItems = curState === "type" ? [
    {
      listItems: barType,
      label: "Bar Type",
      attribute: "barType",
      isMultiSelect: true,
    },
    {
      listItems: specialtyType,
      label: "Specialty Type",
      attribute: "specialtyType",
      isMultiSelect: true,
    },
    {
      listItems: events,
      label: "Events",
      attribute: "events",
      isMultiSelect: true,
    },
  ] : curState === "vibe" ? [
    {
      listItems: games,
      label: "Games",
      attribute: "games",
      isMultiSelect: true,
    },
    {
      listItems: barMusic,
      label: "Bar Music",
      attribute: "barMusic",
      isMultiSelect: true,
    },
    {
      listItems: parking,
      label: "Parking",
      attribute: "parking",
      isMultiSelect: true,
    },
    {
      listItems: dogFriendly,
      label: "Pet Friendly",
      attribute: "dogFriendly",
      isMultiSelect: false,
    },
    {
      listItems: time2visit,
      label: "Best Time to Visit",
      attribute: "time2visit",
      isMultiSelect: true,
    },
    {
      listItems: amenities,
      label: "Amenities",
      attribute: "amenities",
      isMultiSelect: true,
    },
    {
      listItems: ambiance,
      label: "Ambiance",
      attribute: "ambiance",
      isMultiSelect: true,
    },
    {
      listItems: close2Others,
      label: "Close to Other bars",
      attribute: "close2Others",
      isMultiSelect: true,
    },
    {
      listItems: sports,
      label: "Sports Watching",
      attribute: "sports",
      isMultiSelect: true,
    },
    {
      listItems: typesOfSports,
      label: "Types of Sports",
      attribute: "typesOfSports",
      isMultiSelect: true,
    },
    {
      listItems: dancing,
      label: "Dancing",
      attribute: "dancing",
      isMultiSelect: false,
    },
  ] : curState === "drinks" ? [
    {
      listItems: levelOfMixology,
      label: "Mixology",
      attribute: "mixology",
      isMultiSelect: true,
    },
    {
      listItems: costOfDrinks,
      label: "Drink Cost",
      attribute: "drinkCost",
      isMultiSelect: true,
    },
    {
      listItems: costOfCans,
      label: "Beer Cost",
      attribute: "beerCost",
      isMultiSelect: true,
    },
    {
      listItems: drinkSpecialties,
      label: "Drink Specialties",
      attribute: "drinkSpecialties",
      isMultiSelect: true,
    },
    {
      listItems: happyHour,
      label: "Happy Hour",
      attribute: "happyHour",
      isMultiSelect: true,
    },
  ] : [
    {
      listItems: offersFood,
      label: "Offers Food",
      attribute: "offersFood",
      isMultiSelect: false,
    },
    {
      listItems: foodType,
      label: "Food Type",
      attribute: "foodType",
      isMultiSelect: true,
    },
    {
      listItems: foodCost,
      label: "Cost of Food",
      attribute: "foodCost",
      isMultiSelect: true,
    },
    {
      listItems: reservation,
      label: "Reservation",
      attribute: "reservation",
      isMultiSelect: false,
    },
    {
      listItems: meal,
      label: "Meal",
      attribute: "meal",
      isMultiSelect: true,
    },
    {
      listItems: restaurantType,
      label: "Restaurant Type",
      attribute: "restaurantType",
      isMultiSelect: true,
    },
  ];

  return (
    <MSearchGroupWrapper bg={curState}>
      {searchItems.map((item, index) => (
        <MDropdown
          key={index}
          className="my-2"
          listItems={item.listItems}
          formData={formData}
          setFormData={setFormData}
          attribute={item.attribute}
          label={item.label}
          // editData={formData[item.attribute] || []}
          isEdit={true}
          isMulti={item.isMultiSelect}
          placeholder={`Choose ${item.label}`}
          sx={{backgroundColor: "white", borderRadius: "0.375rem"}}
        />
      ))}
    </MSearchGroupWrapper>
  );
};
