import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import { AuthState } from "../../context/AuthProvider";
import notify from "../../utils/notify";
import { googleSign, signIn } from "../../utils/api";
import GoogleLoginCmp from "./GoogleLoginCmp";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MBanner from "../../design_system/MBanner";
import { FormWrapper } from "../../design_system/styled-components";

const SignIn = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const { setAuth } = AuthState();

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (!credentials.email || !credentials.password) {
      setIsLoading(false);
      return notify("Please Fill all the Feilds", "warn");
    }

    try {
      const data = await signIn(credentials);
      handleSignSuccess(data);
    } catch (error) {
      setIsLoading(false);
      return notify("Internal server error", "error");
    }
  };

  const handleGoogleLogin = async (credentials) => {
    try {
      const data = await googleSign(credentials);
      handleSignSuccess(data);
    } catch (error) {
      setIsLoading(false);
      return notify("Internal server error", "error");
    }
  };

  const handleSignSuccess = (data) => {
    if (data.success) {
      setAuth(data);
      setIsLoading(false);
      navigate(-1); // Go to page user was previously on
      // navigate(0);
      return notify("You are successfully logged in", "success");
    } else {
      setIsLoading(false);
      return notify(data.error, "warn");
    }
  };

  return (
    <>
      <MBanner title="Sign In">
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <FormWrapper>
        <Form className="w-sm-50" onSubmit={loginHandler}>
          <h3 className="m-0">Sign In</h3>
          <p className="mb-4">
            Please enter your credentials to access your account.
          </p>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <TextField
              type="email"
              name="email"
              tabIndex="1"
              className="d-block w-100"
              placeholder="Enter email"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
              }}
              value={credentials.email || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label className="d-flex justify-content-between">
              <span>Password</span>
              <Link
                to="/forget-password"
                tabIndex="5"
                className="forget-password"
              >
                Forget Password?
              </Link>
            </Form.Label>
            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              tabIndex="2"
              className="d-block w-100"
              placeholder="Enter password"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={credentials.password || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Button
            type="submit"
            size="lg"
            tabIndex="3"
            className="m-0 mb-3 w-100"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Sign In"
            )}
          </Button>

          <Divider
            className="mb-3"
            sx={{ fontSize: "0.875rem", color: "#97979E" }}
          >
            Or
          </Divider>

          <GoogleLoginCmp handleGoogleLogin={handleGoogleLogin} />

          <Form.Group className="text-center mt-5" controlId="signUp">
            <span className="other">
              Don't have an account?&nbsp;
              <Link to="/signUp" tabIndex="5" className="text-decoration-none">
                SignUp
              </Link>
            </span>
          </Form.Group>
        </Form>
      </FormWrapper>
    </>
  );
};

export default SignIn;
