import React from "react";
import { getBusinessDetailById } from "../utils/api";
import { ModalWrapper } from "./styled-components";
import { Button } from "react-bootstrap";
import SyncIcon from "@mui/icons-material/Sync";

export const MFetchConfirmModal = ({ tableData, locationIdx, setIsOpen }) => {
  const handleFetchGoogleInfo = async () => {
    const abortController = new AbortController();
    await getBusinessDetailById(
      tableData[locationIdx].id,
      abortController.signal
    );
    setIsOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <ModalWrapper>
      <div
        className="modal fade"
        id="fetchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center border-0">
              <SyncIcon
                sx={{ color: "#1da2f2", fontSize: "4rem", margin: "auto" }}
              />
            </div>
            <div className="modal-body text-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Entry
              </h5>
              <p className="m-0">Location information is already exist.</p>
              <p className="m-0">Do you want to update it?</p>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between w-100 gap-2">
                <Button
                  size="lg"
                  variant="outline-primary"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="lg"
                  variant="danger"
                  className="m-0 w-50"
                  data-dismiss="modal"
                  onClick={handleFetchGoogleInfo}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
