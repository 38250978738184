import React, { useState, useEffect } from "react";
import { MTable } from "../../design_system/MTable";
import {
  deleteAllConflictNewLocations,
  deleteAllNewLocations,
  deleteLocation,
  handleCreateNewLocations,
  listCheckLocations,
  markAllNewLocationsAsOld,
} from "../../utils/api";
import { isEmpty } from "../../utils/util";
import MPagination from "../../design_system/MPagination";
import MBanner from "../../design_system/MBanner";
import { Link } from "react-router-dom";
import { AuthState } from "../../context/AuthProvider";
import { Button, Form } from "react-bootstrap";
import UploadIcon from "@mui/icons-material/Upload";
import MUploadButton from "../../design_system/MUploadButton";
import MButtonGroup from "../../design_system/MButtonGroup";
import { MDropdown } from "../../design_system/MDropdown";
import { read, utils } from "xlsx";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  LicenseTypeList,
  LicenseTypesInit,
  MapLicenseTypes,
} from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import notify from "../../utils/notify";

const steps = [
  "Check Column Names",
  "Select License Type",
  "Map License Type",
  "Show New Locations",
  "Check All Locations",
];

export const UploadLicense = () => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [excelColNames, setExcelColNames] = useState([]);
  // const [newLocationCnt, setNewLocationCnt] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const locationColAttrs = [
    { attribute: "name", text: "DOING BUSINESS AS" },
    { attribute: "license_type", text: "LICENSE TYPE" },
    { attribute: "expires", text: "EXPIRES" },
    { attribute: "street", text: "STREET ADDRESS" },
    { attribute: "city", text: "CITY" },
    { attribute: "state", text: "STATE" },
    { attribute: "zip", text: "ZIP" },
  ];
  const [attrNames, setAttrNames] = useState({
    name: "DOING BUSINESS AS",
    license_type: "LICENSE TYPE",
    expires: "EXPIRES",
    street: "STREET ADDRESS",
    city: "CITY",
    state: "STATE",
    zip: "ZIP",
  });
  const [curLicenseTypes, setCurLicenseTypes] = useState({
    licenseTypes: Object.keys(LicenseTypesInit),
    ...LicenseTypesInit,
  });
  const [newLocationsOriData, setNewLocationsOriData] = useState([]);
  const [newLocationsAllData, setNewLocationsAllData] = useState([]);
  const [newLocationsCurData, setNewLocationsCurData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    newTotalCount: 0,
  });
  const [sortFormData, setSortFormData] = useState([]);
  const [expireDateFormat, setExpireDateFormat] = useState({
    format: "mm/dd/yyyy",
  });

  const checkListAttrs = {
    name: attrNames.name,
    address: attrNames.address,
    is_new: "STATUS",
    actions: "ACTIONS",
    expires: attrNames.expires,
    license_type: attrNames.license_type,
    street: attrNames.street,
    city: attrNames.city,
    state: attrNames.state,
    zip: attrNames.zip,
  };

  const radioOptions = [
    { value: "old", label: "Origin" },
    { value: "new", label: "New" },
    { value: "conflict", label: "Conflict" },
    { value: "fetched", label: "Fetched Conflct" },
    { value: "all", label: "All" },
  ];
  const [radioChecked, setRadioChecked] = useState("all");

  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const handleLicenseFileChange = (e) => {
    setActiveStep(0);
    const file = e.target.files[0];
    let reader = new FileReader();

    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = read(data, { type: "array" });
      // find the name of your sheet in the workbook first
      let worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // convert to json format
      const jsonData = utils.sheet_to_json(worksheet, { raw: false });
      if (jsonData.length > 0) {
        setExcelColNames(
          Object.keys(jsonData[0]).map((key) => ({ text: key, value: key }))
        );
      }
      setNewLocationsOriData(jsonData);
      setIsFileSelected(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDateFormat = (value) => {
    const { format: dateFormat } = expireDateFormat;
    let date = value;
    if (!isEmpty(value) && dateFormat === "dd/mm/yyyy") {
      date = value.split(" ")[0];
      date = date.split("/");
      date = date[1] + "/" + date[0] + "/" + date[2];
    }
    return date;
  };

  const handleNewLocationsPageChange = (page, data = newLocationsAllData) => {
    setNewLocationsCurData(
      data.slice((page - 1) * pagination.pageSize, page * pagination.pageSize)
    );
    setPagination({
      ...pagination,
      currentPage: page,
      totalCount: data.length,
    });
  };

  const sortStringAsc = (a, b, key) =>
    a[key].toLowerCase().localeCompare(b[key].toString().toLowerCase());
  const sortStringDesc = (a, b, key) =>
    b[key].toLowerCase().localeCompare(a[key].toString().toLowerCase());

  const sortDateAsc = (a, b, key) => new Date(a[key]) - new Date(b[key]);
  const sortDateDesc = (a, b, key) => new Date(b[key]) - new Date(a[key]);

  useEffect(() => {
    if (steps[activeStep] === "Show New Locations") {
      const sortedData = [...newLocationsAllData];
      sortFormData.forEach((data) => {
        const [key, value] = Object.entries(data)[0];
        sortedData.sort((a, b) =>
          key === "expires"
            ? value
              ? sortDateAsc(a, b, key)
              : sortDateDesc(a, b, key)
            : value
            ? sortStringAsc(a, b, key)
            : sortStringDesc(a, b, key)
        );
      });

      setNewLocationsAllData(sortedData);
      setNewLocationsCurData(sortedData.slice(0, pagination.pageSize));
      setPagination({ ...pagination, currentPage: 1 });
    } else if (steps[activeStep] === "Check All Locations") {
      loadLocations(1);
    }
    // eslint-disable-next-line
  }, [sortFormData, radioChecked]);

  useEffect(() => {
    if (steps[activeStep] === "Show New Locations") {
      const temp = newLocationsOriData?.reduce((acc, data) => {
        if (
          curLicenseTypes.licenseTypes.includes(data[attrNames.license_type])
        ) {
          const newLocation = { id: uuidv4(), is_new: true };
          Object.keys(attrNames).forEach(
            (key) => (newLocation[key] = data[attrNames[key]])
          );
          newLocation.license_type = curLicenseTypes[newLocation.license_type];
          newLocation.expires = handleDateFormat(newLocation.expires);
          newLocation.zip = newLocation.zip.slice(
            0,
            Math.min(5, newLocation.zip.length)
          );
          const suiteIndex = newLocation.street.toLowerCase().indexOf("suite");
          newLocation.street =
            suiteIndex !== -1
              ? newLocation.street.slice(0, suiteIndex)
              : newLocation.street;
          acc.push(newLocation);
        }
        return acc;
      }, []);
      setNewLocationsAllData(temp);
      handleNewLocationsPageChange(1, temp);
    } else if (steps[activeStep] === "Check All Locations") {
      const abortController = new AbortController();
      setSortFormData([
        { state: true },
        { city: true },
        { zip: true },
        { street: true },
        { name: true },
      ]);
      if (!isEmpty(newLocationsAllData))
        handleCreateNewLocations(newLocationsAllData, abortController.signal)
          .then((data) => {
            loadLocations();
          })
          .catch("null");
    } else if (activeStep === steps.length) {
      handleMarkAllNewLocationsAsOld();
    }
    // eslint-disable-next-line
  }, [activeStep]);

  function loadLocations(page = 1) {
    const abortController = new AbortController();
    listCheckLocations(
      { sort: sortFormData, page: page, checked: radioChecked },
      abortController.signal
    )
      .then((data) => {
        setPagination({
          ...pagination,
          currentPage: page,
          totalCount: data.total,
          newTotalCount: data.new_total,
        });
        setNewLocationsCurData(data.data);
      })
      .catch("null");
  }

  const handleCheckLocationsPageChange = (page) => {
    loadLocations(page);
  };

  const handleDeleteAllNewLocations = () => {
    const abortController = new AbortController();
    deleteAllNewLocations(abortController.signal)
      .then((data) => {
        notify(data.message, "success");
      })
      .catch("null");
  };

  const handleDeleteConflictNewLocations = () => {
    const abortController = new AbortController();
    deleteAllConflictNewLocations(abortController.signal)
      .then((data) => {
        notify(data.message, "success");
      })
      .catch("null");
  };

  const handleMarkAllNewLocationsAsOld = async () => {
    const abortController = new AbortController();
    await markAllNewLocationsAsOld(abortController.signal);
    notify("New Locations marked as Old Ones", "success");
    setActiveStep(0);
  };

  const handleDeleteConfirmModal = (formData) => {
    const abortController = new AbortController();

    deleteLocation(formData, abortController.signal)
      .then((data) => {
        const temp = newLocationsCurData.filter(
          (item) => item.id !== formData.id
        );
        setNewLocationsCurData(temp);
      })
      .catch("null");
  };

  return (
    <>
      <MBanner title="Upload License" signed={isAuthenticated}>
        <Link className="link" to="/metrics">
          Admin Metrics
        </Link>
      </MBanner>
      <div className="m-4">
        <div className="mx-2 d-flex justify-content-between align-items-center">
          <h4 className="m-0">Upload License</h4>
          <div className="d-flex justify-content-end align-items-center">
            <MUploadButton onChange={handleLicenseFileChange}>
              <Button size="lg" className="pl-2 ml-2">
                <UploadIcon />
                Select File
              </Button>
            </MUploadButton>
            <Button
              size="lg"
              className="pl-2 ml-2"
              onClick={() => setActiveStep(4)}
            >
              New Locations
            </Button>
          </div>
        </div>
        <div className="mCard mt-4">
          {!isFileSelected && activeStep === 0 ? (
            <>
              <p className="text-center">Select File to Upload</p>
            </>
          ) : (
            <>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="mt-4">
                {activeStep === 0 && (
                  <>
                    {locationColAttrs.map((location, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center mx-auto my-2 gap-5"
                      >
                        <span className="w-50 d-flex justify-content-end">
                          {location.text}
                        </span>
                        <div className="w-50 d-flex align-items-center gap-2 flex-wrap">
                          <div className="w-25 m-w-10">
                            <MDropdown
                              attribute={location.attribute}
                              formData={attrNames}
                              setFormData={setAttrNames}
                              editData={attrNames[location.attribute]}
                              listItems={excelColNames}
                              isEdit={true}
                              label=""
                            />
                          </div>
                          {location.attribute === "expires" && (
                            <div className="w-25 m-w-10">
                              <MDropdown
                                attribute="format"
                                formData={expireDateFormat}
                                setFormData={setExpireDateFormat}
                                editData={expireDateFormat.format}
                                listItems={[
                                  {
                                    text: "mm/dd/yyyy",
                                    value: "mm/dd/yyyy",
                                  },
                                  {
                                    text: "dd/mm/yyyy",
                                    value: "dd/mm/yyyy",
                                  },
                                ]}
                                isEdit={true}
                                label=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <MButtonGroup
                      listItems={LicenseTypeList}
                      formData={curLicenseTypes}
                      setFormData={setCurLicenseTypes}
                      attribute="licenseTypes"
                      isEdit={true}
                      isMultiSelect={true}
                      size="sm"
                    />
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    {curLicenseTypes?.licenseTypes?.map((license, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center mx-auto my-2 gap-5"
                      >
                        <span className="w-50 d-flex justify-content-end">
                          {license}
                        </span>
                        <div className="w-50">
                          <MDropdown
                            attribute={license}
                            formData={curLicenseTypes}
                            setFormData={setCurLicenseTypes}
                            editData={curLicenseTypes[license]}
                            listItems={MapLicenseTypes}
                            isEdit={true}
                            label=""
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <p>
                      {`Total ${newLocationsOriData.length} / Filtered ${newLocationsAllData.length}`}
                    </p>
                    <MTable
                      columnNames={Object.keys(attrNames)}
                      columnLabels={attrNames}
                      tableData={newLocationsCurData}
                      setSortFormData={setSortFormData}
                      sortFormData={sortFormData}
                    />
                    <MPagination
                      currentPage={pagination.currentPage}
                      onPageChange={handleNewLocationsPageChange}
                      total={pagination.totalCount}
                      pageSize={pagination.pageSize}
                    />
                  </>
                )}
                {activeStep === 4 && (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      {`Total ${pagination.totalCount} / New ${pagination.newTotalCount}`}
                      <div className="d-flex justify-content-end align-items-center">
                        {radioOptions.map((option, index) => (
                          <Form.Check
                            className="mx-2"
                            type="radio"
                            name={option.value}
                            id={option.value}
                            key={index}
                            label={option.label}
                            value={radioChecked === option.value}
                            onChange={(e) => setRadioChecked(option.value)}
                            checked={radioChecked === option.value}
                          />
                        ))}
                        <>
                          <Button
                            className="mr-2 ml-4"
                            size="sm"
                            variant="danger"
                            onClick={handleDeleteAllNewLocations}
                          >
                            Delete All New Location
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={handleDeleteConflictNewLocations}
                          >
                            Delete Conflict New Location
                          </Button>
                        </>
                      </div>
                    </div>
                    <MTable
                      columnNames={Object.keys(checkListAttrs)}
                      columnLabels={checkListAttrs}
                      tableData={newLocationsCurData}
                      setSortFormData={setSortFormData}
                      sortFormData={sortFormData}
                      handleDelete={handleDeleteConfirmModal}
                    />
                    <MPagination
                      currentPage={pagination.currentPage}
                      onPageChange={handleCheckLocationsPageChange}
                      total={pagination.totalCount}
                      pageSize={pagination.pageSize}
                    />
                  </>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep !== 4 && (
                    <Button
                      size="lg"
                      variant="primary"
                      disabled={activeStep === 0}
                      onClick={() => setActiveStep(activeStep - 1)}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                  )}

                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button
                    size="lg"
                    variant="primary"
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
