import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import notify from "../../utils/notify";
import styled from "styled-components";
import { GoogleIcon } from "../../utils/icons";

const GoogleLoginCmp = ({ handleGoogleLogin, ...props }) => {
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      const myUUID = uuidv4();
      handleGoogleLogin({ ...credentialResponse, id: myUUID });
    },
    onError: () => notify(`Couldn't connect Google Login`, "error"),
    auto_select: true,
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const myUUID = uuidv4();
      handleGoogleLogin({ ...codeResponse, id: myUUID });
    },
    onError: () => notify(`Couldn't connect Google Login`, "error"),
    flow: "auth-code",
  });

  return (
    <GoogleWrapper onClick={() => googleLogin()}>
      <GoogleIcon />
      <span className="ml-2">Continue With Google</span>
    </GoogleWrapper>
  );
};

export default GoogleLoginCmp;

export const GoogleWrapper = styled.div`
  width: 100%;
  min-height: 3rem;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  cursor: pointer;
`;
