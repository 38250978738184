import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "react-bootstrap";
import { SignOutIcon } from "../utils/icons";
import Avatar from "@mui/material/Avatar";
import { AuthState } from "../context/AuthProvider";
export default function MHeaderMobile({
  handleSignOut,
  setShowSearchModal,
  setIsShowDrinkModal,
  navList,
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { auth } = AuthState();
  const { isAuthenticated } = auth || {};

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsMenuOpen(open);
  };

  const ItemButtonStyle = {
    px: 0,
    mx: isAuthenticated ? "1rem" : "1.5rem",
    maxWidth: "fit-content",
    transition: "0.1s",
    ":hover": {
      backgroundColor: "rgba(13, 138, 215, 0.40)",
      borderRadius: "0.25rem",
      padding: "0.5rem 0.5rem",
      marginRight: "2rem",
      a: {
        color: "white !important",
        border: "none !important",
      },
      li: {
        color: "white !important",
        border: "none !important",
      },
      svg: {
        color: "white !important",
      },
    },
  };

  return (
    <React.Fragment>
      <MenuIcon color="info" onClick={toggleDrawer(true)} fontSize="large" />
      <Drawer
        className="mobile-drawer"
        anchor={"right"}
        open={isMenuOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 10000 }}
      >
        <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {isAuthenticated && (
              <div className="d-flex align-items-center mb-2">
                <Avatar
                  className="mr-2 ml-4"
                  alt="user avatar"
                  src={auth.avatar}
                />
                <p className="m-0">{`${auth?.firstName ? auth.firstName : ""} ${
                  auth?.lastName ? auth.lastName : ""
                }`}</p>
              </div>
            )}
            <ListItem className="py-0">
              <Button
                data-toggle="modal"
                data-target="#updateModal"
                size="lg"
                className="mx-0"
                onClick={() => setIsShowDrinkModal(true)}
              >
                Drinks Near Me
              </Button>
            </ListItem>
            <ListItem className="py-0">
              <Button
                size="lg"
                data-toggle="modal"
                data-target="#updateModal"
                className={`${isAuthenticated ? "mx-0" : ""} w-6`}
                onClick={() => setShowSearchModal(true)}
              >
                Search
              </Button>
            </ListItem>
            {!isAuthenticated && (
              <>
                <ListItem className="py-0">
                  <Link to="/signIn">
                    <Button size="lg" className="w-6">
                      Sign In
                    </Button>
                  </Link>
                </ListItem>
                <ListItem className="py-0">
                  <Link to="/signUp">
                    <Button size="lg" variant="outline-dark" className="w-6">
                      Sign Up
                    </Button>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
          <Divider
            className="mt-2"
            sx={{ borderColor: "#1DA1F2", opacity: 1 }}
          />
          <List
            className="nav my-4"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {navList.map((item) => (
              <ListItemButton key={item.text} sx={ItemButtonStyle}>
                {isAuthenticated && (
                  <ListItemIcon
                    sx={{ minWidth: 0, mr: "0.5rem" }}
                    className="p-0"
                  >
                    {item.icon}
                  </ListItemIcon>
                )}
                <Link key={item.url} to={item.url} className="mx-1 my-0">
                  <ListItem className="p-0">{item.text}</ListItem>
                </Link>
              </ListItemButton>
            ))}
            {isAuthenticated && (
              <ListItemButton sx={ItemButtonStyle}>
                <ListItemIcon
                  sx={{ minWidth: 0, ml: "0.25rem", mr: "0.25rem" }}
                  className="p-0"
                >
                  <SignOutIcon />
                </ListItemIcon>
                <ListItem className="p-0 mx-1" onClick={handleSignOut}>
                  Log out
                </ListItem>
              </ListItemButton>
            )}
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
