import React from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import { Layout } from "./layout/Layout";
import AuthProvider from "./context/AuthProvider";
import { StyleSheetManager } from "styled-components";
import isValidProp from "@emotion/is-prop-valid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

/**
 * Defines the root application component.
 * @returns {JSX.Element}
 */
const App = () => {
  const routes = useRoutes([
    {
      path: "/*",
      element: (
        <AuthProvider>
          <StyleSheetManager
            shouldForwardProp={(propName) => isValidProp(propName)}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Layout />
            </LocalizationProvider>
          </StyleSheetManager>
        </AuthProvider>
      ),
    },
  ]);
  return routes;
};

export default App;
