import styled from "styled-components";

export const ModalWrapper = styled.div`
  --bs-border-radius: 0.5rem;
  --bs-border-radius-sm: 0.5rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 0.5rem;
  --bs-border-radius-xxl: 0.5rem;
  --bs-border-width: 0;

  .modal-content {
    padding: 1rem;
  }

  .modal-body {
    padding-top: 0;
  }

  .fade {
    width: 0;
  }

  .show {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .btn-outline-primary {
    --bs-btn-hover-bg: #f6f6f6;
    --bs-btn-hover-color: #1c1c1f;
    --bs-btn-hover-border-color: #f6f6f6;
  }
`;

export const SearchFieldsWrapper = styled.div`
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 5px !important;
  align-items: center !important;
  .dropdown {
    margin: 5px !important;
  }

  @media only screen and (max-width: 480px) {
    button,
    button:active {
      color: rgb(225, 232, 237) !important;
      background-color: rgb(29, 161, 242) !important;
      border: none;
      width: 100%;
      margin: 5px 0;
    }
  }
`;

export const DropdownWrapper = styled.div`
  .dropdownButton {
    .btn-primary {
      background-color: #f6f6f6 !important;
      color: #1c1c1f !important;
      border-color: #f6f6f6 !important;

      max-width: 100%;
      overflow: auto;
      min-width: 5rem;
      min-height: 1.5rem;
      display: flex;
      padding: 0.15rem 1.75rem 0.15rem 0.75rem;
      align-items: center;
      position: relative;
    }

    .dropdown-toggle::after {
      position: absolute;
      right: 1rem;
    }

    ::-webkit-scrollbar {
      background-color: #f5f5f5;
      width: 2px;
    }

    /* Set the color of the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background-color: #1da1f2;
    }

    /* Set the color of the scrollbar track */
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    .dropdown-menu {
      box-shadow: 0 5px 10px #657786 !important;
      max-height: 40vh;
      overflow: auto;

      .dropdown-item:active {
        background-color: #14171a !important;
      }

      .dropdown-item {
        display: flex;
        align-items: center;

        .dropdown-item-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .dropdown-item-icon svg {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          fill: #1da1f2;
        }
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

  header {
    img.logo {
      width: 70px;
      height: 59px;
      margin-right: 60px;
    }

    .toggle-button {
      left: -18px;
      top: calc(50% - 20px);
      position: absolute;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      width: 2rem;
      height: 2rem;
    }

    @media only screen and (max-width: 1440px) {
      img.logo {
        margin-right: 10px;
      }
      .btn-lg {
        padding: 0.5rem 1rem !important;
      }
    }

    @media only screen and (max-width: 1024px) {
      max-width: 1024px;

      nav a {
        margin: 0 5px;
      }

      .btn-lg {
        padding: 0.5rem !important;
      }
    }

    @media only screen and (max-width: 576px) {
      img.logo {
        width: 2.75rem;
        height: 2.3125rem;
      }
    }
  }
`;

export const FooterWrapper = styled.div`
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;

  color: #5b5b63;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const SidebarWrapper = styled.div`
  z-index: 2;

  img {
    width: 4.3rem;
    height: 3.5rem;
    margin: 1.15rem auto;
  }

  .toggle-button {
    position: relative;
    button {
      transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1);
      left: ${({ open }) => (open ? "225px" : "90px")};
      top: 30px;
      position: fixed;
      border-radius: 50%;
      background: #fff !important;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      width: 2rem;
      height: 2rem;
      z-index: 9999;
    }
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
`;

export const TableWrapper = styled.div`
  ::-webkit-scrollbar {
    height: 0.375rem;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2c2d1;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #f3f3f6;
  }

  > div {
    overflow: auto;
    border-radius: 1rem;

    table::before {
      content: "";
      width: 100%;
      height: calc(100% - 1rem);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.9;
      border-radius: 1rem;
      box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.12);
      z-index: -1;
    }
    height: 50vh;

    .table > :not(caption) > * > * {
      padding: 0.62rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      min-width: 5rem;
      width: fit-content;
      max-width: 15rem;
    }

    table {
      --bs-body-bg: transparent;
      border-color: transparent;

      thead {
        background: #f9f9f9;
        th {
          min-width: 10rem;
          width: fit-content;
          max-width: 10rem;
          position: sticky;
          top: 0;
          z-index: 1;
          background: #f9f9f9;
        }
      }
    }
  }
`;

export const MCardWrapper = styled.div`
  .card {
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.7rem 0px rgba(0, 0, 0, 0.15);
    padding: 0.75rem;
    border: none;

    img {
      border-radius: 0.75rem;
      width: 100%;
      aspect-ratio: 2;
      object-fit: cover;
    }

    .card-img-overlay button {
      background: white;
      border-radius: 0.3rem;
      padding: 0.3rem;
      margin: 0.5rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;

      & > div {
        & > div {
          width: ${({ withRate }) => (withRate ? "60%" : "80%")};
          .card-title,
          p {
            font-size: 1rem;
            margin-bottom: 0.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }
          .card-title {
            font-weight: 600;
          }

          p {
            font-size: 0.875rem;
            color: #5b5b63;
          }
        }

        & > svg {
          border-radius: 0.3125rem;
          background: #f6f6f6;
          padding: 0.3rem;
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      .title {
        color: #838390;
        font-size: 0.8125rem;
        font-weight: 400;
        margin: 0.5rem 0 0 0;
      }

      .card-text {
        color: #1c1c1f;
        height: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .card-text,
      .text {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
`;

export const FormWrapper = styled.div`
  form {
    margin: 5rem auto;
    max-width: 36rem;
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);

    h3 {
      color: #1c1c1f;
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      color: #4d4d54;
    }

    .forget-password {
      color: #1da1f2;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: none;
    }

    .other {
      color: #707076;
      font-size: 0.875rem;

      a {
        color: #1c1c1f;
      }
    }

    @media only screen and (max-width: 576px) {
      margin: 5rem 2rem;
      width: calc(100% - 4rem);
    }
  }
`;

export const SearchModalWrapper = styled.div`
  display: flex;
  align-items: center;

  .modal-dialog {
    width: 768px;
    max-width: 100%;
    margin: 0;
  }

  .modal-header {
    padding-top: 2rem;
    padding-bottom: 0.5rem;

    .modal-title {
      color: #1c1c1f;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .filter-group {
    .btn-outline-primary {
      color: #1da1f2 !important;
      border-color: white !important;
      background-color: white;
    }

    .btn-outline-secondary {
      border-radius: 0.375rem;
      border: 2px solid #1c1c1f;
      background: linear-gradient(
        168deg,
        rgba(227, 233, 237, 0.75) -45.94%,
        rgba(227, 233, 237, 0) 91.28%
      );
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    }

    .btn-outline-secondary.active {
      border: 2px solid #1da1f2;
      background: #1da1f2;
      color: #fff;
    }

    .btn-outline-secondary:hover {
      background: #1da1f2;
      color: #fff;
      border-color: #1da1f2;
    }
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2c2d1;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .modal-body {
    height: 70vh;
    overflow: auto;
    margin: 0 0.25rem 0 0;
    padding-bottom: 2rem;
  }

  .modal-footer {
    background: #f6f6f6;
    .btn-outline-dark {
      --bs-btn-bg: white;
    }
  }

  .criteria-btn {
    color: #1c1c1f !important;
    border: white !important;
    background: white !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    width: 25%;
    margin: 0;
  }

  .criteria-btn.active {
    color: #1da1f2 !important;
  }

  @media only screen and (max-width: 768px) {
    .modal-dialog {
      width: 100%;
    }

    .criteria-btn {
      width: fit-content;
    }
  }

  @media only screen and (max-width: 1320px) {
    .modal-header {
      flex-direction: column;
    }

    .modal-title {
      margin-bottom: 1rem;
    }
    .modal-header {
      flex-direction: column;
    }

    .modal-title {
      font-size: 1.2rem !important;
    }
  }

  @media only screen and (max-width: 480px) {
  }
`;

export const ReviewModalWrapper = styled.div`
  display: flex;
  align-items: center;

  .modal-dialog {
    width: 1200px;
    max-width: 100%;
    margin: 0;
  }

  .modal-header {
    padding-top: 2rem;
    padding-bottom: 0.5rem;

    .modal-title {
      color: #1c1c1f;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .filter-group {
    .btn-outline-primary {
      color: #1da1f2 !important;
      border-color: white !important;
      background-color: white;
    }

    .btn-outline-secondary {
      border-radius: 0.375rem;
      border: 2px solid #1c1c1f;
      background: linear-gradient(
        168deg,
        rgba(227, 233, 237, 0.75) -45.94%,
        rgba(227, 233, 237, 0) 91.28%
      );
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    }

    .btn-outline-secondary.active {
      border: 2px solid #1da1f2;
      background: #1da1f2;
      color: #fff;
    }

    .btn-outline-secondary:hover {
      background: #1da1f2;
      color: #fff;
      border-color: #1da1f2;
    }
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2c2d1;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .modal-body {
    height: 70vh;
    margin: 0 0.5rem;
    padding-bottom: 2rem;
  }

  .modal-footer {
    background: #f6f6f6;
    z-index: 1;
    padding: 0.5rem !important;
    display: flex;
    align-items: center;

    .btn-outline-dark {
      --bs-btn-bg: white;
    }
  }

  .criteria-btn {
    color: #1c1c1f !important;
    border: white !important;
    background: white !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    width: 25%;
    margin: 0;
  }

  .criteria-btn.active {
    color: #1da1f2 !important;
  }

  @media only screen and (max-width: 768px) {
    .modal-dialog {
      width: 100%;
    }

    .criteria-btn {
      width: fit-content;
    }
  }

  @media only screen and (max-width: 1320px) {
    .modal-header {
      flex-direction: column;
    }

    .modal-title {
      margin-bottom: 1rem;
    }
    .modal-header {
      flex-direction: column;
    }

    .modal-title {
      font-size: 1.2rem !important;
    }
  }

  @media only screen and (max-width: 480px) {
  }
`;

export const ButtonGroupWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1rem;

  & > div {
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #cacad9;
  }

  p {
    color: #1c1c1f;
    font-weight: 500;
  }

  p.sub-title {
    font-size: 0.9375rem;
    font-weight: 400;
  }
`;

export const ReviewWrapper = styled.div`
  & > div {
    border-radius: 0.75rem;
    border: 1px solid #cacad9;
    padding: 1rem;
    margin-top: 1.5rem;

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .sub {
      color: #838390;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .star {
      color: #606065;
      font-weight: 400;
    }
  }
`;

export const PaginationWrapper = styled.div`
  margin: 1rem auto;
  display: flex;
  justify-content: center;

  --bs-link-color: #808089;
  --bs-border-color: transparent;
  border-radius: 50%;

  .page-item > .page-link {
    user-select: none;
    padding: 0.5rem 1rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active > .page-link,
  .page-link.active,
  .page-link:focus,
  .page-link:hover {
    --bs-pagination-active-border-color: transparent;
    --bs-pagination-active-bg: #f6f6f6 !important;
    --bs-pagination-active-color: #1c1c1f !important;
    --bs-pagination-hover-color: #1c1c1f !important;
    border-radius: 100% !important;
  }
`;

export const MetricWrapper = styled.div`
  border-radius: 1rem;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  padding: 1.25rem 1.5rem;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  .label {
    color: #5b5b63;
    margin: 0;
  }

  .value {
    color: #1c1c1f;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
  }

  @media only screen and (max-width: 1200px) {
    width: 49%;

    .label {
      font-size: 0.875rem;
    }

    .value {
      font-size: 1.125rem;
    }
  }
`;

export const MUploadButtonWrapper = styled.div`
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const MSearchGroupWrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("/images/filter/${(props) => props.bg}.png"),
    lightgray 50% / cover no-repeat;
  padding: 6rem 1.5rem;
  background-size: auto 100%;
  background-position-x: center;
  margin-top: 3rem;
`;
