import React, { useEffect, useState } from "react";
import {
  getImageURL,
  setFavoriteLocation,
  setLocationRating,
} from "../../utils/api";
import { Button, Card, Col, Row } from "react-bootstrap";
import { MStarRating } from "../../design_system/MStarRating";
import notify from "../../utils/notify";
import { AuthState } from "../../context/AuthProvider";
import { isEmpty, numberFormat } from "../../utils/util";
import {
  MCardWrapper,
} from "../../design_system/styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FavoriteBorder, Favorite } from "@mui/icons-material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { FeaturedLocationIcon } from "../../utils/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

export default function ReviewDetail({ setFilterData, data }) {
  const navigate = useNavigate();
  const { auth, setAuth } = AuthState();
  const { favoriteLocations } = auth || {};

  const ratingItems = [
    { text: "Date spot", value: "date_spot" },
    { text: "Out with friends", value: "friends" },
    { text: "Family spot", value: "family_spot" },
    { text: "Value for money", value: "money" },
  ];
  const [ratings, setRatings] = useState({});
  const [updatedRates, setUpdatedRates] = useState([]);
  const [rateUpdated, setRateUpdated] = useState(false);

  useEffect(() => {
    setRatings(data.z_rating || {});
  }, [data]);

  const handleToggleFavorite = async (e, id) => {
    e.preventDefault();
    if (isEmpty(auth) || !auth.isAuthenticated) {
      return navigate("/signIn");
    }
    const { data } = await setFavoriteLocation({ id });
    const isAdded = favoriteLocations.includes(id);
    setAuth({ ...auth, favoriteLocations: data });
    notify(`${isAdded ? "Remove" : "Add"} Favorite Success`, "success");
  };

  const renderServices = () => {
    const items = [];
    let cnt = 1;
    if (data.dine_in) items.push(<span key={cnt}>{cnt++}. Dine in</span>);
    if (data.serves_beer)
      items.push(<span key={cnt}>{cnt++}. Serves beer</span>);
    if (data.serves_dinner)
      items.push(<span key={cnt}>{cnt++}. Serves dinner</span>);
    if (data.serves_lunch)
      items.push(<span key={cnt}>{cnt++}. Serves Lunch</span>);
    if (data.serves_wine)
      items.push(<span key={cnt}>{cnt++}. Serves wine</span>);
    if (data.takeout) items.push(<span key={cnt}>{cnt++}. Takeout</span>);
    if (data.wheelchair_accessible_entrance)
      items.push(
        <span key={cnt}>{cnt++}. Wheelchair accessible entrance</span>
      );
    if (data.delivery) items.push(<span key={cnt}>{cnt++}. Delivery</span>);
    if (data.reservable) items.push(<span key={cnt}>{cnt++}. Reservable</span>);
    if (data.curbside_pickup)
      items.push(<span key={cnt}>{cnt++}. Curbside pickup</span>);
    if (data.serves_vegetarian_food)
      items.push(<span key={cnt}>{cnt++}. Serves vegetarian food</span>);
    return items;
  };

  const handleChangeStarRating = (e, key) => {
    setRatings({
      ...ratings,
      [key]: e.target.value,
    });
    if (!updatedRates.includes(key)) setUpdatedRates([...updatedRates, key]);
    if (!rateUpdated) setRateUpdated(true);
  };

  const handleSubmitRating = () => {
    if (isEmpty(auth) || !auth.isAuthenticated) {
      return navigate("/signIn");
    }
    const abortController = new AbortController();
    let updatedRatings = {};
    updatedRates.forEach((key) => (updatedRatings[key] = ratings[key]));
    setLocationRating(
      { location_id: data.id, ratings: updatedRatings },
      abortController.signal
    )
      .then(() => {
        setFilterData(prev => {
          const updated = prev.map(item => item.id === data.id ? { ...item, z_rating: ratings } : item)
          return updated;
        })
        notify(`Saved Successfully`, "success");
      });
  };

  return (
    <>
      <Row xs={1} lg={2} className="w-100 m-0 p-4 justify-content-center ">
        <Col xs={12} lg={12} xl={12} className="mb-3 p-0">
          <MCardWrapper withRate>
            <Card>
              {!isEmpty(data.photo) ? (
                <Card.Img variant="top" src={getImageURL(data.photo)} />
              ) : (
                <Card.Img
                  className="px-5 pt-3"
                  variant="top"
                  src="/zappt-mag.png"
                />
              )}
              <Card.ImgOverlay as="div" className="bottom-auto right-auto ">
                <IconButton
                  onClick={(e) => handleToggleFavorite(e, data.id)}
                >
                  {!isEmpty(favoriteLocations) &&
                    favoriteLocations?.includes(data.id) ? (
                    <Tooltip title="Remove Favorite">
                      <Favorite color="error" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add Favorite">
                      <FavoriteBorder />
                    </Tooltip>
                  )}
                </IconButton>
              </Card.ImgOverlay>
            </Card>
            <Card className="h-100 mt-2">
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <div>
                    <Tooltip title={data.name}>
                      <Card.Title>{data.name}</Card.Title>
                    </Tooltip>
                    <Tooltip title={data.address}>
                      <p>{data.address}</p>
                    </Tooltip>
                  </div>
                  <div className="d-flex justify-content-end w-auto">
                    <a href={data.website_url} target="_blank" rel="noreferrer">
                      <Button className="px-2" size="sm" variant="primary">
                        <LanguageOutlinedIcon
                          sx={{
                            background: "transparent !important",
                            padding: "0 !important",
                            width: "1.25rem !important",
                            left: "0.25rem !important",
                          }}
                        />
                        <span className="ml-2">Website</span>
                      </Button>
                    </a>
                    <a href={data.map_url} target="_blank" rel="noreferrer">
                      <Button className="ml-2 px-2" size="sm" variant="primary">
                        <FeaturedLocationIcon />
                        <span className="ml-2">Location</span>
                      </Button>
                    </a>
                  </div>
                </div>
                <p className="title">Google's rating</p>
                {data.rating && <MStarRating value={data.rating || 0} size="lg" />}
                <p className="title">Zappt's ratings</p>
                <Accordion
                  sx={{
                    boxShadow: "none!important",
                    border: "none !important",
                    marginBottom: "1rem",
                    marginTop: "0 !important",
                    padding: "0 !important",
                    ".MuiAccordionSummary-root": {
                      width: "100% !important",
                      padding: "0 !important",
                      ".MuiAccordionSummary-content": {
                        margin: "0!important",
                      },
                    },
                    ".MuiCollapse-root": {
                      width: "100% !important",
                    },
                    ":before": { backgroundColor: "transparent !important" },
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="ratings"
                  >
                    <div className="d-flex align-items-center mb-2">
                      {ratings.overall >= 0 && (
                        <label className="mr-2">
                          {numberFormat(ratings.overall)}
                        </label>
                      )}

                      <MStarRating
                        size="lg"
                        readOnly={false}
                        value={parseFloat(ratings.overall)}
                        onChange={(e) => handleChangeStarRating(e, "overall")}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 }}>
                    {ratingItems.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between flex-wrap gap-2 mb-2"
                      >
                        <label>{item.text}</label>
                        <div className="d-flex align-items-center justify-content-end">
                          {ratings[item.value] >= 0 && (
                            <label className="mr-2">
                              {numberFormat(ratings[item.value])}
                            </label>
                          )}

                          <MStarRating
                            size="lg"
                            readOnly={false}
                            value={parseFloat(ratings[item.value])}
                            onChange={(e) =>
                              handleChangeStarRating(e, item.value)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <div className="d-flex justify-content-end">
                  {rateUpdated && (
                    <Button
                      className="ml-2 px-2"
                      size="md"
                      onClick={handleSubmitRating}
                    >
                      Save
                    </Button>
                  )}
                </div>

                <p className="title">Description</p>
                <p className="text">{data.description}</p>
                <Divider className="mb-3" />
                <p className="title">Service options:</p>
                <div className="d-flex flex-wrap column-gap-3 text mb-2">
                  {renderServices()}
                </div>
                <div className="d-flex flex-sm-row flex-column flex-wrap flex-sm-nowrap column-gap-1 text mb-3">
                  <div className="d-flex w-100 column-gap-1">
                    <div className="w-100">
                      <p className="title">Phone number:</p>
                      {data.international_phone_number}
                    </div>
                    <div className="w-100">
                      <p className="title">Price level:</p>
                      {data.price_level}
                    </div>
                  </div>
                  <div className="d-flex w-100 column-gap-1">
                    <div className="w-100">
                      <p className="title">Rating:</p>
                      {data.rating}
                    </div>
                    <div className="w-100">
                      <p className="title">User Ratings Total:</p>
                      {data.user_ratings_total}
                    </div>
                  </div>
                </div>
                <div className="text">
                  <p className="m-0 title ">Opening hours:</p>
                  {!isEmpty(data.opening_hours?.weekday_text) && (
                    <>
                      {data.opening_hours?.weekday_text?.map((item, index) => (
                        <p className="m-0" key={index}>
                          <span className="w-5 d-inline-block light-gray">
                            {item.split("day:")[0]}day:
                          </span>
                          <span className="text">{item.split("day:")[1]}</span>
                        </p>
                      ))}
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </MCardWrapper>
        </Col>
      </Row>
    </>
  );
}
