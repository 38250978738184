import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

export default function MDatePicker({ formData, setFormData, attribute }) {
  const handleDateChange = (e) => {
    setFormData({ ...formData, [attribute]: moment(e).format("MM-DD-YYYY") });
  };

  return (
      <DatePicker
        sx={{
          fieldset: {
            backgroundColor: "#F6F6F6",
            zIndex: "-1",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          input: {
            padding: "0.25rem 0.75rem",
            width: "6rem",
            fontSize: "0.875rem",
          },
          svg: {
            fontSize: "1rem",
          },
          ".MuiInputAdornment-root": {
            margin: 0,
            button: {
              left: "-0.25rem",
            },
          },
        }}
        format="MM-DD-YYYY"
        value={moment(new Date(formData[attribute]), "MM-DD-YYYY") ?? moment()}
        onChange={handleDateChange}
      />
  );
}
