import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import { resetPassword } from "../../utils/api";

import notify from "../../utils/notify";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import MBanner from "../../design_system/MBanner";
import { FormWrapper } from "../../design_system/styled-components";

const ResetPassword = () => {
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const param = useParams();
  const { resetToken } = param;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (!credentials.password || !credentials.confirmPassword) {
      setIsLoading(false);
      return notify("Please Fill the Password", "warn");
    }

    // If password and confirm password doesn't match
    if (credentials.password !== credentials.confirmPassword) {
      setIsLoading(false);
      return notify("Passwords Do Not Match", "warn");
    }

    try {
      await resetPassword({ resetToken, password: credentials.password });
      setIsLoading(false);
      notify("Password Reset Success!", "success");
      navigate("/signIn");
    } catch (error) {
      setIsLoading(false);
      return notify("Internal server error", "error");
    }
  };

  return (
    <>
      <MBanner title="Reset Password">
        <Link className="link" to="/">
          Home
        </Link>
      </MBanner>
      <FormWrapper>
        <Form className="w-sm-50" onSubmit={handleResetPassword}>
          <h3 className="m-0">Reset Password</h3>
          <p className="mb-4">Please enter your new password.</p>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              tabIndex="3"
              className="d-block w-100"
              placeholder="Enter password"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={credentials.password || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <TextField
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              tabIndex="4"
              className="d-block w-100"
              placeholder="Confirm password"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": { width: "100%" },
                input: { padding: "0.6rem 0" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ width: "1.15rem" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={credentials.confirmPassword || ""}
              onChange={(e) => handleCredentials(e)}
            />
          </Form.Group>

          <Button
            type="submit"
            size="lg"
            tabIndex="3"
            className="m-0 mb-3 w-100"
            disabled={isLoading}
          >
            Continue
          </Button>
        </Form>
      </FormWrapper>
    </>
  );
};

export default ResetPassword;
