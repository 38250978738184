import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { MModal } from "./MModal";
import { MDeleteModal } from "./MDeleteModal";
import { MFetchConfirmModal } from "./MFetchConfirmModal";
import { dateFormat, isEmpty } from "../utils/util";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getBusinessDetailById } from "../utils/api";
import { TableWrapper } from "./styled-components";
import {
  close2Others,
  levelOfMixology,
  sports,
  parking,
  criteriaLabels,
} from "../layout/Location/DrowndownList";
import { TrashIcon, SyncIcon, EditIcon } from "../utils/icons";
import { DataState } from "../context/AuthProvider";
import { MDeleteUserModal } from "./MDeleteUserModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tooltip } from "@mui/material";
import notify from "../utils/notify";
import { MAnalysisConfirmModal } from "./MAnalysisConfirmModal";

export const MTable = ({
  columnNames,
  columnLabels = criteriaLabels,
  tableData,
  editable = false,
  setSortFormData = () => { },
  sortFormData = [],
  handleDelete,
  handleFetch,
}) => {
  const { setEditLocation } = DataState();

  const [rowId, setRowId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isAnalysisOpen, setIsAnalysisOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [locationIdx, setLocationIdx] = useState(null);
  const [loading, setLoading] = useState(false);
  const nonSortableHeader = ["actions", "adminActions"];
  const [rowIsActive, setRowIsActive] = useState(null);

  const columnsHeader = columnNames?.map((header, index) => {
    const idx = sortFormData?.findIndex((item) => !isEmpty(item[header]));
    return (
      <th
        key={index}
        className="cursor-pointer"
        onClick={() => {
          if (nonSortableHeader.includes(header)) return;
          let temp = [...sortFormData];
          if (idx < 0) {
            temp.unshift({ [header]: true });
          } else {
            if (temp[idx][header] === true) {
              temp[idx][header] = false;
            } else {
              temp.splice(idx, 1);
            }
          }
          setSortFormData(temp);
        }}
      >
        <span className="d-flex justify-content-between">
          {columnLabels[header] || header}
          {idx < 0 ? (
            ""
          ) : sortFormData[idx][header] ? (
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M17 11l-5-5-5 5" />
              <path d="M17 18l-5-5-5 5" />
            </svg>
          ) : (
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M7 13l5 5 5-5" />
              <path d="M7 6l5 5 5-5" />
            </svg>
          )}
        </span>
      </th>
    );
  });

  const editEntry = (idx, data) => {
    setIsOpen(true);
    setEditLocation(data);
    setRowId(idx);
  };

  const analysisEntry = (idx, data) => {
    if (tableData[idx].is_analyzed === true) {
      setIsAnalysisOpen(true);
      setRowId(idx);
    } else {
      handleFetch(tableData[idx]);
    }
  }

  const deleteEntry = (idx) => {
    setIsDeleteOpen(true);
    setDeleteId(idx);
  };

  const handleShowFetchModal = async (idx) => {
    if (loading) return;
    setLoading(true);
    setIsOpen(true);
    setLocationIdx(idx);
    setLoading(false);
  };

  const handleFetchGoogleInfo = async (id) => {
    if (loading) return;
    setLoading(true);
    const abortController = new AbortController();
    const res = await getBusinessDetailById(id, abortController.signals);
    if (!isEmpty(res)) notify("Fetched Successfully", "success");
    setLoading(false);
  };

  const mapValuesToText = (values, key) => {
    let mappedValues = values;
    if (key === "mixology") {
      const mappedText = levelOfMixology.filter((v) =>
        values.includes(v.value.toString())
      );
      mappedValues = mappedText.map((v) => v.text);
    } else if (key === "sports") {
      const mappedText = sports.filter((v) =>
        values.includes(v.value.toString())
      );
      mappedValues = mappedText.map((v) => v.text);
    } else if (key === "close2Others") {
      const mappedText = close2Others.filter((v) =>
        values.includes(v.value.toString())
      );
      mappedValues = mappedText.map((v) => v.text);
    } else if (key === "parking") {
      const mappedText = parking.filter((v) =>
        values.includes(v.value.toString())
      );
      mappedValues = mappedText.map((v) => v.text);
    }
    return mappedValues.join(", ");
  };

  const toggleActive = (index) => {
    setRowIsActive(index);
  };
  const rows = tableData?.map((rows, idx) => {
    // const columnNames = Object.keys(rows).filter(
    // 	(columnName) => columnName !== "id"
    // );
    return (
      <tr
        className={rowIsActive === idx ? "table-primary" : ""}
        onClick={() => toggleActive(idx)}
        key={"row" + idx}
      >
        {columnNames.map((key, index) => (
          <td key={"col" + index}>
            {!isEmpty(rows[key]) ? (
              key === "name" && !isEmpty(rows.place_id) ? (
                <Link to={`/location/${rows.id}`}>{rows[key]}</Link>
              ) : key === "joinDate" || key === "expires" ? (
                dateFormat(rows[key])
              ) : key === "is_new" ? (
                <>
                  {rows[key] ? (
                    <>
                      <Button size="sm" variant="outline-light">
                        New
                      </Button>
                      {rows.is_conflict && (
                        <Button
                          className="ml-2"
                          size="sm"
                          variant="outline-danger"
                        >
                          Conflict
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button size="sm" variant="outline-info">
                        Old
                      </Button>
                      {rows.is_conflict && (
                        <Button
                          className="ml-2"
                          size="sm"
                          variant="outline-info"
                        >
                          Conflict
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : Array.isArray(rows[key]) ? (
                mapValuesToText(rows[key], key)
              ) : (
                rows[key]
              )
            ) : key === "status" ? (
              <>
                {rows[key] === true ? (
                  <Button size="sm" variant="outline-info">
                    Active
                  </Button>
                ) : rows[key] === false ? (
                  <Button size="sm" variant="outline-danger">
                    Un-active
                  </Button>
                ) :
                  <Button size="sm" variant="light">
                    Pending
                  </Button>
                }
              </>
            ) : key === "actions" && !editable ? (
              <div className="d-flex align-items-center justify-content-between">
                <Link to={"/add-location/" + rows.id} className="cursor-pointer">
                  <div
                    className="cursor-pointer"
                    onClick={() => editEntry(idx, rows)}
                  >
                    <EditIcon />
                  </div>
                </Link>
                <div
                  className="cursor-pointer mx-1"
                  onClick={() =>
                    isEmpty(rows.place_id)
                      ? handleFetchGoogleInfo(rows.id)
                      : handleShowFetchModal(idx)
                  }
                  data-toggle="modal"
                  data-target={isEmpty(rows.place_id) ? "" : "#fetchModal"}
                >
                  {isEmpty(rows.place_id) ? (
                    <Tooltip title="Fetch">
                      <RefreshIcon color="error" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Refetch">
                      <span>
                        <SyncIcon sx={{ color: "#1da2f2" }} />
                      </span>
                    </Tooltip>
                  )}
                </div>

                <div className="cursor-pointer" onClick={() => deleteEntry(idx)} >
                  <TrashIcon />
                </div>
              </div>
            ) : key === "adminActions" && !editable ? (
              <div className="d-flex align-items-center justify-content-end">
                <div className="cursor-pointer" style={{ color: "#1da2f2" }}>
                  <EditIcon />
                </div>

                <div className="cursor-pointer ml-2" onClick={() => deleteEntry(idx)}>
                  <TrashIcon />
                </div>
              </div>
            ) : key === "analysis_actions" && !editable ? (
              <div className="d-flex align-items-center justify-content-around">
                <div className="cursor-pointer" style={{ color: "#1da2f2" }} onClick={() => analysisEntry(idx)}>
                  {rows.is_analyzed ? (
                    <Tooltip title="Refetch">
                      <span>
                        <SyncIcon sx={{ color: "#1da2f2" }} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Fetch">
                      <RefreshIcon />
                    </Tooltip>
                  )}
                </div>

                <div className="cursor-pointer ml-2" onClick={() => deleteEntry(idx)} >
                  <TrashIcon />
                </div>
              </div>
            ) : key === "claim_actions" && !editable ? (
              <div className="d-flex align-items-center justify-content-around">
                <div className="cursor-pointer ml-2" onClick={() => handleDelete(rows)} >
                  <TrashIcon />
                </div>
              </div>
            ) : null}
          </td>
        ))}
      </tr>
    );
  });

  return (
    <>
      <div className="mt-4 position-relative">
        <TableWrapper>
          <div>
            <Table className="table table-hover table-striped">
              <thead>
                <tr>{columnsHeader}</tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        </TableWrapper>
      </div>
      {isOpen ? (
        <MModal
          modalId="updateModal"
          setIsOpen={setIsOpen}
          tableData={tableData}
          rowId={rowId}
        />
      ) : null}
      {isOpen ? (
        <MFetchConfirmModal
          modalId="googleFetchModal"
          setIsOpen={setIsOpen}
          locationIdx={locationIdx}
          tableData={tableData}
        />
      ) : null}
      {isDeleteOpen ? (
        <MDeleteModal
          setIsOpen={setIsDeleteOpen}
          deleteId={deleteId}
          isOpen={isDeleteOpen}
          tableData={tableData}
          handleDelete={handleDelete}
        />
      ) : null}
      {isOpen ? (
        <MDeleteUserModal
          setIsOpen={setIsOpen}
          deleteId={deleteId}
          tableData={tableData}
        />
      ) : null}
      {isAnalysisOpen ? (
        <MAnalysisConfirmModal
          setIsOpen={setIsAnalysisOpen}
          rowId={rowId}
          isOpen={isAnalysisOpen}
          tableData={tableData}
          handleAnalyze={handleFetch}
        />
      ) : null}
    </>
  );
};
